import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import Button from "@cof/graffiti-alley-spray-cans/molecules/Button";
import getWebLogger from "../../lib/logger/webLogger";

const ApprovedTapToVerify = ({ location }) => {
  const logger = getWebLogger("approved-t2v-section.js");
  const handleClick = () => {
    logger.info({
      actionType: "T2V_BUTTON_CLICKED",
      message: "Applicant clicked the Tap to Verify button"
    });
  };
  return (
    <div
      data-cy="approved-tapToVerify"
      data-testid="approved.tapToVerify"
      id="tapToVerify"
      className="t2v-wrapper"
    >
      <p className="medium-bold">
        <strong>
          <FormattedMessage id="decision.approve.t2v.access" />
        </strong>
      </p>
      <p className="medium-bold">
        <FormattedMessage id="decision.approve.t2v.message" />
      </p>

      <Button url={location} onClick={handleClick} id="t2v-button">
        <FormattedMessage id="t2v.button" />
      </Button>
      <p className="opt-out">
        <FormattedMessage id="decision.approve.t2v.opt-out" />
      </p>
    </div>
  );
};

ApprovedTapToVerify.propTypes = {
  location: PropTypes.string
};

export default ApprovedTapToVerify;
