import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class AppSubmit extends Component {
  static propTypes = {
    message: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.validationTimer = null;
    this.reviewingTimer = null;

    this.state = {
      doneValidating: false,
      doneReviewing: false,
      doneProcessing: false
    };
  }

  componentDidMount() {
    this.endValidationAfter(3000);
    this.endReviewingAfter(6000);

    // INFO: processing animation never ends, since when the data comes back from
    //       the server with the decision this status bar will be unmounted
  }

  componentWillUnmount() {
    window.clearTimeout(this.validationTimer);
    window.clearTimeout(this.reviewingTimer);

    this.validationTimer = null;
    this.reviewingTimer = null;

    this.setState(
      Object.assign({}, this.state, {
        doneValidating: true,
        doneReviewing: true,
        doneProcessing: true
      })
    );
  }

  endValidationAfter = (time) => {
    this.validationTimer = window.setTimeout(() => {
      // INFO: make sure we don't attempt to set the state if the timer was canceled,
      //       at the same time this method was called
      if (this.validationTimer != null) {
        this.setState(
          Object.assign({}, this.state, {
            doneValidating: true
          })
        );
      }
    }, time);
  };

  endReviewingAfter = (time) => {
    this.reviewingTimer = window.setTimeout(() => {
      // INFO: make sure we don't attempt to set the state if the timer was canceled,
      //       at the same time this method was called
      if (this.reviewingTimer != null) {
        this.setState(
          Object.assign({}, this.state, {
            doneReviewing: true
          })
        );
      }
    }, time);
  };

  render() {
    const validatingClass = this.state.doneValidating ? "done" : null;
    const reviewingClass = this.state.doneReviewing ? "done" : null;
    const processingClass = this.state.doneProcessing ? "done" : null;

    return (
      <div id="app-submit-status" className="row small-12 medium-8 columns">
        <div className="expanded row loading text-center">
          <div className={`small-12 medium-4 columns ${validatingClass}`}>
            <p>
              <i className={`large oi-list${this.state.doneValidating ? "" : " outline"}`} />
              {this.state.doneValidating && (
                <i data-testid="doneValidatingComplete" className="oi-success" />
              )}
            </p>
            <p>
              <FormattedMessage id="app-status.text.validating" />
            </p>
            <div className="bar" />
          </div>

          <div className={`small-12 medium-4 columns ${reviewingClass}`}>
            <p>
              <i className={`large oi-bank-branch${this.state.doneReviewing ? "" : " outline"}`} />
              {this.state.doneReviewing && (
                <i data-testid="doneReviewingComplete" className="oi-success" />
              )}
            </p>
            <p>
              <FormattedMessage id="app-status.text.reviewing" />
            </p>
            <div className="bar" />
          </div>

          <div className={`small-12 medium-4 columns ${processingClass}`}>
            <p>
              <i className={`large oi-transfers${this.state.doneProcessing ? "" : " outline"}`} />
              {this.state.doneProcessing && (
                <i data-testid="doneProcessingComplete" className="oi-success" />
              )}
            </p>
            <p>
              <FormattedMessage id="app-status.text.processing" />
            </p>
            <div className="bar" />
          </div>
        </div>

        <footer className="expanded row column text-center">
          <p>
            <FormattedMessage id="app-status.text.submission.text" />
            <br />
            <FormattedMessage id="app-status.text.submission.additional-text" />
          </p>
        </footer>
      </div>
    );
  }
}

export default AppSubmit;
