import { getGUID } from "../../session";
import { getChannelValue } from "../../server-info";
import { getAppEnvirnoment, isWebDriver } from "../../dom-utils";
import config from "../../../../config";

const removeDisallowedHeaders = (requestHeaders, allowedHeaders = []) => {
  Object.keys(requestHeaders)
    .filter((header) => !allowedHeaders.includes(header))
    .forEach((header) => delete requestHeaders[header]);

  return requestHeaders;
};

const addExtraHeaders = (requestHeaders, extraHeaders = {}) => {
  //merging objects is done this way to preserve the original reference to requestHeaders
  Object.keys(extraHeaders).forEach((headerKey) => {
    requestHeaders[headerKey] = extraHeaders[headerKey];
  });
};

export default (url, extraHeaders = {}) => {
  const { extraHeaderConfig } = config();
  let requestHeaders = {
    "Content-Type": "application/json; charset=utf-8",
    "Channel-Type": getChannelValue(),
    "Client-Correlation-Id": getGUID(),
    "Api-key": "DAPPCA"
  };
  addExtraHeaders(requestHeaders, extraHeaders);
  // This logic will allow for a special configuration on SHAPE clusters to allow selenium tests from CA Dynamic app
  if (isWebDriver() && getAppEnvirnoment() !== "prod") {
    requestHeaders["Ca-Dapp-Automated-Test"] = true;
  }
  if (url) {
    let headerConfig = extraHeaderConfig.find((config) => url.includes(config.baseUrl));
    if (!headerConfig) return requestHeaders;
    if (headerConfig.allowedHeaders)
      removeDisallowedHeaders(requestHeaders, headerConfig.allowedHeaders);
    if (headerConfig.extraHeaders) addExtraHeaders(requestHeaders, headerConfig.extraHeaders);
  }

  return requestHeaders;
};
