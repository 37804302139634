// polyfill to be applied for devices/engines that doesn't support them
// currently we support the following browsers:
//
//   - IE 11
//   - Edge (last 2 versions)
//   - Google Chrome (last 2 versions)
//   - Firefox (last 2 versions)
//   - Safari (last 2 versions)
//   - Mobile Chrome (last 2 versions)
//   - Mobile Safari (last 2 versions)
//

import "core-js";
import "regenerator-runtime/runtime";
// require('babel-polyfill');

// fetch() polyfill for making API calls.
require("whatwg-fetch");

//To make IE11 implements forEach on NodeList
//from MDN:
//https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
