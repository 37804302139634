import { clean } from "diacritic";

//Diacritic are replaced by the clean function. However, the characters '.'
//''' and '-' are not diacritic but needs to be stripped out due to downstream
//system limitations. Character . and ' will be changed to blanks and any -
//will change to a space.
//E.g.
// Before: 200 Avenue Québec, Rouyn-Noranda, Québec
// After: 200 Avenue Quebec, Rouyn Noranda, Quebec
//Another example is
// Before: 62 Ladysmith Drive, St. John's, NL A1B0G4
// After: 62 Ladysmith Drive, St Johns, NL A1B0G4
export const stripSpecialCharacter = (value) => {
  return clean(value).replace(/[.']/g, "").replace(/[-]/g, " ");
};

export const cleanseSpecialCharacters = (value) => {
  return value
    ? clean(value)
        .replace(/[-]/g, " ")
        .replace(/ᵉ/g, "e")
        .replace(/[^a-zA-Z0-9\s,]+/g, "")
    : "";
};

//prefill data validation
export const addressValidation = (address) => {
  if (address) {
    return address.replace(/[^A-Za-z0-9 ]/g, "");
  }
};

export const nameValidation = (name) => {
  if (name) {
    name = name.replace(/-/g, " ");
    return name.replace(/[^A-Za-z ]/g, "");
  }
};
