import React, { useContext, useEffect } from "react";
import { intlShape, FormattedMessage, injectIntl } from "react-intl";
import { pageLoadLogger } from "../../lib/logger";
import { AppContext } from "../../state/app-context";
import { changeCalypsoContext } from "../../lib/CalypsoUtil";
import useLogger from "../../hooks/useLogger";

const InvalidOffer = ({ intl }) => {
  const { app } = useContext(AppContext);
  const logger = useLogger("invalid-offer.js");

  const prefillKey = app.prefillKey;
  useEffect(() => {
    changeCalypsoContext("CA_InvalidOffer");
    logger.info({ actionType: "PAGE_LOAD", message: "Invalid offer page" });
    pageLoadLogger(app.product, null, prefillKey);
  }, [app.product, prefillKey]);

  return (
    <div className="row column text-center error-page">
      <i className="oi-caution outline header-icon" />
      <h1 className="h1-as-h2">
        <FormattedMessage id="invalid-offer.title" />
      </h1>
      <p>
        <FormattedMessage
          id="invalid-offer.text"
          values={{
            linkCTA: (
              <a href={intl.formatMessage({ id: "capital-one.external.url" })}>
                <FormattedMessage id="invalid-offer.link.cta" />
              </a>
            )
          }}
        />
        <br />
        <br />
        <FormattedMessage id="invalid-offer.next-step" />
      </p>
    </div>
  );
};

InvalidOffer.propTypes = {
  intl: intlShape
};

export { InvalidOffer as InvalidOfferRaw };
const InvalidOfferHOC = injectIntl(InvalidOffer);

InvalidOfferHOC.displayName = "InvalidOffer";
export default InvalidOfferHOC;
