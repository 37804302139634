import requestGet from './base/request-get';                                           // eslint-disable-line no-unused-vars
import { BASE_URL } from './base/settings';
import { LANGUAGES } from "../../state/constants/product";

// // from: http://www.2ality.com/2014/10/es6-promises-api.html
// function delay(ms) {
//   return new Promise(function (resolve, reject) {
//     setTimeout(resolve, ms); // (A)
//   });
// }

// TODO: remove both the promise and the fakeRequestGet when api is fully implemented
const fakeRequestGet = (url, locale) => {                                         // eslint-disable-line no-unused-vars
  let messages;
  if (locale === LANGUAGES.FRENCH) {
    messages = require('../../../locale/fr.json');
  }
  else {
    messages = require('../../../locale/en.json');
  }
  return Promise.resolve({ status: 200, json: () => messages });
};

export const getMessages = locale => {
  const url = `${BASE_URL}/creditmind-locale-messages`;

  // replace the fakeRequestGet with the following when api is implemented
  // return requestGet(url, locale)
  return fakeRequestGet(url, locale)
    .then(res => {
      if (res.status >= 400) {
        // TODO: in this case we should probably log the issue
      }
      return res.json();
    })
    .then(messages => {
      return { locale, messages };
    });
};
