import React from "react";
import PropTypes from "prop-types";
import { intlShape, FormattedMessage } from "react-intl";

import { CARD_FAQ } from "../../state/constants/product";
import { TabTitle, Tabs, TabPanel } from "@cof/graffiti-alley-spray-cans/molecules/Tabs";

const Faq = ({ intl, product }) => {
  const campaign = product ? product.campaign : "";
  const renderQuestions = (section) => {
    if (!CARD_FAQ[section]) {
      return null;
    }

    const result = [];
    CARD_FAQ[section].forEach((tag, i) => {
      if (tag === "faq.auto-payment") {
        result.push(
          <div key={i}>
            <h4>
              <FormattedMessage id={`${tag}.question`} />
            </h4>
            <p>
              <FormattedMessage
                id={`${tag}.answer`}
                values={{
                  linkForm: (
                    <a
                      href={intl.formatMessage({ id: "pad-authorization-form.external.url" })}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="faq-pad-form-link"
                    >
                      <FormattedMessage id="faq.auto-payment.answer.linkForm" />
                    </a>
                  ),
                  emDash: <span className="nowrap">&nbsp;&#8211;&nbsp;</span>,
                  md: <sup>MD</sup>
                }}
              />
            </p>
          </div>
        );
      } else if (!campaign || campaign !== "multi" || tag !== "faq.email-required") {
        result.push(
          <div key={i}>
            <h4>
              <FormattedMessage id={`${tag}.question`} />
            </h4>
            <p>
              <FormattedMessage id={`${tag}.answer`} />
            </p>
          </div>
        );
      }
    });

    return result;
  };

  const personalFAQ = renderQuestions("personal");
  const decisionFAQ = renderQuestions("decision");

  return (
    <div data-cy="faq-modal" data-testid="faqModel">
      <Tabs>
        <TabTitle>
          <div data-cy="faq-modal-personal" data-testid="faqModalPersonal">
            <i aria-hidden="true" className="oi-person outline" />
            <span>
              <strong>
                <FormattedMessage id="faq.menu.personal" />
              </strong>
            </span>
          </div>
        </TabTitle>
        <TabTitle>
          <div data-cy="faq-modal-decision" data-testid="faqModalDecision">
            <i aria-hidden="true" className="oi-thumbs-up outline" />
            <span>
              <strong>
                <FormattedMessage id="faq.menu.decision" />
              </strong>
            </span>
          </div>
        </TabTitle>
        <TabPanel>{personalFAQ}</TabPanel>
        <TabPanel>{decisionFAQ}</TabPanel>
      </Tabs>
    </div>
  );
};

Faq.propTypes = {
  intl: intlShape,
  product: PropTypes.object
};

export default Faq;
