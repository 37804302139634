import PropTypes from "prop-types";
import React, { useContext } from "react";
import { intlShape, FormattedMessage } from "react-intl";
import { SecurityFunds } from "../alerts";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import CardArt from "@cof/graffiti-alley-spray-cans/molecules/CardArt";
import { cardImageBrands } from "../../lib/product-utils";

const SecuredApprovedHeader = ({
  productInfo,
  intl,
  firstName,
  lastName,
  solicitationReferenceNumber,
  minimumSecurityDepositAmount,
  faceToFaceRequired
}) => {
  const { toggleModal } = useContext(SprayCanContext);

  return (
    <div
      data-cy="secured-decision-header"
      className="secured-decision-page"
      data-testid="secured-approved-header"
    >
      <h1 className="text-center">
        <FormattedMessage
          id="decision.approve.secure.title"
          values={{
            registered: (
              <sup>
                <FormattedMessage id="common.registered" />
              </sup>
            ),
            trademark: (
              <sup>
                <FormattedMessage id="common.trademark" />
              </sup>
            )
          }}
        />
      </h1>
      <div className="row">
        <div className="column small-12 medium-3" data-testid="cardart-container">
          <CardArt
            brand={cardImageBrands(productInfo.brand)}
            firstName={firstName}
            lastName={lastName}
            classImageStyle=""
            showLegalDisclaimer={true}
          />
        </div>
        <div className="column small-12 medium-9">
          {faceToFaceRequired ? (
            <h2>
              <FormattedMessage id="decision.text.securing.fund-your-card-f2f" />
            </h2>
          ) : (
            <h2>
              <FormattedMessage id="decision.text.securing.fund-your-card" />
            </h2>
          )}
          <div className="row">
            <div className="column small 6">
              <p>
                <FormattedMessage id="decision.text.securing.funds-required" />
                <br />
                <span id="minimum-deposit" className="currency-value">
                  <span className="currency-sign">$</span>
                  {minimumSecurityDepositAmount}
                </span>
              </p>
            </div>
            <div className="column small 6">
              <p>
                <FormattedMessage id="decision.text.securing.reference-number" />
                <br />
                <span id="solicitation-ref" className="currency-value">
                  {solicitationReferenceNumber}
                </span>
              </p>
            </div>
          </div>
          <div
            className="row column small-12 security-funds-link"
            data-testid="security-funds-link-container"
          >
            <p>
              <a
                onClick={() => toggleModal(null, SecurityFunds, { intl })}
                data-testid="security-funds-link"
              >
                <FormattedMessage id="decision.text.securing.security-funds-explanation" />
                <span aria-hidden="true" className="icon-uniELaunch icon-resize" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SecuredApprovedHeader.propTypes = {
  intl: intlShape,
  decision: PropTypes.object,
  productInfo: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  minimumSecurityDepositAmount: PropTypes.string,
  solicitationReferenceNumber: PropTypes.string,
  cardImgSource: PropTypes.string,
  faceToFaceRequired: PropTypes.bool
};

export default SecuredApprovedHeader;
