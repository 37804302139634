import React from "react";
import { intlShape, FormattedMessage } from "react-intl";
import Accordion from "@cof/graffiti-alley-spray-cans/molecules/Accordion/AccordionSection";
const SendFundsAdditionalInfo = ({ intl }) => {
  return (
    <Accordion
      title={intl.formatMessage({ id: "send-funds-additional-info.title" })}
      id="send-funds-additional-info-title"
    >
      <span id="send-funds-additional-info-description">
        <FormattedMessage id="send-funds-additional-info.description" />
      </span>
    </Accordion>
  );
};

SendFundsAdditionalInfo.propTypes = {
  intl: intlShape.isRequired
};

export default SendFundsAdditionalInfo;
