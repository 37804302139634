import React from 'react';
import PropTypes from 'prop-types';

const FeatureFlagsContext = React.createContext();

const FeatureFlagsProvider = ({ children, value }) => (
  <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>
);

FeatureFlagsProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  children: PropTypes.node
};

export { FeatureFlagsContext };
export default FeatureFlagsProvider;
