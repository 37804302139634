import { getAppEnvirnoment } from "../src/lib/dom-utils";

// Return the appropriate configuration, based on the environment that the app is running in
export default (env = getAppEnvirnoment()) => {
  switch (env) {
    case "prod":
      return require("./prod.json");
    case "qa":
      return require("./qa.json");
    default:
      return require("./dev.json");
  }
};
