// For the new export constants run Snowplow_Schema_Automation Python
// Change integer -> number
// Change array -> object

export const SNOWPLOW_FAILURE = "SNOWPLOW_FAILURE";
export const SNOWPLOW_SUCCESS = "SNOWPLOW_SUCCESS";

/**
 * Snowplow's schema and properties for application property tracking
 * @type {Object}
 */
const applicationSchema = {
  schema: "iglu:com.capitalone.snowplow/application/jsonschema/1-0-0",
  properties: {
    applicationId: {
      type: ["string", "null"]
    },
    productId: {
      type: ["string", "null"]
    },
    productType: {
      type: ["string", "null"]
    },
    productName: {
      type: ["string", "null"]
    },
    applicationReferenceId: {
      type: ["number", "null"]
    },
    solicitationReferenceNumber: {
      type: ["string", "null"]
    },
    applicationStatus: {
      type: ["string", "null"]
    },
    applicationStatusDetail: {
      type: ["string", "null"]
    },
    creditLimit: {
      type: ["number", "null"]
    },
    landingPage: {
      type: ["string", "null"]
    },
    marketingChannel: {
      type: ["string", "null"]
    },
    applicationTimeoutStatus: {
      type: "string",
      enum: ["warning", "reset", "expired"]
    },
    offerType: {
      type: ["string", "null"]
    },
    audienceType: {
      type: ["string", "null"]
    },
    passTrack: {
      type: ["string", "null"]
    }
  }
};

/**
 * Snowplow's schema and properties for link click tracking
 * @type {Object}
 */
const linkClickSchema = {
  schema: "iglu:com.snowplowanalytics.snowplow/link_click/jsonschema/1-0-1",
  properties: {
    elementId: {
      type: "string"
    },
    elementClasses: {
      type: "object"
    },
    elementTarget: {
      type: "string"
    },
    targetUrl: {
      type: "string"
    },
    elementContent: {
      type: "string"
    }
  },
  required: ["targetUrl"]
};

/**
 * Snowplow's schema and properties for ui interaction tracking
 * @type {Object}
 */
const uiInteractionSchema = {
  schema: "iglu:com.capitalone.snowplow/ui_interaction/jsonschema/1-0-0",
  properties: {
    action: {
      type: "string"
    },
    elementType: {
      type: "string",
      enum: [
        "banner",
        "button",
        "carousel",
        "chat",
        "checkbox",
        "drawer",
        "dropdown",
        "filter",
        "link",
        "map",
        "message",
        "modal",
        "nav",
        "progressbar",
        "radiobutton",
        "search",
        "sort",
        "textinput",
        "toggle",
        "tooltiplink",
        "window"
      ]
    },
    elementName: {
      type: "string"
    },
    value: {
      type: "string"
    }
  },
  required: ["action", "elementType", "elementName"]
};

/**
 * Snowplow's schema and properties for field level tracking
 * @type {Object}
 */
const fieldTrackingSchema = {
  schema: "iglu:com.capitalone.snowplow/field_tracking/jsonschema/1-0-1",
  properties: {
    name: {
      type: "string"
    },
    validitionStatus: {
      type: "boolean"
    },
    validationMessage: {
      type: ["string", "null"]
    },
    formName: {
      type: ["string"]
    },
    inputCharacterLength: {
      type: ["number"]
    }
  },
  required: ["name", "formName"]
};

/**
 * Snowplow's schema and properties for A/B testing tracking
 * @type {Object}
 */
const abTestingSchema = {
  schema: "iglu:com.capitalone.snowplow/a_b_testing/jsonschema/1-0-2",
  properties: {
    adobe_tnt: { type: ["string"] },
    akamaiTest: { type: ["string"] },
    sectionId: { type: ["string", "null"] },
    manual: { type: ["boolean", "null"] },
    name: { type: ["string"] },
    variationIds: { type: ["object"] },
    testGroup: { type: ["string"] }
  },
  required: ["name", "sectionId", "manual", "name", "variationIds", "testGroup"]
};

/**
 * Get the context details for a given schema
 * @param  {string} schemaName  The schema's name you want to retrieve
 * @return {object} map[name]   Context details
 */
export const schemas = (schemaName) => {
  const map = {};
  map["APPLICATION"] = applicationSchema;
  map["LINK_CLICK"] = linkClickSchema;
  map["UI_INTERACTION"] = uiInteractionSchema;
  map["FIELD_TRACKING"] = fieldTrackingSchema;
  map["A_B_TESTING"] = abTestingSchema;

  return map[schemaName];
};

/**
 * Get the tracking details given a trackingEvent
 * @param  {string} trackingEvent       Potential Snowplow tracking events
 * @return {string} map[trackingEvent]  Tracking details corresponding value
 */
export const trackingEvents = (trackingEvent) => {
  const map = {};
  map["TRACK_SELF_DESCRIBING_EVENT"] = "trackSelfDescribingEvent";
  map["TRACK_PAGE_VIEW"] = "trackPageView";

  return map[trackingEvent];
};

/**
 * Get the corresponding accepted value for snowplow
 * @param  {string} elementType       The described element type by the browser
 * @return {string} map[elementType]  The snowplow accepted element type
 */
export const elementTypes = (elementType) => {
  const map = {};
  map["banner"] = "banner";
  map["button"] = "button";
  map["carousel"] = "carousel";
  map["chat"] = "chat";
  map["checkbox"] = "checkbox";
  map["drawer"] = "drawer";
  map["dropdown"] = "dropdown";
  map["filter"] = "filter";
  map["link"] = "link";
  map["map"] = "map";
  map["message"] = "message";
  map["modal"] = "modal";
  map["nav"] = "nav";
  map["progressbar"] = "progressbar";
  map["search"] = "search";
  map["sort"] = "sort";
  // Changed from the standard schema
  map["text"] = "textinput";
  map["tel"] = "textinput";
  map["email"] = "textinput";
  map["radio"] = "radiobutton";
  // End of changes
  map["toggle"] = "toggle";
  map["tooltiplink"] = "tooltiplink";
  map["window"] = "window";

  return map[elementType];
};
