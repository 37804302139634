import React, { useContext, useState, useEffect } from "react";
import { intlShape, injectIntl, FormattedMessage } from "react-intl";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import WalkAwayModalTimer from "@cof/graffiti-alley-spray-cans/modal-bodies/WalkAwayModalTimer";
import ReservationNumber from "@cof/graffiti-alley-spray-cans/molecules/ReservationNumber";
import AccessCode from "@cof/graffiti-alley-spray-cans/molecules/AccessCode";
import { AppContext } from "../state/app-context";
import * as validation from "../lib/validation";
import { Processing } from "../components/status";
import PropTypes from "prop-types";
import {
  getReservationNumberAndAccessCodeFromQS,
  redirectToExternalUrl,
  redirectTo,
  getCTAQs,
  getChannelFromQS
} from "../lib/dom-utils";
import { pageLoadLogger } from "../lib/logger";
import { refreshGUID, getLocale } from "../lib/session";
import { verifyCodesForQuickCheckUA } from "../lib/product-utils";
import { getProducts } from "../lib/api/product";
import { CHANNEL_INTERNET, CHANNEL_PHONE } from "../state/constants/channel";
import {
  QS_APP_ORIGIN,
  APP_ORIGIN_GYC,
  TECHNICAL_DIFFICULTY_URL,
  INVALID_OFFER_URL,
  APP_ORIGIN_UYC
} from "../routes/routes-constants";
import { APP_RESET, APP_SET_PRODUCT, APP_SET_STAGE } from "../state/constants/action-types";
import { Faq, GYCInfoSecurity } from "../components/alerts";
import SubHeader from "@cof/graffiti-alley-spray-cans/organisms/SubHeader";
import { changeCalypsoContext, changeCalypsoCustomerSessionId } from "../lib/CalypsoUtil";
import useLogger from "../hooks/useLogger";

import { UpdateTabTitle } from "../lib/session";

const buildQs = (isUYCpage) => {
  const appOrigin = isUYCpage ? APP_ORIGIN_UYC : APP_ORIGIN_GYC;
  const path = `${QS_APP_ORIGIN}=${appOrigin}${isUYCpage ? "&uyc=t" : ""}`;
  const ctaQs = getCTAQs();
  return ctaQs ? `${path}&${ctaQs}` : path;
};

const GetYourCard = ({ intl, isUYC }) => {
  const [invalidSrnAccessCode, setInvalidSrnAccessCode] = useState(false);
  const {
    data: { reservationNumber, accessCode },
    validateAndSubmit,
    toggleModal,
    dispatch
  } = useContext(SprayCanContext);
  const { app, appDispatch } = useContext(AppContext);
  const logger = useLogger("get-your-card.js");

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    logger.info({ message: "In Get Your card page" });
    pageLoadLogger({ id: "", brand: "" }, null, null);
    changeCalypsoContext("CA_GetYourCard");
    if (!isMounted) {
      setIsMounted(true);
      logger.info({
        logFilter: "CANCA-20923",
        message: "Refreshing correlationId in Get Your card page",
        appStage: app.stage
      });
      const guid = refreshGUID();
      logger.info({
        logFilter: "CANCA-20923",
        message: "Refreshed correlationId in Get Your card page",
        appStage: app.stage
      });
      changeCalypsoCustomerSessionId(guid);
      appDispatch({
        type: APP_RESET
      });
      dispatch({ type: "resetToInitialState" });
    }

    const reservationAccess = getReservationNumberAndAccessCodeFromQS();
    if (reservationAccess.reservationNumber && reservationAccess.accessCode) {
      dispatch({
        type: "fieldUpdate",
        fieldName: "reservationNumber",
        fieldValue: reservationAccess.reservationNumber
      });
      dispatch({
        type: "fieldUpdate",
        fieldName: "accessCode",
        fieldValue: reservationAccess.accessCode
      });
    } else if (reservationAccess.reservationNumber) {
      dispatch({
        type: "fieldUpdate",
        fieldName: "reservationNumber",
        fieldValue: reservationAccess.reservationNumber
      });
    }
  }, [app.stage, appDispatch, dispatch, isMounted]);

  const loadProductInfo = async (reservationNumber, accessCode) => {
    let product = {};
    const { quickCheckRedirect, quickCheckRedirectUrl } = verifyCodesForQuickCheckUA(
      reservationNumber,
      accessCode
    );
    try {
      toggleModal(null, Processing, {
        isCloseable: false,
        message: "app-status.text.loading-app",
        icon: "large oi-transfers"
      });
      if (quickCheckRedirect) {
        //Append QS must be true. This will allow for redirect to french version of quick check
        redirectToExternalUrl(quickCheckRedirectUrl, true);
      } else {
        const channel = getChannelFromQS() === CHANNEL_PHONE ? CHANNEL_PHONE : CHANNEL_INTERNET;
        // Following condition will be added to determine whether to use orchestrator or existing approach
        try {
          product = await getProducts({ reservationNumber, accessCode });
        } catch (e) {
          if (e.message == "Bad Request") {
            logger.info("INVALID RESERVATION CODE or ACCESSCODE OR PRODUCT");
          } else {
            logger.info("Exception occurred in CCPI response");
            throw e;
          }
        }
        if (!product.solicitedIndicator) {
          logger.info({
            actionType: "INVALID_SRN_CODE",
            message: "invalid reservation code and access code"
          });
          setInvalidSrnAccessCode(true);
        }

        if (product.solicitedIndicator && product.channel.includes(channel)) {
          appDispatch({
            type: APP_SET_STAGE,
            stage: 0
          });

          const productLocale = product.locale;
          const currentLocale = getLocale(window);
          if (!productLocale.includes(currentLocale)) {
            redirectTo(INVALID_OFFER_URL, {
              url: INVALID_OFFER_URL,
              message: "invalid product"
            });
          } else {
            appDispatch({
              type: APP_SET_PRODUCT,
              product: product
            });
            UpdateTabTitle(product);
            const path = `/?productId=${product.id}&` + buildQs(isUYC);
            redirectTo(path, null, false);
          }
        }
      }
    } catch (e) {
      redirectTo(TECHNICAL_DIFFICULTY_URL + "?" + buildQs(), { ...e }, false);
    } finally {
      toggleModal(null, Processing);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    validateAndSubmit(event, () => {
      loadProductInfo(reservationNumber, accessCode);
    });
  };

  const handleInformationSecurity = (event) => {
    event.preventDefault();
    toggleModal(null, GYCInfoSecurity, {
      title: "get-your-card.info-security-tooltip-title",
      intl
    });
  };

  let customValidationState = null;

  if (invalidSrnAccessCode) {
    customValidationState = { result: validation.INVALID, message: "" };
  }

  const onTimeout = () => {
    redirectTo("/session-timeout", { message: "session timeout" });
  };

  const FaqModal = () => <Faq intl={intl} product={app.product} />;

  return (
    <div id="gyc-product-form">
      <WalkAwayModalTimer
        appTimeout={10 * 60 * 1000}
        onModalTimeout={onTimeout}
        onAppExit={onTimeout}
      />
      <section id="entry-gyc-header">
        <div className="row">
          <SubHeader
            title={<FormattedMessage id="get-your-card.title" />}
            description={
              <FormattedMessage
                id="get-your-card.text"
                values={{
                  registered: (
                    <sup>
                      <FormattedMessage id="common.registered" />
                    </sup>
                  ),
                  reservationNumber: (
                    <strong>
                      <FormattedMessage id="get-your-card.reservation.number.emphasize" />
                    </strong>
                  ),
                  accessCode: (
                    <strong>
                      <FormattedMessage id="get-your-card.access.code.emphasize" />
                    </strong>
                  )
                }}
              />
            }
            itemsList={[
              {
                id: intl.formatMessage({ id: "common.faq" }),
                modalComponent: FaqModal,
                icon: "oi-question show-for-medium"
              }
            ]}
          />
        </div>
      </section>
      <div id="product-entry-content">
        <form className="row column">
          <section className="row column">
            <div className="row column">
              <div className="row">
                <div className="small-12 medium-6 columns">
                  {invalidSrnAccessCode && (
                    <p
                      className="help-text error"
                      role="status"
                      aria-live="polite"
                      id="no-product-for-reservation-number-and-access-code"
                    >
                      <FormattedMessage id="get-your-card.invalid-reservation-access-code" />
                    </p>
                  )}

                  <ReservationNumber validationState={customValidationState} />
                  <AccessCode validationState={customValidationState} />

                  <div className="row align-center gyc-btn">
                    <div className="small-12 columns">
                      <button
                        data-cy="get-your-card-submit"
                        type="button"
                        className="button"
                        onClick={handleSubmit}
                      >
                        <FormattedMessage id="get-your-card.button" />
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <p>
                        <i className="oi-locked" />{" "}
                        <strong>
                          <FormattedMessage id="get-your-card.info-security" />
                        </strong>{" "}
                        <button
                          type="button"
                          className="buttonLink"
                          role="link"
                          aria-label={intl.formatMessage({ id: "get-your-card.info-security" })}
                          id="information-security-modal"
                          onClick={handleInformationSecurity}
                        >
                          <i className="oi-question" />
                        </button>
                      </p>
                      <p>
                        <FormattedMessage
                          id="get-your-card.info-security-features"
                          values={{
                            securityFeatures: (
                              <a
                                href={intl.formatMessage({
                                  id: "get-your-card.info-security-features-url"
                                })}
                                target="_blank"
                                rel="noopener noreferrer"
                                id="security-features"
                              >
                                <FormattedMessage id="get-your-card.info-security-features-link-text" />
                              </a>
                            )
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="small-12 medium-6 columns">
                  <div className="row align-left">
                    <div className="small-12 columns">
                      <h6>
                        <strong>
                          <FormattedMessage id="get-your-card.res-number-instructions1" />
                        </strong>
                      </h6>
                      <p>
                        <FormattedMessage id="get-your-card.res-number-instructions2" />
                      </p>
                      <div className="gyc-sample-box">
                        <strong>
                          <FormattedMessage id="get-your-card.sample-res-number" />
                        </strong>
                        <br />
                        <span>00X-XXXX-XXXX-XXXXX</span>
                        <br />
                        <strong>
                          <FormattedMessage id="get-your-card.sample-access-code" />
                        </strong>
                        <br />
                        <span>XXXXXX</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
};

GetYourCard.propTypes = {
  intl: intlShape.isRequired,
  isUYC: PropTypes.bool
};

export { GetYourCard as GetYourCardRaw };

export default injectIntl(GetYourCard);
