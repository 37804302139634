import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";
import { getSnippetTextHTML } from "../../lib/dom-utils";

import { CARD_NAME_SNIPPET } from "../../state/constants/product";
import { cardImageBrands } from "../../lib/product-utils";
import CardArt from "@cof/graffiti-alley-spray-cans/molecules/CardArt";

const KYCConcerned = ({ intl, productInfo, firstName, lastName, decision }) => {
  const f2fMessageValues =
    productInfo.locale === "en-CA"
      ? {
          determiner: productInfo.type === "upmarket" ? "an" : "a",
          productName: (
            <span
              dangerouslySetInnerHTML={getSnippetTextHTML(productInfo.snippets, CARD_NAME_SNIPPET)}
            />
          )
        }
      : {
          productName: (
            <span
              dangerouslySetInnerHTML={getSnippetTextHTML(productInfo.snippets, CARD_NAME_SNIPPET)}
            />
          )
        };

  function checkFundingConcernForMessage(hasFundingConcern) {
    if (hasFundingConcern) {
      return (
        <FormattedMessage id="decision.kyc-concerned.message.identity.step3.funding-concern" />
      );
    } else {
      return <FormattedMessage id="decision.kyc-concerned.message.identity.step3" />;
    }
  }

  return (
    <div data-cy="decision-page" className="column small-10 text-center decision-page">
      <h1>
        <FormattedMessage id="decision.kyc-concerned.title" />
      </h1>

      <CardArt
        brand={cardImageBrands(productInfo.brand)}
        firstName={firstName}
        lastName={lastName}
        classImageStyle=""
        showLegalDisclaimer={true}
      />
      <p>
        <FormattedMessage id="decision.kyc-concerned.message" values={f2fMessageValues} />
      </p>

      <div data-cy="f2f-decision-steps" className="text-left f2f-decision-steps">
        <p>
          <b>
            <FormattedMessage id="decision.kyc-concerned.message.identity" />
          </b>
        </p>
        <ol>
          <li>
            <b>
              <FormattedMessage id="decision.kyc-concerned.message.identity.step2.bold" />
            </b>
            <FormattedMessage
              id="decision.kyc-concerned.message.identity.step2"
              values={{
                identityInfoLink: (
                  <a
                    href={intl.formatMessage({
                      id: "decision.kyc-concerned.message.identity.info-url"
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="decision.kyc-concerned.message.identity.info-text" />
                  </a>
                )
              }}
            />
          </li>
          <li>{checkFundingConcernForMessage(decision?.concerns?.hasFundingConcern)}</li>
        </ol>
      </div>
      <p>
        <FormattedMessage
          id="decision.text.thank-you"
          values={{
            registered: (
              <sup>
                <FormattedMessage id="common.registered" />
              </sup>
            )
          }}
        />
      </p>
    </div>
  );
};

KYCConcerned.propTypes = {
  intl: intlShape.isRequired,
  decision: PropTypes.object,
  productInfo: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

export default KYCConcerned;
