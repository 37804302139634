import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const SecurityFundsQuickCheck = (props) => {
  return (
    <div id="security-funds-alert" className="callout small-11 medium-10 columns">
      <header>
        <h3 data-testid="decision.Text.Securing.SecurityFundsExplanation">
          <FormattedMessage id="decision.text.securing.security-funds-explanation" />
        </h3>
        <button
          className="close-button"
          aria-label={props.intl.formatMessage({ id: "common.close" })}
          type="button"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </header>

      <section className="content">
        <p data-testid="decision.Text.Securing.SecurityFundsModalQuickCheck">
          <FormattedMessage id="decision.text.securing.security-funds-modal.quick-check" />
        </p>
      </section>
    </div>
  );
};

SecurityFundsQuickCheck.propTypes = {
  intl: intlShape,
  onClose: PropTypes.func
};

export default SecurityFundsQuickCheck;
