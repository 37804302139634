import { getGUID } from "../session";
import getLogger from "@cof/client-side-logger";
import { throttle } from "lodash";

export const LOG_API_ENDPOINT = "/protected/954360/credit-cards/canada/acquisition/log";
export const THROTTLE_TIME = 0;

let logs = [];
const sendRequestAndClearQueue = () => {
  if (logs.length < 1) return;

  const serializedLogs = JSON.stringify(logs);

  fetch(LOG_API_ENDPOINT, {
    method: "POST",
    body: serializedLogs,
    headers: {
      Accept: "application/json;v=1",
      "Content-Type": "application/json"
    },
    keepalive: true
  }).then(() => {
    logs = [];
  });
};

const postLogMessagesToServer = throttle(sendRequestAndClearQueue, THROTTLE_TIME, {
  leading: false
});

const LOGGER_KEY_RENAME_MAP = {
  msg: "message",
  fileName: "filename"
};

const ALLOWED_ROOT_LEVEL_KEYS_PER_BACKEND_SCHEMA = [
  "level",
  "correlationId",
  "sessionId",
  "actionType",
  "time",
  "message",
  "userAgent",
  "name",
  "filename",
  "product",
  "decision"
];

const EXCLUDED_FIELDS = ["disclosures"];

const transformLogObject = (log) => {
  const transformedLog = { product: { additionalInfo: { origin: window?.origin ?? "" } } };

  // Don't Send Product Detail if Missing information
  const productId = log?.product?.id;

  // Product name is defined --> exception is assigned as product name.
  const hasException = log?.product?.name ?? false;
  if ((productId && productId.toUpperCase() !== "ERROR") || hasException) {
    transformedLog.product = { ...log.product, ...transformedLog.product };
  }

  for (const [key, value] of Object.entries(log)) {
    const renamedKey = LOGGER_KEY_RENAME_MAP[key] || key;

    if (key === "product") continue;

    if (ALLOWED_ROOT_LEVEL_KEYS_PER_BACKEND_SCHEMA.includes(renamedKey)) {
      transformedLog[renamedKey] = value;
    } else if (!EXCLUDED_FIELDS.includes(key)) {
      transformedLog.product.additionalInfo[key] = value;
    }
  }
  return transformedLog;
};

export const DEFAULT_WRITE_STREAM = (object) => {
  logs.push(transformLogObject(object));

  postLogMessagesToServer();
};

const enableConsoleStream = () => {
  if (process.env.NODE_ENV === "development") {
    return true;
  }
  return false;
};

export const DEFAULT_LOGGER_OPTIONS = {
  streams: { isConsoleStreamEnabled: enableConsoleStream() },
  name: "DynamicAppCanada", // The app name. This appears in console logs
  consoleAdornment: "DynamicAppCA", // A string that appears in console logs
  level: "info", // The log level
  fieldsToAdd: { actionType: "PAGE_LOAD" }, // This adds extra static context to each log message,
  // Note: the Web logger API expects log object in specific schema, any new key added here
  // should also be present on the Backend schema
  dynamicFields: {
    correlationId: () => getGUID(),
    userAgent: () => window.navigator.userAgent,
    url: () => `${window?.location?.pathname}${window?.location?.search}`,
    /*
     * Use the network API to gather network information while the customer is moving across pages.
     * This is an experimental API and does not have support on all browsers but as of Oct 2018, there
     * seems to be good support on mobile browsers. Having this information for mobile customers would
     * be very handy in analysing different network speeds. For browsers that are not supported, this value
     * will be undefined.
     */
    networkInformation: () => navigator?.connection || "No Network Information"
  }
};

export const getMessageAndObject = (e) => {
  const message = e.message;
  delete e.message;

  return [message, e];
};

let fileNameToLoggerMap = {};

/*
getWebLogger is used to log messages from non-react components realm. For instance, If you need to
log something inside a utility function, you can use it as:

const logger = getWebLogger("util.js");
logger.info(obj, message);
*/
const getWebLogger = (fileName) => {
  if (!fileName) {
    throw new Error(
      "Please provide a file name for logging using instance created with getLogger. " +
        "Note: If you are logging inside React Component, Please use useLogger Custom Hook. " +
        "The custom hook adds misc dynamic field while logging."
    );
  }

  if (!fileNameToLoggerMap[fileName]) {
    const logger = getLogger(DEFAULT_LOGGER_OPTIONS);
    fileNameToLoggerMap[fileName] = logger.getChildLogger(fileName);

    fileNameToLoggerMap[fileName].addStream({
      stream: {
        write: DEFAULT_WRITE_STREAM
      }
    });
  }

  const logInstance = fileNameToLoggerMap[fileName];

  return {
    info: (e) => {
      const [msg, obj] = getMessageAndObject(e);

      logInstance.info(obj, msg);
    },
    error: (e) => {
      const [msg, obj] = getMessageAndObject(e);

      logInstance.error(obj, msg);
    }
  };
};

export default getWebLogger;
