import React, { useContext, useEffect } from "react";
import { intlShape, FormattedMessage, injectIntl } from "react-intl";
import { pageLoadLogger } from "../../lib/logger";
import { AppContext } from "../../state/app-context";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import { changeCalypsoContext } from "../../lib/CalypsoUtil";
import useLogger from "../../hooks/useLogger";
import snowPlow from "../../lib/logger/snow-plow";

const ValidationError = ({ intl }) => {
  const { app } = useContext(AppContext);
  const { data } = useContext(SprayCanContext);
  const logger = useLogger("validation-error.js");
  const prefillKey = app.prefillKey;
  useEffect(() => {
    changeCalypsoContext("CA_ValidationError");
    pageLoadLogger(app.product, null, prefillKey);
    logger.info({ message: "In Validation error page", prefillKey });
  }, [app.product, prefillKey]);

  const uycinfo = { uycres: data.reservationNumber, uycacc: data.accessCode };
  try {
    snowPlow("page", "/validation-error", app.product, uycinfo);
  } catch (e) {
    //eslint-disable-next-line no-console
    console.warn(e);
  }

  return (
    <div
      data-testid="ValidationError"
      className="row column text-center error-page"
      data-cy="validation-error"
    >
      <i className="oi-caution outline header-icon" />
      <h1 className="h1-as-h2">
        <FormattedMessage id="validation-error.title" />
      </h1>
      <p data-testid="ValidationErrorTitle">
        <FormattedMessage
          id="validation-error.text"
          values={{
            linkLandingPage: (
              <a href={intl.formatMessage({ id: "validation-error.link.url" })}>
                <FormattedMessage id="validation-error.link" />
              </a>
            )
          }}
        />
      </p>
      <p data-testid="ValidationErrorNextStep">
        <FormattedMessage id="validation-error.next-step" />
      </p>
    </div>
  );
};

ValidationError.propTypes = {
  intl: intlShape
};

export { ValidationError as ValidationErrorRaw };
const ValidationErrorHOC = injectIntl(ValidationError);

ValidationErrorHOC.displayName = "ValidationError";
export default ValidationErrorHOC;
