import { stripSpecialCharacter } from "../../string-utils";
import { getChannelValue } from "../../server-info";
import { getGUID, getSessionConfig } from "../../../lib/session";
import { CHANNEL_PHONE } from "../../../state/constants/channel";
import { NS_TEST_SIN_PREFILL } from "../../affiliate-constants";

/*
 * Prepare employment status in accordance with api requirements
 * TODO: this logic should be moved to the app layer, so tha we don't expose our database structure
 */
const prepareEmploymentStatus = (status) => {
  if (status) {
    return {
      employed: "EMP",
      selfEmployed: "SEP",
      retired: "RET",
      student: "STU",
      unemployed: "UNE"
    }[status];
  }
  return status;
};

/*
 * Prepare account type in accordance with api requirements
 * TODO: this logic should be moved to the app layer, so tha we don't expose our database structure
 */
const prepareAccountData = (type) => {
  if (type) {
    return {
      "chequing-and-savings": "CAS",
      "chequing-only": "CHK",
      "savings-only": "SAV",
      neither: "NON"
    }[type];
  }

  return type;
};

/*
 * Prepare email in accordance with api requirements
 */
const prepareEmailData = (email, type) => {
  return {
    emailAddress: email,
    emailAddressType: type
  };
};

/*
 * Helper that sanitizes number (telephone, SIN etc.,) by removing special characters
 */
const sanitizeNumber = (number) => {
  return number.replace(/[^0-9]/g, "");
};

/*
 * Prepare phone number in accordance with api requirements
 */
const preparePhoneData = (number, type) => {
  return {
    telephoneNumber: sanitizeNumber(number),
    phoneNumberType: type
  };
};

/*
 * Prepare address in accordance with api requirements
 */
const prepareAddressData = (entryData, type) => {
  return {
    addressLine1: stripSpecialCharacter(entryData[`${type}Street`]),
    addressLine2: entryData[`${type}Apartment`],
    city: stripSpecialCharacter(entryData[`${type}City`]),
    stateCode: entryData[`${type}Province`],
    postalCode: entryData[`${type}PostalCode`],
    // TODO: investigate if this is better to include on the app tier
    countryCode: "CAN",
    countryName: "Canada"
  };
};

const prepareCashAdvancedData = (value) => {
  if (value) {
    return {
      yes: "Y",
      no: "N"
    }[value];
  }

  return value;
};

/*
 * Prepare mortgageStatus data for apptier (expects Y or N)
 */

const prepareMortgageStatusData = (value) => {
  if (value) {
    return {
      yes: "Y",
      no: "N"
    }[value];
  }
  return value;
};

/*
 * Prepare applicant in accordance with api requirements
 */
const prepareApplicantData = (entryData) => {
  const result = {
    firstName: entryData.personalFirstName || null,
    lastName: entryData.personalLastName || null,
    emailAddress: prepareEmailData(entryData.contactEmail, "pref"),
    phoneNumber: preparePhoneData(entryData.contactPhoneNumber, entryData.contactPhoneNumberType),
    address: prepareAddressData(entryData, "contact"),
    dateOfBirth: entryData.personalDob || null,
    taxId: sanitizeNumber(entryData.personalSin),
    addAuthorizedUser: entryData.authorizedUser || null,
    accountType: prepareAccountData(entryData.financialBankingInfo) || null,
    annualGrossIncome: entryData.financialAnnualIncome
      ? parseInt(entryData.financialAnnualIncome, 10)
      : null,
    otherHouseholdIncome: entryData.financialOtherIncome
      ? parseInt(entryData.financialOtherIncome, 10)
      : null,
    employmentStatus: prepareEmploymentStatus(entryData.employmentStatus) || null,
    employmentIndustry: entryData.employmentIndustry || null,
    employmentJobDescription: entryData.employmentJobDescription || null,
    currentEmployer: entryData.employmentCurrentEmployer,
    lengthOfTimeYear: entryData.employmentLengthYears || null,
    lengthOfTimeMonth: entryData.employmentLengthMonths || null,
    monthlyHousingPaymentAmount: entryData.financialMonthlyPayment
      ? parseInt(entryData.financialMonthlyPayment, 10)
      : "",
    isCashAdvanceCheckRequested: prepareCashAdvancedData(entryData.financialCashAdvance) || null,
    housingStatus: entryData.financialHousing || null,
    mortgageStatus: prepareMortgageStatusData(entryData.financialHasMortgage) || null,
    prefillToken: entryData.nsTestSinPrefill === NS_TEST_SIN_PREFILL ? entryData.prefillToken : null
  };

  if (entryData.employmentPhoneNumber) {
    result.businessPhoneNumber = preparePhoneData(entryData.employmentPhoneNumber, "work");
  }

  if (result.addAuthorizedUser) {
    result.authorizedUser = {
      firstName: entryData.authorizedFirstName || null,
      lastName: entryData.authorizedLastName || null,
      dateOfBirth: entryData.authorizedDob || null,
      phoneNumber: preparePhoneData(
        entryData.authorizedPhoneNumber,
        entryData.authorizedPhoneNumberType
      ),
      address: prepareAddressData(entryData, "authorized")
    };
  }

  return result;
};

/*
 * Prepare application submission data in accordance with api requirements
 */
export const getApiData = (entryData, productInfo, session) => {
  const channel = productInfo.channel || getChannelValue();
  let reqData = {
    clientID: session.clientID,
    channelType: channel, // this will be Internet / Phone
    isApplicationSigned: entryData.reviewPageConsentDisclosure
      ? "Y"
      : channel === CHANNEL_PHONE
      ? "Y"
      : "N",
    userAgent: window.navigator.userAgent
  };
  reqData.productId = productInfo.id;
  reqData.brand = productInfo.brand;
  reqData.productType = productInfo.type;
  reqData.languageCode = productInfo.locale;
  reqData.solicitedIndicator = productInfo.solicitedIndicator;
  reqData.active = productInfo.active;
  reqData.isUpgradeCardFlow = session.isUpgradeCardFlow;
  reqData.reservationNumber = session.reservationNumber;
  reqData.accessCode = session.accessCode;
  reqData.agentId = entryData.ctaAgentId;
  reqData.ani = "";
  reqData.applicant = prepareApplicantData(entryData);
  reqData.referralCode = entryData.referralCode || ""; // INFO: GMC should not have this value
  reqData.isPaperlessConsentProvided = entryData.paperlessConsent;

  return Promise.resolve(reqData);
};

/*
 * Prepare recurring application submition data in accordance with api requirements
 * If isGovernmentIdApi is true, prepare additional field for GovId policies for retrieving GovId url
 */
export const getRecurringApiData = (
  entryData,
  productInfo,
  sessionData,
  isGovernmentIdApi = false
) => {
  return getApiData(entryData, productInfo, sessionData).then((application) => {
    let result = {
      verificationId: sessionData.verificationId,
      applicationChecksum: sessionData.checksum,
      application
    };

    // verification session not always available (e.g: when tu is down)
    if (sessionData.verificationSession) {
      result.sessionReferenceId = sessionData.verificationSession;
    }

    // master build policy for GovId requires redirectUrl to come back to DAPP after finishing GovId
    if (isGovernmentIdApi) {
      const {
        governmentId: { redirectUrl }
      } = getSessionConfig();
      // DraftApp validate currentEmployer to be either null or string with minimum length of 1
      const currentEmployer = result.application.applicant.currentEmployer;
      result.application.applicant.currentEmployer =
        currentEmployer === "" ? null : currentEmployer;
      result.redirectUrl = `${redirectUrl}&productId=${productInfo.id}&govtIdcorid=${getGUID()}`;
    }

    return result;
  });
};
