// INFO: used for page tracking logs
//       when including a new alert, also include the taxonomy here
//
//       empty string => no page tracking or development error will be logged
//       null => taxonomy will be informed somewhere else
//       undefined => no page tracking, but a development error will show on console
export const alertsTaxonomyMap = (name) =>
  ({
    SessionTimer: "Session Timer modal window",
    Faq: "FAQ modal window",
    Security: "Security modal window",
    CardSummary: "Card Summary modal window",
    AuthorizedUserConsent: "Authorized User Consent modal window",
    // INFO: adding here as 'Print Disclosures' link is being treated as a popup for snowplow tracking
    PrintDisclosures: "Print Disclosures modal window",
    // Information security tooltip implemented as a modal window due to larger amounts of functionality/content
    GYCInfoSecurity: "GYC Info Security modal window",
    SecurityFunds: "Providing Security Funds information modal window",
    SecurityFundsQuickCheck: "Providing Security Funds Quick Check information modal window",
    AlternativeFundingMethods: "Alternative methods of funding modal window"
  }[name]);

// using stage-0 would result: export SessionTimer from './session-timer';
import _SessionTimer from "./session-timer";
export { _SessionTimer as SessionTimer };

// using stage-0 would result: export Faq from './faq';
import _Faq from "./faq";
export { _Faq as Faq };

// using stage-0 would result: export Security from './security';
import _Security from "./security";
export { _Security as Security };

// using stage-0 would result: export CardSummary from './card-summary';
import _CardSummary from "./card-summary";
export { _CardSummary as CardSummary };

// using stage-0 would result: export AuthorizedUserConsent from './authorized-user-consent';
import _AuthorizedUserConsent from "./authorized-user-consent";
export { _AuthorizedUserConsent as AuthorizedUserConsent };

// using stage-0 would result: export InformationSecurity from './information-security';
import _GYCInfoSecurity from "./information-security";
export { _GYCInfoSecurity as GYCInfoSecurity };

// using stage-0 would result: export SecurityFunds from './security-funds';
import _SecurityFunds from "./security-funds";
export { _SecurityFunds as SecurityFunds };

// using stage-0 would result: export SecurityFundsQuickCheck from './security-funds-quick-check';
import _SecurityFundsQuickCheck from "./security-funds-quick-check";
export { _SecurityFundsQuickCheck as SecurityFundsQuickCheck };

// using stage-0 would result: export AlternativeFundingMethods from './alternative-funding-methods';
import _AlternativeFundingMethods from "./alternative-funding-methods";
export { _AlternativeFundingMethods as AlternativeFundingMethods };

//Credit card Application Terms and Conditions alert on the Review Page from './application-terms';
import _ApplicationTerms from "./application-terms";
export { _ApplicationTerms as ApplicationTerms };

//Consent to Collection, Use, and Disclosure of your Personal Information alert on the Review Page from './privacy-terms';
import _PrivacyTerms from "./privacy-terms";
export { _PrivacyTerms as PrivacyTerms };

//Warning information about inapplicability of capitalone.ca privacy, terms and conditions when redirected to third party sites from capitalone.ca './third-party-terms';
import _ThirdPartyTerms from "./third-party-terms";
export { _ThirdPartyTerms as ThirdPartyTerms };
