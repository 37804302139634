import React, { useContext, useEffect } from "react";
import { injectIntl, intlShape } from "react-intl";
import * as decisionList from "../components/decisions";
import { addTaggingInfo, redirectTo } from "../lib/dom-utils";
import {
  APPLICATION_APPROVED,
  APPLICATION_DECLINED,
  APPLICATION_SOFT_DECLINED
} from "../state/constants/decision";

import { pageLoadLogger } from "../lib/logger";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import { AppContext } from "../state/app-context";
import { changeCalypsoContext } from "../lib/CalypsoUtil";
import useLogger from "../hooks/useLogger";

function velocityPageFlow(decision, logger) {
  const { concerns, applicationStatus } = decision;
  const status = applicationStatus;
  const { hasAlertConcern, hasKYCConcern, hasFundingConcern } = concerns;
  const msg = `In velocity page flow: status=${status}, hasAlertConcern=${hasAlertConcern}, hasKYCConcern=${hasKYCConcern}, hasfundingConcern=${hasFundingConcern}; `;

  if (APPLICATION_DECLINED.localeCompare(status?.toLowerCase()) === 0) {
    logger.info({ message: msg + "Result page = Declined" });
    changeCalypsoContext("CA_ApplicationDeclined");
    return decisionList.Declined;
  } else if (
    hasAlertConcern ||
    (APPLICATION_SOFT_DECLINED.localeCompare(status?.toLowerCase()) === 0 &&
      !hasAlertConcern &&
      !hasKYCConcern &&
      !hasFundingConcern)
  ) {
    logger.info({ message: msg + "Result page = application soft declined" });
    changeCalypsoContext("CA_ApplicationPending");
    return decisionList.SoftDeclined;
  } else if (hasKYCConcern) {
    logger.info({ message: msg + "Result page = hasKYConcern" });
    changeCalypsoContext("CA_ApplicationApproved");
    return hasFundingConcern ? decisionList.KYCConcerned : decisionList.ApprovedF2F;
  } else if (hasFundingConcern) {
    logger.info({ message: msg + "Result page = hasFundingConcern" });
    changeCalypsoContext("CA_ApplicationApproved");
    return decisionList.SecuredApproved;
  } else if (APPLICATION_APPROVED.localeCompare(status?.toLowerCase()) === 0) {
    logger.info({ message: msg + "Result page = Approved" });
    changeCalypsoContext("CA_ApplicationApproved");
    return decisionList.Approved;
  }
  logger.info({ message: msg + "Result page = cannot be route and will redirect to tech diff" });
  return null;
}

function preVelocityPageFlow(
  decision,
  decideApproval,
  faceToFaceRequired,
  type,
  minimumSecurityDepositAmount
) {
  switch (decision?.applicationStatus?.toLowerCase()) {
    case APPLICATION_APPROVED:
      changeCalypsoContext("CA_ApplicationApproved");
      return decideApproval(faceToFaceRequired, type, minimumSecurityDepositAmount);
    case APPLICATION_DECLINED:
      changeCalypsoContext("CA_ApplicationDeclined");
      return decisionList.Declined;
    case APPLICATION_SOFT_DECLINED:
      changeCalypsoContext("CA_ApplicationPending");
      return decisionList.SoftDeclined;
  }
  return null;
}

const ApplicationDecision = ({ intl }) => {
  const { data } = useContext(SprayCanContext);
  const { app } = useContext(AppContext);
  const { product } = app;
  const logger = useLogger("application-decision.js");

  useEffect(() => {
    logger.info({
      decision: app.decision,
      message: "In Application Decision Page"
    });
    pageLoadLogger(product, app.decision, app.prefillKey);
    addTaggingInfo(product, app.decision);
  }, [product, app.decision, app.prefillKey]);

  const decideApproval = (faceToFaceRequired, type, minimumSecurityDepositAmount) => {
    if (faceToFaceRequired) {
      return type === "secure" && minimumSecurityDepositAmount
        ? decisionList.SecuredApprovedF2F
        : decisionList.ApprovedF2F;
    } else {
      return type === "secure" && minimumSecurityDepositAmount
        ? decisionList.SecuredApproved
        : decisionList.Approved;
    }
  };

  const decisionProps = {
    referenceId: app.decision.applicationReferenceId,
    creditLimit: app.decision.creditLimit,
    decision: app.decision,
    productInfo: product,
    firstName: data.personalFirstName,
    lastName: data.personalLastName,
    creditKeeperBlock: app.decision.creditKeeperBlock,
    creditKeeperUrl: app.decision.creditKeeperUrl
  };

  const { snippets, productDisplayContent, type } = product;
  const { faceToFaceRequired, minimumSecurityDepositAmount } = app.decision;
  decisionProps.productInfo.snippets =
    productDisplayContent.cardSummary.length && productDisplayContent.ui.length
      ? productDisplayContent
      : snippets;

  let Decision;
  if (app.decision.concerns) {
    Decision = velocityPageFlow(app.decision, logger);
  } else {
    Decision = preVelocityPageFlow(
      app.decision,
      decideApproval,
      faceToFaceRequired,
      type,
      minimumSecurityDepositAmount
    );
  }

  if (!Decision) {
    redirectTo("/technical-difficulty", {
      message: `application processing returned an unexpected decision: ${app.decision.applicationStatus}`,
      ...product
    });
    return null;
  } else {
    return (
      <div id="app-decision" className="align-center align-middle">
        <Decision intl={intl} {...decisionProps} />
      </div>
    );
  }
};

ApplicationDecision.propTypes = {
  intl: intlShape
};
export { ApplicationDecision as ApplicationDecisionRaw };

const ApplicationDecisionHOC = injectIntl(ApplicationDecision);
ApplicationDecisionHOC.displayName = "ApplicationDecision";
export default ApplicationDecisionHOC;
