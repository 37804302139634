import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const AlternativeFundingMethods = (props) => {
  return (
    <section
      data-cy="alternative-funding-method"
      className="content"
      data-testid="alternativeFundingMethod"
    >
      <h3 data-testid="decision.Text.Securing.SecurityFundsCheque">
        <FormattedMessage id="decision.text.securing.security-funds-cheque" />
      </h3>
      <ul>
        <li data-testid="decision.Text.AlternativeFundingContentBullet1">
          <FormattedMessage
            id="decision.text.securing.alternative-funding-content-bullet1"
            values={{
              solicitedReferenceNumber: <strong>{props.solicitationReferenceNumber}</strong>
            }}
          />
        </li>
        <li data-testid="text.Securing.AlternativeFundingContentBullet2">
          <FormattedMessage id="decision.text.securing.alternative-funding-content-bullet2" />
        </li>
      </ul>
    </section>
  );
};

AlternativeFundingMethods.propTypes = {
  intl: intlShape,
  solicitationReferenceNumber: PropTypes.string
};

export default AlternativeFundingMethods;
