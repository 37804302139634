import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const SecuredApprovedNextSteps = ({ minimumSecurityDepositAmount, faceToFaceRequired, intl }) => {
  return (
    <div
      data-cy="secured-approved-next-steps"
      data-testid="secured-approved-next-steps"
      className="expanded secured-video-expanded-content"
    >
      <div className="row">
        <div className="column small-12">
          <div className="secured-video-next-steps-wrapper">
            <div className="column secured-video-next-steps-content">
              <h2 className="text-center" data-testid="secured-video-next-steps-title">
                <FormattedMessage id="decision.text.securing.video-next-steps-title" />
              </h2>
              <h4>
                <ol>
                  <li data-testid="decision-text-securing.video-next-steps-step1-item">
                    <FormattedMessage
                      id="decision.text.securing.video-next-steps-step1"
                      values={{
                        depositAmount: (
                          <b>
                            <span id="minimum-deposit" className="currency-value">
                              <span className="currency-sign">$</span>
                              {minimumSecurityDepositAmount}
                            </span>
                          </b>
                        )
                      }}
                    />
                  </li>
                  {faceToFaceRequired && (
                    <li data-testid="decision-text-securing.video-next-steps-step2-item">
                      <FormattedMessage
                        id="decision.text.securing.video-next-steps-step2"
                        values={{
                          learnMore: (
                            <a
                              href={intl.formatMessage({ id: "find-out-more.external.url" })}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FormattedMessage id="decision.text.securing.video-next-steps-step2-learn-more-link" />
                              .
                            </a>
                          )
                        }}
                      />
                    </li>
                  )}
                  <li data-testid="decision-text-securing.video-next-steps-step4-item">
                    <FormattedMessage id="decision.text.securing.video-next-steps-step4" />
                  </li>
                </ol>
              </h4>
            </div>
          </div>
          <div className="secured-video-closing-content">
            <h4 data-testid="decision-text-securing-video-next-steps-closer">
              <FormattedMessage id="decision.text.securing.video-next-steps-closer" />
            </h4>
            <h4 data-testid="decision-text-thank-you">
              <FormattedMessage id="decision.text.thank-you" />
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

SecuredApprovedNextSteps.propTypes = {
  intl: PropTypes.object,
  minimumSecurityDepositAmount: PropTypes.string,
  faceToFaceRequired: PropTypes.bool
};

export default SecuredApprovedNextSteps;
