import React, { Component } from "react";
import { intlShape, FormattedMessage } from "react-intl";

class PrivacyContent extends Component {
  static propTypes = {
    intl: intlShape.isRequired
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="drawer-content" id="privacy-link">
        <FormattedMessage id="privacy-content.heading1.content" tagName="h2" />
        <>
          <FormattedMessage id="privacy-content.paragraph1.content" />
          <ul data-cy="privacy-content.read-policy-links-list">
            <li>
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph1.link1Url" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph1.link1Text" />
              </a>
            </li>
            <li>
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph1.link2Url" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph1.link2Text" />
              </a>
            </li>
          </ul>
        </>
        <FormattedMessage id="privacy-content.paragraph2.content" tagName="p" />
        <FormattedMessage id="privacy-content.paragraph3.content" tagName="p" />
        <FormattedMessage id="privacy-content.paragraph13.content" tagName="p" />
        <p>
          <a
            href={intl.formatMessage({ id: "privacy-content.paragraph4.linkUrl" })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="privacy-content.paragraph4.linkText" />
          </a>
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="privacy-content.paragraph4.list1.content"
              values={{
                linkText: (
                  <a
                    href={intl.formatMessage({ id: "privacy-content.paragraph4.list1.linkUrl" })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="privacy-content.paragraph4.list1.linkText" />
                  </a>
                )
              }}
            />
          </li>
          <li>
            <FormattedMessage id="privacy-content.paragraph4.list2.content" />
          </li>
          <li>
            <FormattedMessage id="privacy-content.paragraph4.list3.content" />
          </li>
          <li>
            <FormattedMessage id="privacy-content.paragraph4.list4.content" />
          </li>
          <li>
            <FormattedMessage id="privacy-content.paragraph4.list5.content" />
          </li>
          <li>
            <FormattedMessage id="privacy-content.paragraph4.list6.content" />
          </li>
          <li>
            <FormattedMessage id="privacy-content.paragraph4.list7.content" />
          </li>
        </ul>
        <FormattedMessage id="privacy-content.paragraph5.content" />
        <ul>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list1.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list1.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list2.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list2.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list3.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list3.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list3.5.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list3.5.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list4.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list4.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list5.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list5.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list5.5.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list5.5.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list6.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list6.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list7.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list7.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph5.list8.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph5.list8.linkText" />
            </a>
          </li>
        </ul>
        <FormattedMessage id="privacy-content.paragraph5.5.content" tagName="p" />
        <FormattedMessage id="privacy-content.paragraph6.content" tagName="p" />
        <FormattedMessage
          id="privacy-content.paragraph7.content"
          tagName="p"
          values={{
            linkText1: (
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph7.linkUrl1" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph7.linkText1" />
              </a>
            ),
            linkText2: (
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph7.linkUrl2" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph7.linkText2" />
              </a>
            )
          }}
        />
        <FormattedMessage id="privacy-content.paragraph8.content" tagName="p" />
        <ul>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list1.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list1.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list2.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list2.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list3.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list3.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list4.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list4.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list5.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list5.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list6.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list6.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list7.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list7.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list8.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list8.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list9.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list9.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list10.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list10.linkText" />
            </a>
          </li>
          <li>
            <a
              href={intl.formatMessage({ id: "privacy-content.paragraph8.list11.linkUrl" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="privacy-content.paragraph8.list11.linkText" />
            </a>
          </li>
        </ul>

        <FormattedMessage id="privacy-content.paragraph10.content" tagName="p" />
        <FormattedMessage
          id="privacy-content.paragraph11.content"
          tagName="p"
          values={{
            linkText: (
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph11.linkUrl" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph11.linkText" />
              </a>
            )
          }}
        />
        <FormattedMessage
          id="privacy-content.paragraph12.content1"
          tagName="p"
          values={{
            linkText1: (
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph12.linkUrl1" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph12.linkText1" />
              </a>
            ),
            linkText3: (
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph12.linkUrl3" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph12.linkText3" />
              </a>
            )
          }}
        />
        <FormattedMessage
          id="privacy-content.paragraph12.content2"
          tagName="p"
          values={{
            linkText2: (
              <a
                href={intl.formatMessage({ id: "privacy-content.paragraph12.linkUrl2" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-content.paragraph12.linkText2" />
              </a>
            )
          }}
        />
      </div>
    );
  }
}

export default PrivacyContent;
