/* eslint-disable react/no-multi-comp */
import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";
import Snackbar from "@cof/graffiti-alley-spray-cans/molecules/Snackbar";
import { PATHS } from "../routes/routes-constants";
import { withRouter } from "react-router-dom";

const MainHeader = ({ id, intl, className, coLogo, location }) => {
  const showSnackBar = PATHS.pathsWithSnackBar.includes(location.pathname);
  return (
    <div>
      {showSnackBar && (
        <Snackbar id="ga-warning-test" type="warning" locale={intl.locale} intl={intl}>
          <FormattedMessage
            id="privacy-message"
            values={{
              termsOfPrivacy: (
                <a
                  href={intl.formatMessage({ id: "privacy.external.url" })}
                  target="_blank"
                  rel="noopener noreferrer"
                  id="privacy-terms-link"
                >
                  {intl.formatMessage({ id: "privacy-terms" })}
                </a>
              ),
              disableCookies: (
                <a
                  href={intl.formatMessage({ id: "privacyterms.external.url" })}
                  target="_blank"
                  rel="noopener noreferrer"
                  id="privacy-disable-cookies-link"
                >
                  {intl.formatMessage({ id: "privacy-disable-cookies" })}
                </a>
              )
            }}
          />
        </Snackbar>
      )}
      <div id={id} className={className}>
        <a
          href={intl.formatMessage({ id: "capital-one.external.url" })}
          id="app-header-cof-home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            id="coHeaderLogo"
            src={coLogo}
            alt={intl.formatMessage({ id: "common.capital-one" })}
          />
        </a>
      </div>
    </div>
  );
};

const AppHeader = ({ intl, location }) => {
  const coLogo = require("../assets/images/cof-logo.svg");
  const className = "expanded row column";
  const appHeaderId = "app-header";

  return (
    <div>
      <MainHeader
        id={appHeaderId}
        intl={intl}
        className={className}
        coLogo={coLogo}
        location={location}
        showHeader
      />
    </div>
  );
};

AppHeader.propTypes = {
  intl: intlShape.isRequired,
  children: PropTypes.array,
  toggleAlert: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

MainHeader.propTypes = {
  intl: intlShape,
  coLogo: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  showHeader: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default withRouter(AppHeader);
