import React, { useContext, useEffect } from "react";
import GetYourCard from "./get-your-card";
import { AppContext } from "../state/app-context";
import { APP_SET_IS_UPGRADE_CARD_FLOW } from "../state/constants/action-types";
import useLogger from "../hooks/useLogger";

const UpgradeYourCard = () => {
  const { appDispatch } = useContext(AppContext);
  const logger = useLogger("upgrade-your-card.js");

  useEffect(() => {
    logger.info({
      actionType: "VIEW_UPGRADE_YOUR_CARD_PAGE",
      message: "User is currently viewing the Upgrade Your Card page"
    });
    appDispatch({
      type: APP_SET_IS_UPGRADE_CARD_FLOW,
      value: true
    });
  }, []);

  return <GetYourCard isUYC={true} />;
};

export { UpgradeYourCard as UpgradeYourCardRaw };

export default UpgradeYourCard;
