import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";
import CardSummarySnippets from "../card-summary-snippets";

const CardSummary = (props) => {
  return (
    <div id="card-summary-alert" className="callout small-11 medium-10 large-8 columns">
      <h2>
        <FormattedMessage id="common.card-summary" />
      </h2>

      <section data-testid="CardSummarySnippets" className="content">
        <CardSummarySnippets cardSummary={props.cardSummary} className="hover" />
      </section>

      <button
        data-testid="CloseButton"
        className="close-button"
        aria-label={props.intl.formatMessage({ id: "common.close" })}
        type="button"
        onClick={props.onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

CardSummary.propTypes = {
  intl: intlShape,
  cardSummary: PropTypes.array.isRequired,
  onClose: PropTypes.func
};

export default CardSummary;
