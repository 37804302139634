import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const SecurityFunds = () => {
  return (
    <section data-cy="security-fund" className="content" data-testid="securityFund">
      <header>
        <h3 data-testid="decision.Text.Securing.SecurityFundsExplanation">
          <FormattedMessage id="decision.text.securing.security-funds-explanation" />
        </h3>
      </header>
      <p data-testid="decision.Text.Securing.SecurityFundsModal">
        <FormattedMessage id="decision.text.securing.security-funds-modal" />
      </p>
    </section>
  );
};

SecurityFunds.propTypes = {
  intl: intlShape
};

export default SecurityFunds;
