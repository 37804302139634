import initialState from "../initial-state";
import {
  APP_RESET,
  APP_SET_STAGE,
  APP_SET_SESSION,
  APP_IDENTITY_DECISION,
  APP_DECISION,
  APP_SET_CLIENT_ID,
  APP_HAS_BEEN_SUBMITTED,
  APP_STARTED,
  APP_SET_APIGW_TRAFFIC,
  RESET_QUICK_CHECK_FLAG,
  APP_SET_CTA_AGENT_ID,
  APP_SET_PRODUCT,
  APP_SET_IS_UPGRADE_CARD_FLOW
} from "../constants/action-types";

export default function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case APP_RESET:
      // reseting the app state should not affect GUID or SessionId
      return Object.assign({}, initialState.app, {
        status: { ...initialState.app.status },
        product: state.product
      });

    case APP_SET_STAGE:
      return Object.assign({}, state, {
        stage: action.stage
      });

    case APP_SET_PRODUCT:
      return Object.assign({}, state, {
        product: action.product
      });

    case APP_SET_CTA_AGENT_ID:
      return Object.assign({}, state, {
        ctaAgentId: action.ctaAgentId
      });

    case APP_SET_SESSION:
      return Object.assign({}, state, {
        checksum: action.checksum,
        verificationId: action.verificationId,
        verificationSession: action.verificationSession
      });

    case APP_SET_IS_UPGRADE_CARD_FLOW:
      return Object.assign({}, state, {
        isUpgradeCardFlow: action.value
      });

    case APP_IDENTITY_DECISION:
      return Object.assign({}, state, {
        verificationDecision: action.decision
      });

    case APP_DECISION:
      return Object.assign({}, state, {
        decision: action.decision
      });

    case APP_SET_CLIENT_ID:
      return Object.assign({}, state, {
        clientID: action.clientID
      });

    case APP_HAS_BEEN_SUBMITTED:
      return Object.assign({}, state, {
        hasAppBeenSubmitted: action.hasAppBeenSubmitted
      });

    case APP_STARTED:
      return Object.assign({}, state, {
        started: action.started
      });

    case APP_SET_APIGW_TRAFFIC:
      return Object.assign({}, state, {
        baseUrl: action.baseUrl,
        isApi: action.isApi
      });

    case RESET_QUICK_CHECK_FLAG:
      return Object.assign({}, state, {
        brands: { ...state.brands, quickCheck: false }
      });

    default:
      return state;
  }
}
