/*
 * Field level tracking for various events
 */
import React, { useContext } from "react";
import PropTypes from "prop-types";

import { LoggerPortal } from "../../portals";
import fieldTrackingMapper from "../../state/constants/field-tracking";
import { AppContext } from "../../state/app-context";

const eventHandler = (eventType, event, appContextProps) => {
  const { fieldType, snowplowEventHandler } = fieldTrackingMapper[`${eventType}`];
  const {
    type,
    parentNode: { nodeName, dataset }
  } = event.target;
  /*
   * When the user is running the mouse over, we do not want to capture
   * events that match the type of form fields. For example, for a field typed "text"
   * we do not want to capture an onMouseOver event when the mouse is hovered over
   * the text field.
   */
  if (eventType === "onClick") {
    let elementType = type;
    if (!type) {
      elementType = event.target.hasAttribute("role")
        ? event.target.getAttribute("role")
        : event.target.tagName;
    }
    const parentType = event.target.parentNode.hasAttribute("role")
      ? event.target.parentNode.getAttribute("role")
      : event.target.parentNode.tagName;

    if (
      fieldType.includes(elementType && elementType.toLowerCase()) ||
      fieldType.includes(parentType && parentType.toLowerCase())
    ) {
      snowplowEventHandler(event, appContextProps);
    }
  } else if (eventType !== "onMouseOver") {
    let elementType = type;
    if (!type && event.target.parentNode.hasAttribute("type")) {
      elementType = event.target.parentNode.getAttribute("type");
    }
    (fieldType.includes(elementType) || fieldType.includes(nodeName && nodeName.toLowerCase())) &&
      snowplowEventHandler(event, appContextProps);
  } else {
    fieldType.forEach((e) => {
      dataset && dataset[`${e}`] && snowplowEventHandler(event, appContextProps);
    });
  }
};

const FieldLevelTracker = (props) => {
  const {
    app: { decision, product }
  } = useContext(AppContext);
  const appContextProps = { product: product, decision: decision };
  return (
    <div
      id="field-level-tracker"
      onBlur={(event) => eventHandler("onBlur", event, appContextProps)}
      onFocus={(event) => eventHandler("onFocus", event, appContextProps)}
      onChange={(event) => eventHandler("onChange", event, appContextProps)}
      onPaste={(event) => eventHandler("onPaste", event, appContextProps)}
      onClick={(event) => eventHandler("onClick", event, appContextProps)}
      onMouseOver={(event) => eventHandler("onMouseOver", event, appContextProps)}
    >
      <LoggerPortal>{props.children}</LoggerPortal>
    </div>
  );
};

FieldLevelTracker.propTypes = {
  children: PropTypes.element
};

export default FieldLevelTracker;
