const MIME_TYPE = {
  TEXT_HTML: "text/html"
};

let BASE_URL = "/dynamicapp-canada";
if (process.env.NODE_ENV === "development") {
  // INFO: currently our proxy is redirecting root to: /dynamic-app-canada-apptier-web
  BASE_URL = "";
}

export { BASE_URL, MIME_TYPE };
