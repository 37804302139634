import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';

import Timer from '../timer';

const SessionTimer = (props) => {
  return (
    <div id="session-timeout" className="callout small-11 medium-10 large-12 columns">
      <div className="row column text-center">
        <h2><FormattedMessage id="timeout-warning.title" /></h2>
      </div>
      <div className="row column content">
        <p className="text-center"><FormattedMessage id="timeout-warning.text" /></p>
        <Timer time={props.alertTime} />
        <p className="text-center"><FormattedMessage id="timeout-warning.next-step" /></p>
      </div>
    </div>
  );
};

SessionTimer.propTypes = {
  alertTime: PropTypes.number,
  intl: intlShape
};

export default SessionTimer;
