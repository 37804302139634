import { FormattedMessage } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import Button from "@cof/graffiti-alley-spray-cans/molecules/Button";
import getWebLogger from "../../lib/logger/webLogger";

const ApprovedUserEnrolment = ({ decision }) => {
  const logger = getWebLogger("approved-enrolment-section.js");
  const handleClick = () => {
    logger.info({
      actionType: "ENROLMENT_BUTTON_CLICKED",
      message: "Applicant clicked the real time credential enrolment button"
    });
  };
  const enrolmentURL = decision.credentialEnrolmentLocation;
  return (
    <div
      data-cy="approved-enrolment"
      data-testid="approved.enrolment"
      id="enrolment"
      className="enrolment-wrapper"
    >
      <div className="row">
        <div className="column small-12 medium-7  enrolment-text-wrapper">
          <strong>
            <p data-testid="EnrolmentText" className="enrolment-text">
              <FormattedMessage id="decision.approve.enrolment.access" />
            </p>
          </strong>
          <p data-testid="DecisionApproveEnrolmentMessage">
            <FormattedMessage id="decision.approve.enrolment.message" />
          </p>
        </div>

        <div className="column small-12 medium-5 enrolment-button-wrapper">
          <Button url={enrolmentURL} onClick={handleClick} id="enrolment-button">
            <b>
              <FormattedMessage id="enrolment.button" />
            </b>
          </Button>
        </div>
      </div>
    </div>
  );
};

ApprovedUserEnrolment.propTypes = {
  decision: PropTypes.object
};

export default ApprovedUserEnrolment;
