import ReactDOM from "react-dom";

/*
 * Creating a portal to allow event bubbling from the child component.
 * The idea here is to make the entire application a child of the FieldLevelTracker.
 * The events that are propagated from the app don't bubble all the way back up because the events
 * are usually answered through redux actions. By creating a parent portal, we leverage the functionality
 * that react provides for us to bubble up the events to the parent portal. Any event fired from the child
 * are propagated (unless the event.stopPropagation is used) to the portal and then we handle the field level
 * tracking accordingly.
 */
const LoggerPortal = props => {
  const loggerRoot = document.getElementById("app");
  return ReactDOM.createPortal(props.children, loggerRoot);
};

export default LoggerPortal;
