import React, { useEffect } from "react";
import { intlShape, FormattedMessage, injectIntl } from "react-intl";
import Button from "../button";
import { changeCalypsoContext } from "../../lib/CalypsoUtil";

const EntryCTA = ({ intl }) => {
  useEffect(() => {
    changeCalypsoContext("CA_ApplicationAgentPage");
  }, []);
  return (
    <div data-cy="entry-cta" id="entry-cta-form">
      <section id="entry-cta-header">
        <div className="row">
          <header className="small-12 medium-9 columns">
            <h1>
              <FormattedMessage id="cta-entry.title" />
            </h1>
            <p>
              <FormattedMessage
                id="cta-entry.summary"
                values={{
                  trademark: (
                    <sup>
                      <FormattedMessage id="common.trademark" />
                    </sup>
                  ),
                  registered: (
                    <sup>
                      <FormattedMessage id="common.registered" />
                    </sup>
                  )
                }}
              />
            </p>
          </header>
        </div>
      </section>
      <div id="entry-cta-content">
        <form className="row column">
          <section id="entry-cta-opt" className="row column">
            <ul>
              <li>
                <FormattedMessage
                  id="cta-entry.instruction.bullet1"
                  values={{
                    attemptGuidedApplication: (
                      <i>
                        <FormattedMessage id="cta-entry.attempt.guided.application" />
                      </i>
                    )
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="cta-entry.instruction.bullet2"
                  values={{
                    reservationNumber: (
                      <strong>
                        <FormattedMessage id="get-your-card.reservation.number.emphasize" />
                      </strong>
                    ),
                    accessCode: (
                      <strong>
                        <FormattedMessage id="get-your-card.access.code.emphasize" />
                      </strong>
                    ),
                    solicited: (
                      <strong>
                        <FormattedMessage id="cta-entry.button.solicited" />
                      </strong>
                    )
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="cta-entry.instruction.bullet3"
                  values={{
                    askQualificationQuestions: (
                      <i>
                        <FormattedMessage id="cta-entry.ask.qualification.questions" />
                      </i>
                    ),
                    unsolicited: (
                      <strong>
                        <FormattedMessage id="cta-entry.button.unsolicited" />
                      </strong>
                    )
                  }}
                />
              </li>
            </ul>
            <div className="row column">
              <div className="row">
                <div className="small-12 medium-6 columns">
                  <Button
                    id="solicited-button"
                    url={intl.formatMessage({ id: "cta-entry.url.solicited" })}
                    buttonTitle={intl.formatMessage({ id: "cta-entry.button.solicited" })}
                  />
                </div>
                <div className="small-12 medium-6 columns">
                  <Button
                    id="unsolicited-button"
                    url={intl.formatMessage({ id: "cta-entry.url.unsolicited" })}
                    buttonTitle={intl.formatMessage({ id: "cta-entry.button.unsolicited" })}
                  />
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
};

EntryCTA.propTypes = {
  intl: intlShape
};

export { EntryCTA as EntryCTARaw };
const EntryCTAHOC = injectIntl(EntryCTA);

EntryCTAHOC.displayName = "EntryCTA";
export default EntryCTAHOC;
