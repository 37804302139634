import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import EntrySectionHeader from "../entry-form/entry-section-header";

const PrefillWarningReview = (props) => {
    const { intl } = props;

    return (
        <Fragment>
            <EntrySectionHeader
                intl={intl}
                id="review-and-verify-header"
                title="prefill-warning.review.page.header" />

            <div id="prefill-warning-review" className="callout warning prefill-warning-header review-warning-header">
                <h2><FormattedMessage id="prefill-warning.review.page.alert.header" /></h2>
                <p><FormattedMessage id="prefill-warning.review.page.alert.content" /></p>
            </div>
        </Fragment>
    );
};

PrefillWarningReview.propTypes = {
    intl: intlShape,
    onClose: PropTypes.func
};

export default PrefillWarningReview;


