import requestPost from "./base/request-post";
import { getRecurringApiData } from "./base/payload";
import { upstreamEnvHeaders } from "./base/traffic-split";
import config from "../../../config";
import getWebLogger from "../logger/webLogger";
/*
 * verify the identity of the applicant based on the data provided on the entry form
 */

export const submit = (entryData, productInfo, sessionData) => {
  const logger = getWebLogger("application.js");
  const baseUrl = config().apiGwBase;
  const url = `${baseUrl}/credit-cards/applications/submit`;
  logger.info({
    actionType: `CALL_TO_APIGW`,
    message: "App submit routed to API Gateway endpoint."
  });

  return getRecurringApiData(entryData, productInfo, sessionData).then((payload) => {
    const { upStreamSubmitEnvironmentWeight } = config();
    return requestPost(url, payload, "Application Submit API", {
      isCustomHeaders: true,
      extraHeaders: upstreamEnvHeaders(upStreamSubmitEnvironmentWeight)
    });
  });
};
