/* eslint-disable no-unused-vars */
import snowPlow from "./snow-plow";

// send logs to snowPlow
export const pageTracker = (url, product, pageType = "page", data = {}) => {
  if (process.env.NODE_ENV === "development") {
    if (!url) {
      console.warn("LOGGER: pageLoad method called without a url. Log being ignored"); // eslint-disable-line no-console
    }
  }

  let parsedUrl = url ? url.split("?")[0] : "";

  setTimeout(() => {
    snowPlow(pageType, parsedUrl, product, data);
  }, 0);
};

/*
 * filter the necessary product information to be used by logs
 */
export const getProductDetails = (product) => {
  // INFO: in the future we might need other information from the product to be sent

  return {
    id: product.id,
    type: product.type,
    brand: product.brand,
    solId: product.solId,
    testCellId: product.testCellId
  };
};

export const pageLoadLogger = (product, decision = "", prefillKey = null) => {
  const {
    disclosures,
    snippets, // eslint-disable-line no-unused-vars
    ...productInfo
  } = product;

  const url = `${window.location.pathname}${window.location.search}`;

  /*
   * Use the network API to gather network information while the customer is moving across pages.
   * This is an experimental API and does not have support on all browsers but as of Oct 2018, there
   * seems to be good support on mobile browsers. Having this information for mobile customers would
   * be very handy in analysing different network speeds. For browsers that are not supported, this value
   * will be undefined.
   */
  const networkInformation = navigator.connection || "No Network Information";
  pageTracker(url, productInfo, "page", decision);
};
