module.exports = {
  ENV: "prod",
  ENABLE_APM_BROWSER_AGENT: true,
  APM_BROWSER_AGENT_SCRIPT: "/static/js/newrelic.js",
  ENABLE_CALYPSO_BROWSER_AGENT: true,
  CALYPSO_BROWSER_AGENT_SCRIPT: "/static/js/calypso.min.js",
  APP_ICO: "/static/images/favicon.ico",
  SHAPE_SCRIPT: "/static/assets/enterprise/js/cof_common.js",
  SNOWPLOW_ENABLED: true,
  SNOWPLOW_ENDPOINT: "potomac-clickstream.capitalone.com",
  SNOWPLOW_COOKIE_DOMAIN: ".capitalone.ca",
  SNOWPLOW_SCRIPT: "potomac-tracker.capitalone.com/2.8.0/sp.js",
  GOOGLE_TAG_MANAGER_ENABLED: true,
  ENABLE_ANALYTICS: true,
  ENABLE_AFFILIATE_ENTRYPOINT: true,
  ENABLE_TAP_TO_VERIFY: true,
  ENABLE_UPGRADE_YOUR_CARD: true,
  ENABLE_EAV: true,
  ENABLE_PAPERLESS: false,
  ONETAG_ENDPOINT: "https://onetms.capitalone.com/ot/ot.js",
  ONETAG_ENABLED: false,
  ENABLE_SINPREFILL: true
};
