import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const SecuredApprovedVideoSection = (props) => {
  return (
    <div
      data-cy="secured-approved-video-section"
      data-testid="secured-approved-video-section"
      className="row text-center"
    >
      <div className="column small-12">
        <div className="row column secured-video-wrapper">
          <h2 data-testid="decision-text-securing-security-funds-subhead2">
            <FormattedMessage id="decision.text.securing.security-funds-subhead2" />
          </h2>
          <p data-testid="decision-text-securing-video-learn-more-content">
            <FormattedMessage id="decision.text.securing.video-learn-more-content" />
          </p>
          <iframe
            id="secured-approved-video"
            width="100%"
            height="315"
            src={props.videoSource}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

SecuredApprovedVideoSection.propTypes = {
  intl: PropTypes.object,
  videoSource: PropTypes.string
};

export default SecuredApprovedVideoSection;
