/*
 * HOC (High Order Component) to add obfuscation (unclear) capabilites to the input field
 */

// exporting since I need to integrate it with the mask-input hoc
export const DEFAULT_OBFUSCATE_CHAR = "*";

export const applyObfuscate = (
  rawValue,
  obfuscateLength,
  pattern,
  obfuscateChar = DEFAULT_OBFUSCATE_CHAR
) => {
  return rawValue.split("").map((char) => {
    if (obfuscateLength > 0 && pattern.test(char)) {
      obfuscateLength--;
      return obfuscateChar;
    } else {
      return char;
    }
  });
};
