import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';

const AuthorizedUserConsent = (props) => {
  return (
    <div id="au-consent-alert" className="callout small-11 medium-10 columns">
      <header>
        <h3><FormattedMessage id="au-disclosures.title" /></h3>
        <button
          className="close-button"
          aria-label={props.intl.formatMessage({ id: 'common.close' })}
          type="button"
          onClick={props.onClose} >
          <span aria-hidden="true">&times;</span>
        </button>
      </header>

      <section className="content">
        <p><FormattedMessage id="au-disclosures.text" /></p>
      </section>
    </div>
  );
};

AuthorizedUserConsent.propTypes = {
  intl: intlShape,
  onClose: PropTypes.func
};

export default AuthorizedUserConsent;
