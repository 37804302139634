import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';

const Tooltip = ({intl, id, intro, introValues, help}) => {
  return (
    <p className="help"
       aria-label={intl.formatMessage({ id: intro }, introValues)}
       aria-describedby={`sr-${id}`}>
      <span
        className="has-tip small-show-left"
        data-tooltip={intl.formatMessage({ id: help })}
        aria-hidden="true">
        <i className="oi-question outline" />
      </span>
      {/* the following span is only used by screen readers to read the tooltip */}
      <span id={`sr-${id}`} className="show-for-sr">{intl.formatMessage({ id: help})}</span>
    </p>
  );
};

Tooltip.propTypes = {
  intl: intlShape,
  id: PropTypes.string,
  intro: PropTypes.string,
  introValues: PropTypes.object,
  help: PropTypes.string
};

export default Tooltip;
