export const VALID_SNIPPETS = [
  "REWARDS_SNIPPET",
  "REWARDS_BONUS_SNIPPET",
  "REWARDS_REDEMPTION_SNIPPET",
  "PURCHASE_AIR_SNIPPET",
  "ANNUAL_FEE_SNIPPET",
  "FOREIGN_TRANSACTION_FEE"
];

export const SNIPPETS_TITLE = {
  // TODO: verify why these are being ignored
  //
  //   CREDIT_LINE_INCREASE_SNIPPET
  //   BRANDED_PRODUCT_NAME_SNIPPET
  //   PRIMARY_BENEFIT_SNIPPET
  //   CARD_NAME_SNIPPET
  //
  // TODO: the following are being ignored since we are not sure how iCatalist
  //       would handle images (quality, size, etc...)
  //
  //   CARD_ART_IMAGE_URL
  //   CARD_ART_APPROVED_IMAGE_URL
  //   CARD_ART_ALT
  //

  REWARDS_SNIPPET: "snippets.title.rewards",
  REWARDS_BONUS_SNIPPET: "snippets.title.rewards-bonus",
  REWARDS_REDEMPTION_SNIPPET: "snippets.title.rewards-redemption",
  PURCHASE_AIR_SNIPPET: "snippets.title.purchase-air",
  ANNUAL_FEE_SNIPPET: "snippets.title.annual-fee",
  FOREIGN_TRANSACTION_FEE: "snippets.title.foreign-transaction-fee"
};

export const VALID_LANGUAGES = ["en", "fr"];
export const MAX_CITY_FIELD_LIMIT = 20;
export const MAX_ADDRESS_FIELD_LIMIT = 35;
export const DEFAULT_CLIENT_ID = "DynamicAppCA";
export const QUICKCHECK_CLIENT_ID = "DynamicAppCA_QuickCheck";
export const NO_PRODUCT_ID = "0000";

export const CARD_FAQ = {
  personal: ["faq.email-required", "faq.account-private"],
  decision: ["faq.credit-limit", "faq.auto-payment"]
};

export const CARD_NAME_SNIPPET = "CARD_NAME_SNIPPET";
export const PRIMARY_BENEFIT_SNIPPET = "PRIMARY_BENEFIT_SNIPPET";

export const UPMARKET_PRODUCTS = ["ASPIRETRAVEL", "ASPIRECASH", "ASPIRETRAVELWORLDELITE"];
export const SECURED_PRODUCTS = [
  "GUARANTEEDSECUREDMASTERCARD",
  "GUARANTEEDMASTERCARD",
  "GUARANTEEDLOWRATEMASTERCARD",
  "NOFEEGUARANTEEDMASTERCARD",
  "GUARANTEEDMASTERCARD22PCT",
  "GUARANTEEDMASTERCARD26PCT",
  "GUARANTEEDMASTERCARD30PCT",
  "GUARANTEEDMASTERCARDDEPOSIT"
];

export const TITLE_TOGGLE_EN = "Apply for a Capital One Card";
export const TITLE_TOGGLE_FR = "Demander une carte Capital One";

export const LANGUAGES = {
  ENGLISH_CANADA: "en-CA",
  FRENCH_CANADA: "fr-CA",
  //because we consume English snippets from iCatalyst using language en-US
  ENGLISH_US: "en-US",
  //because we consume French snippets from iCatalyst using language es-US
  SPANISH_US: "es-US",
  //because en-US doubles up to give us English snippets from iCatalyst
  ENGLISH_ALL: ["en-CA", "en-US", "en"],
  //because es-US doubles up to give us French snippets from iCatalyst
  FRENCH_ALL: ["fr-CA", "es-US", "fr", "es"],
  ENGLISH: "en",
  FRENCH: "fr"
};

//Match brands returned by QuickCheck to the product code in Spray-Cans
export const BRAND_MAP = {
  ASPIRECASH: "acp",
  ASPIRETRAVEL: "atp",
  GUARANTEEDMASTERCARD: "gm",
  GUARANTEEDLOWRATEMASTERCARD: "lgm",
  GUARANTEEDSECUREDMASTERCARD: "gsm",
  NOFEEGUARANTEEDMASTERCARD: "nfb",
  NOFEESMARTREWARDSMASTERCARD: "nfsrm",
  AMFSMARTREWARDSMASTERCARD: "afs",
  GUARANTEEDMASTERCARD22PCT: "gm",
  GUARANTEEDMASTERCARD26PCT: "gm",
  GUARANTEEDMASTERCARD30PCT: "gm",
  GUARANTEEDMASTERCARDDEPOSIT: "gm"
};

export const SECURED_FUNDING = 300;
export const FUNDING_VIEWPOLICY = "funding";
export const LIST_VIEWPOLICY = "list";
export const COMPARE_VIEWPOLICY = "compare";

export const GM_CREDIT_LIMIT_EN = "$1,000";
export const GM_CREDIT_LIMIT_FR = "1 000 $";
export const GSM_FEATURE_4 = "Security funds are required";

export const UNSOLICITED = "UNS";
export const SOLICITED = "SOL";

export const UPMARKET_INDICATOR = "ASPIRE";
export const MIDMARKET_INDICATOR = "SMART";
