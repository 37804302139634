import React, { useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { pageLoadLogger } from "../../lib/logger";
import { AppContext } from "../../state/app-context";
import { changeCalypsoContext } from "../../lib/CalypsoUtil";
import useLogger from "../../hooks/useLogger";

const PageNotFound = () => {
  const {
    app: { product }
  } = useContext(AppContext);
  const logger = useLogger("page-not-found.js");
  useEffect(() => {
    changeCalypsoContext("CA_PageNotFound");
    logger.info({ message: "In Page Not found display" });
    pageLoadLogger(product);
  }, [product]);

  return (
    <div className="row column text-center error-page">
      <i className="oi-caution outline header-icon" />
      <h1 className="h1-as-h2">
        <FormattedMessage id="not-found.title" />
      </h1>
      <p>
        <FormattedMessage
          id="not-found.text"
          values={{
            homePageUrl: (
              <a href="https://www.capitalone.ca/credit-cards/compare">
                <FormattedMessage id="not-found.home-page-url" />
              </a>
            )
          }}
        />
      </p>
      <p>
        <FormattedMessage id="not-found.next-step" />
      </p>
    </div>
  );
};

export default PageNotFound;
