import initialState from "./initial-state";
import React, { useReducer } from "react";
import { default as AppReducer } from "./reducers/app-reducer";

const AppContext = React.createContext();
const { Provider } = AppContext;

// eslint-disable-next-line react/prop-types
const AppProvider = ({ children, qkRef }) => {
  const initialAppStateWithQKRef = { ...initialState.app, qkRef };
  const [appState, appDispatch] = useReducer(AppReducer, initialAppStateWithQKRef);

  return <Provider value={{ appDispatch, app: { ...appState } }}>{children}</Provider>;
};

export { AppContext, AppProvider };
