import requestHeaders from "./request-headers";
import { ServerError, NotAuthorized, BadRequest, ConnectionError } from "../../errors";

export default (url, payload, callDescription, options = {}) => {
  const { isCustomHeaders, extraHeaders } = options;
  const fetchAPIOptions = {
    method: "POST",
    headers: isCustomHeaders
      ? requestHeaders(url, extraHeaders)
      : requestHeaders(null, extraHeaders),
    body: JSON.stringify(payload)
  };

  // The fetch() API only rejects a promise when a network error is encountered.
  // The catch will handle any such failure cases. For all other HTTP status code,
  // the conditions below will do the appropriate error handlng.
  return fetch(url, fetchAPIOptions)
    .catch(() => {
      throw new ConnectionError(`Connection Error - ${callDescription}`);
    })
    .then((res) => {
      if (res.status >= 500) {
        throw new ServerError(null, res.status);
      } else if (res.status === 401) {
        throw new NotAuthorized(null, res.status);
      } else if (res.status >= 400) {
        throw new BadRequest(null, res.status);
      } else {
        return res.json();
      }
    });
};
