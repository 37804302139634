import React from "react";
import { render } from "react-dom";

// ### Static Global Assets
// ----------------------------------------------------------------------------
import "./assets/images/favicon.ico"; // webpack to load favicon.ico
import "./assets/styles/app.scss"; // root SASS style tree

import { Router } from "react-router-dom";
import history from "./routes/history";
import HashLinkScroll from "./components/hash-link-scroll";
import FeatureFlagsProvider from "./lib/FeatureFlagsContext";

import App from "./containers/app";

import {
  getCorrelationIdFromRedirect,
  getQuickCheckReferenceParameterFromRedirect,
  hasTitle,
  setTitle
} from "./lib/dom-utils";
import { freeHardcodedDisclosures, getEnv } from "./lib/server-info";
import { getLocale } from "./lib/session";

import { LANGUAGES, TITLE_TOGGLE_EN, TITLE_TOGGLE_FR } from "../src/state/constants/product";
const defaultLocale = LANGUAGES.ENGLISH;

import { SprayCanProvider } from "@cof/graffiti-alley-spray-cans/context/app-context";
import { AppProvider } from "./state/app-context";
import { FieldLevelTracker } from "./components/field-level-tracker";
import setupCalypsoWithRetry from "./lib/CalypsoUtil";

// When not available *cough*Safari*cough*IE<11*cough* inject polyfill with locale data
// It is important to load all supported languages (not only the current language)
if (!window.Intl) {
  require.ensure(
    ["intl", "intl/locale-data/jsonp/en.js", "intl/locale-data/jsonp/fr.js"],
    function (require) {
      window.Intl = require("intl");
      require("intl/locale-data/jsonp/en.js");
      require("intl/locale-data/jsonp/fr.js");
      runApp();
    },
    "intl-bundle"
  );
} else {
  runApp();
}

async function runApp() {
  if (!hasTitle()) {
    const locale = getLocale(window, {}, defaultLocale);
    setTitle(locale === LANGUAGES.FRENCH ? TITLE_TOGGLE_FR : TITLE_TOGGLE_EN);
  }

  // Keep the same GUID if present in query strings, otherwise generate a new one
  const guid = getCorrelationIdFromRedirect();
  const qkRef = getQuickCheckReferenceParameterFromRedirect();
  if (FEATURE_FLAGS.ENABLE_CALYPSO_BROWSER_AGENT) {
    setupCalypsoWithRetry(guid);
  }

  const env = getEnv();

  // cleanup - free-up the original disclosure variable, since its content is now
  //           part of the state
  freeHardcodedDisclosures();

  // bootstrap the app by getting the set locale
  // and then fetching locale data
  // and then rendering the react components

  const locale = getLocale(window, {}, defaultLocale);
  document.body.classList.add(`lang-${locale}`);
  document.documentElement.setAttribute("lang", locale);
  const messagesFr = require("../locale/fr.json");
  const messagesEn = require("../locale/en.json");
  const messages = Object.assign({ en: messagesEn }, { fr: messagesFr });
  render(
    <SprayCanProvider localeData={{ messages }} env={env}>
      <AppProvider qkRef={qkRef}>
        <FieldLevelTracker>
          <FeatureFlagsProvider value={FEATURE_FLAGS}>
            <Router history={history}>
              <HashLinkScroll>
                <App />
              </HashLinkScroll>
            </Router>
          </FeatureFlagsProvider>
        </FieldLevelTracker>
      </AppProvider>
    </SprayCanProvider>,
    document.getElementById("app")
  );
}
