import queryString from "query-string";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import React, { useContext } from "react";
import {
  INVALID_OFFER_URL,
  TECHNICAL_DIFFICULTY_URL,
  UNAUTH_PAGE_ACCESS_URL
} from "../routes/routes-constants";
import { VALID_LANGUAGES } from "../state/constants/product";
import { getServerError } from "../lib/server-info";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import { AppContext } from "../state/app-context";
import getWebLogger from "../lib/logger/webLogger";

const isValidLocale = (lang) => {
  return VALID_LANGUAGES.reduce((hasPrev, current) => hasPrev || lang.includes(current), false);
};

const logAction = (info) => {
  const logger = getWebLogger("product-route.js");
  logger.info(
    Object.assign({}, info, {
      actionType: "HISTORY_PUSH"
    })
  );
};

const checkProductInfo = (product) => {
  const qs = queryString.parse(window.location.search);
  if (product.code === 400 || product.code === 500) {
    return {
      isValid: false,
      url: TECHNICAL_DIFFICULTY_URL,
      message: "Unable to fetch product details",
      qsProductId: qs.productId,
      productId: product.id
    };
  }

  // If product Id cannot be mapped to an existing product, an error object is
  // returned in place of the product object and the status code of that error object
  // is 404.
  if (!product.id || !product.active) {
    return {
      isValid: false,
      url: INVALID_OFFER_URL,
      message: "invalid product",
      qsProductId: qs.productId,
      productId: product.id
    };
  } else if (product.id.toUpperCase() === "ERROR") {
    return {
      isValid: false,
      ul: TECHNICAL_DIFFICULTY_URL,
      message: "unable to validate product",
      qsProductId: qs.productId,
      productId: product.id
    };
  }

  if (!product.disclosures) {
    return {
      isValid: false,
      url: TECHNICAL_DIFFICULTY_URL,
      message: "missing disclosures",
      qsProductId: qs.productId,
      productId: product.id
    };
  }

  if (
    !(product.snippets || product.productDisplayContent) ||
    !product.locale ||
    !isValidLocale(product.locale) ||
    !product.type ||
    !product.brand ||
    !product.channel
  ) {
    return {
      isValid: false,
      url: TECHNICAL_DIFFICULTY_URL,
      message: "missing one or more product details",
      qsProductId: qs.productId,
      productId: product.id
    };
  }

  return { isValid: true };
};

const checkServerInfo = (product, reservationNumber, accessCode) => {
  const { solicitedIndicator } = product;
  let isUnAuthAccess = (solicitedIndicator && !reservationNumber && !accessCode) || false;

  const serverError = getServerError();

  if (serverError.code) {
    if (serverError.code.id === 102) {
      isUnAuthAccess = true;
    }
  }
  if (isUnAuthAccess) {
    const qs = queryString.parse(window.location.search);
    //ILLEGAL_GMC_PAGE_ACCESS error code is 102
    return {
      isValid: false,
      url: UNAUTH_PAGE_ACCESS_URL,
      message: "unauthorized page access",
      qsProductId: qs.productId,
      productId: qs.productId
    };
  }
  return { isValid: true };
};

function ProductRoute({ component: Component, ...rest }) {
  const {
    data: { reservationNumber, accessCode }
  } = useContext(SprayCanContext);
  const {
    app: { product }
  } = useContext(AppContext);
  const serverInfo = checkServerInfo(product, reservationNumber, accessCode);
  const productInfo = checkProductInfo(product);

  let redirectPath;

  if (!serverInfo.isValid) {
    logAction(serverInfo);
    redirectPath = serverInfo.url;
  } else if (!productInfo.isValid) {
    logAction(productInfo);
    redirectPath = productInfo.url;
  }

  return (
    <Route
      {...rest}
      render={({ props }) =>
        redirectPath ? (
          <Redirect
            to={{
              pathname: redirectPath
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

ProductRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default ProductRoute;
