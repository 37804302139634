import PropTypes from "prop-types";
import React, { useEffect } from "react";
import getWebLogger from "./logger/webLogger";

const NSTracker = (props) => {
  const logger = getWebLogger("ns-tracker.js");
  const { testName, onRender } = props;

  useEffect(() => {
    logger.info({ message: "Selected NS Test Cell: " + testName });
    if (onRender) {
      onRender(testName);
    }
  }, [testName]);

  return <span className={testName} id={testName}></span>;
};

NSTracker.propTypes = {
  testName: PropTypes.string,
  onRender: PropTypes.func
};

export default NSTracker;
