import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

import Tooltip from "../tooltip";

const EntrySectionHeader = ({ intl, id, title, help, warning, flagAsRequired }) => {
  return (
    <header className="row column text-center" data-testid={id}>
      <h2>
        <FormattedMessage id={title} />
      </h2>
      {help && (
        <Tooltip
          intl={intl}
          id={`tooltip-${id}`}
          intro="help.intro"
          introValues={{ item: intl.formatMessage({ id: title }) }}
          help={help}
        />
      )}
      {flagAsRequired && (
        <p className="help-text text-right" aria-hidden="true">
          <FormattedMessage id="common.required" />
        </p>
      )}
      {warning && (
        <p className="warning-message warning">
          <FormattedMessage id={warning} />
        </p>
      )}
    </header>
  );
};

EntrySectionHeader.propTypes = {
  intl: intlShape.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  help: PropTypes.string,
  warning: PropTypes.string,
  flagAsRequired: PropTypes.bool
};

export default EntrySectionHeader;
