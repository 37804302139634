import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

import Button from "./button";

const DeclinedCreditKeeper = ({ intl, creditKeeperUrl }) => {
  const creditKeeperLogo = require("../assets/images/CreditKeeperBeta.png");
  const creditKeeperLaptop = require("../assets/images/LaptopMobile.png");
  const url = creditKeeperUrl;

  const handleNavigation = (evt, target) => {
    evt.preventDefault();

    const el = document.getElementById(target);

    if (el && el.scrollIntoView) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="credit-keeper-content" data-testid="credit-keeper.content">
      <div data-testid="credit-keeper-prompt" className="credit-keeper-prompt">
        <a
          id="credit-keeper-link"
          href="#credit-keeper-section"
          role="link"
          onClick={(e) => handleNavigation(e, "credit-keeper-section")}
        >
          <i className="oi-arrow-left outline" aria-hidden="true" />
        </a>
      </div>

      <div id="credit-keeper-section" className="row column credit-keeper-wrapper">
        <div className="column small-12 medium-6">
          <div className="row credit-keeper-logo">
            <img id="credit-keeper-logo" src={creditKeeperLogo} alt="credit-keeper-logo" />
          </div>

          <div className="row">
            <h3>
              <FormattedMessage id="credit-keeper-section.title" />
            </h3>
          </div>

          <div className="row credit-keeper-laptop">
            <img
              id="credit-keeper-laptop"
              className=""
              src={creditKeeperLaptop}
              alt="creditKeeperLaptop"
            />
          </div>

          <div className="row credit-keeper-left-col">
            <ul data-testid="CreditKeeperSectionDescriptionBullet1">
              <li>
                <FormattedMessage
                  id="credit-keeper-section.description.bullet1"
                  values={{
                    trademark: (
                      <sup>
                        <FormattedMessage id="common.trademark" />
                      </sup>
                    ),
                    registered: (
                      <sup>
                        <FormattedMessage id="common.registered" />
                      </sup>
                    )
                  }}
                />
              </li>
              <li data-testid="CreditKeeperSectionDescriptionBullet2">
                <FormattedMessage id="credit-keeper-section.description.bullet2" />
              </li>
              <li>
                <FormattedMessage id="credit-keeper-section.description.bullet3" />
              </li>
            </ul>
          </div>

          <div className="row">
            <div className="credit-keeper-button">
              <Button
                id="credit-keeper"
                url={url}
                buttonTitle={intl.formatMessage({ id: "common.get-started" })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row ck-legal-type">
        <div className="column small-12">
          <p data-testid="CreditKeeperSectionLegalFirstParagraph">
            <FormattedMessage id="credit-keeper-section.legal.first.paragraph" />
            <br />
            <FormattedMessage id="credit-keeper-section.legal.second.paragraph" />
          </p>
        </div>
      </div>
    </div>
  );
};

DeclinedCreditKeeper.propTypes = {
  intl: intlShape.isRequired,
  decision: PropTypes.object,
  product: PropTypes.object,
  creditKeeperUrl: PropTypes.string
};

export default DeclinedCreditKeeper;
