// INFO: used for page tracking logs
//       when including a new status, also include the taxonomy here
//
//       empty string => no page tracking or development error will be logged
//       null => taxonomy will be informed somewhere else
//       undefined => no page tracking, but a development error will show on console
export const statusTaxonomyMap = name =>
  ({
    Processing: "", // INFO: generic processing status shouldn't be logged
    AppSubmit: "application processing"
  }[name]);

// using stage-0 would result: export Processing from './processing';
import _Processing from "./processing";
export { _Processing as Processing };

// using stage-0 would result: export AppSubmit from './app-submit';
import _AppSubmit from "./app-submit";
export { _AppSubmit as AppSubmit };
