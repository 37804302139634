import { CHANNEL_INTERNET, CHANNEL_PHONE } from "../state/constants/channel";
import { LANGUAGES } from "../state/constants/product";
import { getChannelFromQS, getProductId, getOrigin, getAffiliateProductParams } from "./dom-utils";
import { addressValidation, nameValidation } from "./string-utils";
import { buildProductDisplayContent, extractSnippetLanguage } from "./product-utils.js";
import { product as productInfoApi, creditOffers as creditOffersApi } from "../lib/api";
import { BasicModel, ObjectModel } from "objectmodel";
import getWebLogger from "./logger/webLogger";

export const extractProductInfo = (isBrandInformation = false) => {
  // first check the required structure
  if (
    !window.PRODUCTINFO ||
    !window.PRODUCTINFO.productDetail ||
    !window.PRODUCTINFO.productDetail.entries ||
    !window.PRODUCTINFO.productDetail.entries[0]
  ) {
    return {};
  }

  return isBrandInformation
    ? window.PRODUCTINFO.productDetail.entries
    : window.PRODUCTINFO.productDetail.entries[0];
};

export const extractSnippets = (productInfo) => {
  if (Object.keys(productInfo).length === 0 || !productInfo.snippets) {
    return {
      cardSummary: [],
      ui: []
    };
  }

  return {
    cardSummary: productInfo.snippets.cardSummary,
    ui: productInfo.snippets.ui
  };
};

export const getEnv = () => {
  return FEATURE_FLAGS.ENV;
};

export const validateQKPrefill = (applicant) => {
  const logger = getWebLogger("server-info.js");
  const StringNotBlank = BasicModel(String)
    .assert(function isNotBlank(str) {
      return str.trim().length > 0;
    })
    .as("StringNotBlank");

  const SkinnyDappMandatory = new ObjectModel({
    firstName: StringNotBlank,
    lastName: StringNotBlank,
    dateOfBirth: StringNotBlank,
    address: {
      addressLine1: StringNotBlank,
      city: StringNotBlank,
      stateCode: StringNotBlank,
      postalCode: StringNotBlank
    },
    phoneNumber: {
      telephoneNumber: StringNotBlank,
      telephoneNumberType: StringNotBlank
    },
    employmentStatus: StringNotBlank,
    emailAddress: StringNotBlank,
    annualGrossIncome: StringNotBlank,
    otherHouseholdIncome: StringNotBlank,
    financialMonthlyPayment: StringNotBlank
  });
  let res = {};

  if (applicant && applicant.address && applicant.phoneNumber) {
    res = {
      personalFirstName: nameValidation(applicant.firstName) || "",
      personalLastName: nameValidation(applicant.lastName) || "",
      personalDob: applicant.dateOfBirth || "",
      contactHomeAddress: addressValidation(applicant.address.addressLine1) || "",
      contactStreet: addressValidation(applicant.address.addressLine1) || "",
      contactApartment: applicant.address.addressLine2 || "",
      contactCity: addressValidation(applicant.address.city) || "",
      contactProvince: applicant.address.stateCode || "",
      contactPostalCode: applicant.address.postalCode || "",
      contactEmail: applicant.emailAddress || "",
      contactPhoneNumber: applicant.phoneNumber.telephoneNumber || "",
      contactPhoneNumberType: applicant.phoneNumber.telephoneNumberType || "",
      financialAnnualIncome: applicant.annualGrossIncome || "",
      financialOtherIncome: applicant.otherHouseholdIncome || "",
      financialCashAdvance: applicant.isCashAdvanceCheckRequested || "",
      financialMonthlyPayment: applicant.financialMonthlyPayment || "",
      employmentStatus: applicant.employmentStatus || "",
      financialHousing: applicant.housingStatus || "",
      prefill: true,
      authorizedOnelineAddress: false,
      contactOnelineAddress: false,
      qkPrefilledEmail: applicant.emailAddress || "",
      prefillToken: FEATURE_FLAGS.ENABLE_SINPREFILL ? applicant.encryptedTaxId || "" : "",
      taxIdLastTwo: FEATURE_FLAGS.ENABLE_SINPREFILL ? applicant.taxIdLastTwo || "" : ""
    };

    SkinnyDappMandatory.test(applicant, function (errors) {
      const invalidPrefillKeySet = new Set(errors.map((e) => e.path));

      logger.info({
        actionType: "INVALID_QUICKCHECK_PREFILL_DATA",
        message: "Following prefill data are invalid: " + Array.from(invalidPrefillKeySet)
      });
    });
  } else {
    logger.info({
      actionType: "INVALID_QUICKCHECK_PREFILL_DATA",
      message: "Empty applicant or address or phonenumber data"
    });
  }
  return res;
};

export const getApplicantInfo = () => {
  if (!window.QK || !window.atob) {
    return {};
  }

  try {
    const entry = JSON.parse(atob(window.QK));
    const applicant = entry.applicant;

    const res = validateQKPrefill(applicant);
    let qckElement = document.getElementById("qck");
    if (qckElement) {
      qckElement.innerHTML = "";
    }

    return res;
  } catch (ex) {
    return {};
  }
};

export const getProductInfo = () => {
  const result = {};

  // INFO: For the find your card flow, we receive multiple brand information in which case
  //       the productId in the PRODUCTINFO would be null.
  if (!window.PRODUCTINFO || !window.PRODUCTINFO.productId) {
    return result;
  }

  const productInfo = extractProductInfo();

  // INFO: there might be a case where PRODUCTINFO.productId has a content even when
  //       product info detail is not available
  if (Object.keys(productInfo).length === 0) {
    if (window.PRODUCTINFO && window.PRODUCTINFO.productId) {
      result.id = window.PRODUCTINFO.productId;
    }

    return result;
  }

  return {
    solId: window.PRODUCTINFO.solId,
    // TODO: the back end should be calling this testCellId
    testCellId: window.PRODUCTINFO.testCell,
    id: window.PRODUCTINFO.productId,
    brand: productInfo.brand,
    type: productInfo.productType,
    locale: productInfo.languageCode,
    solicitedIndicator: productInfo.solicitedIndicator,
    snippets: extractSnippets(productInfo),
    productDisplayContent: buildProductDisplayContent(productInfo),
    active: productInfo.active,
    channel: productInfo.channel,
    campaign: productInfo.campaign
  };
};

export const getBrandSnippet = (snippetArray, languageCode) => {
  const brand = {};

  snippetArray &&
    snippetArray
      .filter(
        ({ language: snippetLanguage = LANGUAGES.ENGLISH_CANADA }) =>
          snippetLanguage ===
          (languageCode === LANGUAGES.FRENCH_CANADA
            ? extractSnippetLanguage(LANGUAGES.FRENCH_ALL, snippetLanguage)
            : extractSnippetLanguage(LANGUAGES.ENGLISH_ALL, snippetLanguage))
      )
      .forEach(({ type, text }) => (brand[type] = text));

  return brand;
};

export const getServerError = () => {
  if (window.SERVERERROR) {
    return window.SERVERERROR;
  }

  return {};
};

export const getDisclosure = () => {
  if (window.PRODUCTINFO && window.PRODUCTINFO.disclosureHTML) {
    return window.PRODUCTINFO.disclosureHTML;
  }

  return null;
};

export const getServerVersion = () => {
  if (window.APP && window.APP.serverVersion) {
    return window.APP.serverVersion;
  }

  // no need for translation, since this is not a customer facing information
  return "unspecified";
};

export const freeHardcodedDisclosures = () => {
  if (window.PRODUCTINFO && window.PRODUCTINFO.disclosureHTML) {
    delete window.PRODUCTINFO.disclosureHTML;
  }
};

export const getChannelValue = () => {
  const productInfo = extractProductInfo(); // TODO: should look for state as well as apptier HTML
  if (productInfo.channel) {
    return productInfo.channel;
  }

  // Get from search
  const channel = getChannelFromQS();
  return channel ? channel : CHANNEL_INTERNET;
};

export const isCTA = (productInfo) => {
  if (Object.keys(productInfo).length === 0 || !productInfo.channel) {
    return false;
  }

  return productInfo.channel === CHANNEL_PHONE;
};

/**
 * Get the documentResult sent from apptier redirected from Government ID
 * for traffics that starts on the application at /validate page.
 */
export const getDocumentResult = () => {
  return (window.APP && window.APP.documentResult) || null;
};

/**
 * ResolveTimeout will set TIME_OUT when it exceeds the timer.
 */
const resolveTimeout = async (timerInMs) => {
  await new Promise((resolve) => {
    setTimeout(resolve, timerInMs, "TIME_OUT");
  });
};

export const getApplicantInfoFromApi = async (prefillKey) => {
  const logger = getWebLogger("server-info.js");
  let applicantInfo = {};
  if (prefillKey) {
    // Resolve timeout - redirect the user to an empty applications within 10sec of prefill not loading.
    const value = await Promise.race([
      creditOffersApi.getApplicantInfo(prefillKey),
      resolveTimeout(10000)
    ]);
    if (value && value === "TIME_OUT") {
      logger.info({
        actionType: "GET_APPLICANT_TIMEOUT",
        message: "Timed Out while calling Credit Offers Get Applicant endpoint",
        prefillKey: prefillKey
      });
    } else {
      applicantInfo = value;
    }
  }
  return applicantInfo;
};

export const getApplicantInfoFromOrch = async (partnerCode, prefillKey) => {
  let applicantInfo = {};
  const logger = getWebLogger("server-info.js");

  if (prefillKey) {
    // Resolve timeout - redirect the user to an empty applications within 10sec of prefill not loading.
    const value = await creditOffersApi.getApplicantInfoFromOrch(partnerCode, prefillKey);
    if (!value || Object.keys(value).length === 0) {
      logger.info({
        actionType: "FAILED_TO_GET_APPLICANT",
        message: "Failed to get applicant info from prefill orchestrator service",
        prefillKey: prefillKey
      });
    } else {
      applicantInfo = value;
    }
  }
  return applicantInfo;
};

export const getProductFromOrchestrator = async (prefill = false) => {
  let result = {};
  const logger = getWebLogger("server-info.js");
  const productId = getProductId();
  const productParams = getAffiliateProductParams();

  if (!productId && Object.keys(productParams).length === 0) {
    return result;
  }

  try {
    if (productId && !prefill) {
      // method will be implemented in product.js
      result = await productInfoApi.getProduct(productId);
    } else if (prefill) {
      // method will be implemented in product.js
      result = await productInfoApi.getProducts(productParams);
    }
  } catch (e) {
    logger.info({
      actionType: "PRODUCT_INFO_BAD_REQUEST",
      url: getOrigin(),
      message: e
    });
  }
  return result;
};
