import requestPost from "./base/request-post";
import { getApiData } from "./base/payload";
import { upstreamEnvHeaders } from "./base/traffic-split";
import config from "../../../config";
import getWebLogger from "../logger/webLogger";

/*
 * check the identity of the applicant based on the data provided on the entry form
 */
export const check = (entryData, productInfo, session) => {
  const logger = getWebLogger("identity.js");
  const baseUrl = config().apiGwBase;
  const url = `${baseUrl}/credit-cards/applications/identity-verification`;
  logger.info({
    actionType: `CALL_TO_APIGW`,
    message: "IDV check routed to API Gateway endpoint."
  });

  return getApiData(entryData, productInfo, session).then((payload) => {
    const { upStreamIdvEnvironmentWeight } = config();
    return requestPost(url, payload, "Identity Check API", {
      isCustomHeaders: true,
      extraHeaders: upstreamEnvHeaders(upStreamIdvEnvironmentWeight)
    });
  });
};
