import React, { useEffect, useState, useContext } from "react";
import { intlShape, injectIntl } from "react-intl";
import { pageLoadLogger } from "../lib/logger";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import { TechDifficultyApp, TechDifficultyQuickCheck } from "../components/error-pages";
import { APP_SET_STAGE, RESET_QUICK_CHECK_FLAG } from "../state/constants/action-types";
import { AppContext } from "../state/app-context";
import { changeCalypsoContext } from "../lib/CalypsoUtil";
import useLogger from "../hooks/useLogger";

const TechnicalDifficulty = ({ intl }) => {
  const logger = useLogger("technical-difficulty.js");
  const [quickCheck, setQuickCheck] = useState(false);
  const {
    data: { personalFirstName }
  } = useContext(SprayCanContext);
  const { app, appDispatch } = useContext(AppContext);

  useEffect(() => {
    logger.info({ message: "In Technical Difficulty Page" });
    pageLoadLogger(app.product, null, app.prefillKey);
    changeCalypsoContext("CA_TechnicalDifficulty");
    appDispatch({
      type: APP_SET_STAGE,
      stage: 10
    });
    setQuickCheck(app.brands.quickCheck);
    appDispatch({
      type: RESET_QUICK_CHECK_FLAG
    });
  }, [app.brands.quickCheck, app.prefillKey, app.product, appDispatch]);

  const decision = app.decision;
  const techDifficultyProps = { intl, personalFirstName, decision };
  const TechDifficulty = quickCheck ? TechDifficultyQuickCheck : TechDifficultyApp;
  return (
    <div>
      <TechDifficulty {...techDifficultyProps} />
    </div>
  );
};

TechnicalDifficulty.propTypes = {
  intl: intlShape.isRequired
};

export { TechnicalDifficulty as TechnicalDifficultyRaw };
const TechnicalDifficultyHOC = injectIntl(TechnicalDifficulty);
TechnicalDifficultyHOC.displayName = "TechnicalDifficulty";
export default TechnicalDifficultyHOC;
