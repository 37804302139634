import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const Processing = (props) => {
  return (
    <div className="row small-12 medium-10 columns">
      <div className="loading text-center">
        {props.icon && (
          <p>
            <i className={props.icon} />
          </p>
        )}
        <p>
          <FormattedMessage id={props.message} />
        </p>
        <div data-testid="Processing.bar" className="bar" />
      </div>
    </div>
  );
};

Processing.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.string
};

export default Processing;
