import PropTypes from 'prop-types';
import React from 'react';

const CardSummarySnippets = ({cardSummary, className}) => {

  const htmlSnippets = cardSummary.map((snippet, index) => {
    let style;
    if (index === 0) {
      style = {width: '30%'};
    }

    return (
      <tr key={index}>
        <td style={style}><b>{snippet.title}</b></td>
        <td>{snippet.text}</td>
      </tr>
    );
  });

  return (
    <table className={className}>
      <tbody>{htmlSnippets}</tbody>
    </table>
  );
};

CardSummarySnippets.propTypes = {
  cardSummary: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default CardSummarySnippets;

