import React, { useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { pageLoadLogger } from "../../lib/logger";
import { AppContext } from "../../state/app-context";
import { changeCalypsoContext } from "../../lib/CalypsoUtil";
import useLogger from "../../hooks/useLogger";

const LockoutError = () => {
  const { app: product } = useContext(AppContext);
  const logger = useLogger("lockout-error.js");
  useEffect(() => {
    changeCalypsoContext("CA_LockoutError");
    logger.info({ message: "In Lockout error Page" });
    pageLoadLogger(product);
  }, [product]);

  return (
    <div className="row column text-center error-page">
      <i className="oi-caution outline header-icon" />
      <h2>
        <FormattedMessage id="lock-error.title" />
      </h2>
      <p>
        <FormattedMessage id="lock-error.text" />
      </p>
    </div>
  );
};

export default LockoutError;
