import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const Security = (props) => {
  return (
    <div id="security-alert" className="callout small-10 medium-8 columns">
      <h2>
        <FormattedMessage id="common.security" /> <i className="oi-locked outline" />
      </h2>
      <section className="content" data-testid="securityText">
        <FormattedMessage id="security.text" />
      </section>

      <button
        data-testid="closeButton"
        className="close-button"
        aria-label={props.intl.formatMessage({ id: "common.close" })}
        type="button"
        onClick={props.onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

Security.propTypes = {
  intl: intlShape,
  onClose: PropTypes.func
};

export default Security;
