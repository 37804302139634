import { getGUID } from "../session";
import { routesTaxonomyMap } from "../../routes";
import { statusTaxonomyMap } from "../../components/status";
import { alertsTaxonomyMap } from "../../components/alerts";
import { decisionTaxonomyMap } from "../../components/decisions";
import { schemas, trackingEvents, elementTypes, SNOWPLOW_FAILURE } from "./snow-plow-constants";
import { APPLICATION_DECISION_URL } from "../../routes/routes-constants";
import { getCurrentUrlPathName } from "../../lib/dom-utils";
import getWebLogger from "./webLogger";
import {
  trackUserInteraction,
  trackUserPageView,
  trackInputUserInteraction,
  trackmouseoverUserInteraction
  //trackFieldTracking
} from "./oneTag";

const selfDescribingEvents = trackingEvents("TRACK_SELF_DESCRIBING_EVENT");

const getEventName = (pageType, url, info) => {
  const logger = getWebLogger("snow-plow.js");
  let eventName = "";
  switch (pageType) {
    case "page":
      if (APPLICATION_DECISION_URL.includes(url)) {
        eventName = info.applicationStatus
          ? decisionTaxonomyMap(info.applicationStatus, info.faceToFaceRequired)
          : eventName;
      } else {
        eventName = routesTaxonomyMap(url);
      }
      break;
    case "popup":
      eventName = alertsTaxonomyMap(url);
      break;
    case "status":
      eventName = statusTaxonomyMap(url);
      break;
    default:
      logger.info({
        actionType: "FAIL_PAGE_TRACKER",
        message: "invalid page type prevented Snowplow log",
        pageType
      });
      return;
  }
  return eventName;
};

const isPayloadDataValid = (payloadData, validatorData) => {
  // Verify Payload Types are valid
  const isPayloadDataTypeValid = validatorData.type.includes(typeof payloadData);
  // Since typeof null returns object, validation with typeof will fail
  // We need to check explictly for null data and check if validator's schema support null value
  const isPayloadDataNull = payloadData === null && validatorData.type.includes("null");
  return isPayloadDataTypeValid || isPayloadDataNull;
};

const validatePayload = (payload, validator) => {
  // Ensure the Schema is corrects
  if (payload.schema !== validator.schema) {
    throw new Error(`Invalid Schema "${payload.schema}"`);
  }

  for (let property of Object.keys(payload.data)) {
    // Verify Payload Attributes are accepted
    if (!(property in validator.properties)) {
      throw new Error(`Property "${property}" is not accepted by "${validator.schema}"`);
    }
    // Verify Payload Types are accepted
    if (!isPayloadDataValid(payload.data[property], validator.properties[property])) {
      throw new Error(
        `Type "${typeof payload.data[property]}" is not accepted for "${property}" by "${
          validator.schema
        }"`
      );
    }
    // Verify Enum if it must be an enum value
    if (
      "enum" in validator.properties[property] &&
      !validator.properties[property].enum.includes(payload.data[property])
    ) {
      throw new Error(
        `Value "${payload.data[property]}" is not in the enumerated list so "${payload.data[property]}" is not accepted by "${validator.schema}"`
      );
    }
  }

  // Verify all Required Payload Properties are set
  if ("required" in validator) {
    for (let requiredProperty of validator.required) {
      if (!(requiredProperty in payload.data)) {
        throw new Error(
          `"${requiredProperty}" is a required property of "${validator.schema}", please include "${requiredProperty}" for the event to fire`
        );
      }
    }
  }
};

const fireEvent = (snowplowDetails, eventArgs) => {
  const logger = getWebLogger("snow-plow.js");
  // This code will only be available on dev environment
  if (process.env.NODE_ENV === "development") {
    //eslint-disable-next-line no-console
    console.log("LOGGER (snowPlow): ", snowplowDetails);
    return;
  }

  if (FEATURE_FLAGS.ENABLE_ANALYTICS && FEATURE_FLAGS.SNOWPLOW_ENABLED) {
    let actionObject = {};
    try {
      let snowplowSchemas = [];
      for (let snowplowDetailKey of Object.keys(snowplowDetails)) {
        validatePayload(
          snowplowDetails[snowplowDetailKey].payload,
          snowplowDetails[snowplowDetailKey].validator
        );
        snowplowSchemas.push(snowplowDetails[snowplowDetailKey].payload);
      }
      if (eventArgs[0] === "trackPageView") {
        window.snowplow(...eventArgs, snowplowSchemas);
      } else if (eventArgs[0] === "trackSelfDescribingEvent") {
        window.snowplow(...eventArgs, snowplowSchemas[0], snowplowSchemas.slice(1));
      }
    } catch (e) {
      //eslint-disable-next-line no-console
      console.warn(e);
      snowplowDetails.exception = e.message || e;
      actionObject.actionType = SNOWPLOW_FAILURE;
      actionObject.message = "unable to send logs to snowplow because of snowplow failure";
    } // if succeeded, not sending duplicated snowplow detail data to overload splunk
  } else {
    logger.info({
      actionType: SNOWPLOW_FAILURE,
      message: "not sending logs as snowplow may be disabled or event data may be missing"
    });
  }
};

const getLinkClick = (event) => {
  const validator = schemas("LINK_CLICK");
  let { id, className, target, href, innerText } = event.target.parentNode;

  if (id === "t2v-button" || id === "tapToVerify") {
    href = new URL(window.location).origin + "/t2v-clicks";
    id = "t2v-button";
  }

  if (event.target.parentNode.type == "button" || event.target.type == "button") {
    id = "button";
    if (
      event.target.parentNode.id == "entry-review-button" ||
      event.target.id == "entry-review-button"
    ) {
      href = new URL(window.location).origin + "/application-review";
    }
    if (href == undefined) {
      href = new URL(window.location).origin;
    }
    target = "undefined";
  }
  let payload = {
    schema: validator.schema,
    data: {}
  };

  payload.data.elementId = id;
  payload.data.elementClasses = className.split(" ");
  payload.data.elementTarget = target;
  payload.data.targetUrl = href;
  payload.data.elementContent = innerText.trim();

  return Object.assign({}, { linkClick: { validator, payload } });
};

const getFieldTracking = (event) => {
  const validator = schemas("FIELD_TRACKING");
  const { id, className, textContent, nextElementSibling, form, type, value } = event.target;
  let payload = {
    schema: validator.schema,
    data: {}
  };

  if (nextElementSibling && nextElementSibling.className.includes("error")) {
    payload.data.validationMessage = nextElementSibling.innerText;
    payload.data.validitionStatus = false;
  } else {
    payload.data.validitionStatus = true;
  }
  if (elementTypes(type) === "textinput") {
    payload.data.inputCharacterLength = value.length;
  }
  payload.data.name = id
    ? `id: ${id}`
    : className
    ? `className: ${className}`
    : `textContent: ${textContent}`;
  payload.data.formName = form.name;

  return Object.assign({}, { fieldTracking: { validator, payload } });
};

const getApplication = (url, product = {}, info = {}) => {
  const validator = schemas("APPLICATION");
  let payload = {
    schema: validator.schema,
    data: {
      applicationId: getGUID(),
      productId: product.id,
      productName: product.brand
    }
  };

  if (info?.uycres && info?.uycacc) {
    payload.data.offerType = btoa(info.uycres);
    payload.data.solicitationReferenceNumber = info.uycacc;
  }

  if (APPLICATION_DECISION_URL.includes(url)) {
    payload.data.applicationReferenceId = info.applicationReferenceId
      ? parseInt(info.applicationReferenceId, 10)
      : null;
    payload.data.applicationStatus = info.applicationStatus ? info.applicationStatus : null;
  }

  let applicationSchemaPayload = {
    application: { validator, payload }
  };

  return Object.assign({}, applicationSchemaPayload);
};

const getUiInteraction = (event, precedenceVariables, appContextProps) => {
  const validator = schemas("UI_INTERACTION");
  let { id, className, textContent, innerText, type } = event.target;
  const { elementType, elementName, value } = precedenceVariables;
  let payload = {
    schema: validator.schema,
    data: { action: event.type }
  };

  if (event.target.parentNode.type == "button") {
    id = event.target.parentNode.id;
    className = event.target.parentNode.className;
    textContent = event.target.parentNode.textContent;
    innerText = event.target.parentNode.innerText;
    type = event.target.parentNode.type;
  }

  payload.data.elementType = elementType ? elementType : elementTypes(type);
  payload.data.elementName = elementName
    ? elementName
    : id
    ? `id: ${id}`
    : className
    ? `className: ${className}`
    : `textContent: ${textContent}`;

  payload.data.value = value ? value : innerText.trim();

  const currentParsedUrl = getCurrentUrlPathName();
  const product = appContextProps.product ? appContextProps.product : {};
  const decisionInfo = appContextProps.decision ? appContextProps.decision : {};
  const applicationContext = getApplication(currentParsedUrl, product, decisionInfo);
  // when there is no campaign active, keep abTest empty to prevent it becomes undefined
  //const abTesting = applicationContext.abTesting ? { abTesting: applicationContext.abTesting } : {};
  return Object.assign(
    {},
    { uiInteraction: { validator, payload } },
    { application: applicationContext.application }
    //abTesting
  );
};

export const inputSnowplowImplementation = (event, appContextProps) => {
  const elementType = event.target.type;
  const precedenceVariables = {};

  if (elementType === "checkbox") {
    precedenceVariables.value = event.target.checked.toString();
  } else if (elementType === "radiobutton" || elementType === "radio") {
    precedenceVariables.value = event.target.value.toString();
  } else if (elementType === "select-one") {
    precedenceVariables.value = event.target.selectedOptions[0].innerText.trim();
    precedenceVariables.elementType = elementTypes("dropdown");
  } else {
    precedenceVariables.value = event.target.value.length.toString();
  }
  if (FEATURE_FLAGS.ONETAG_ENABLED) {
    trackInputUserInteraction(event, precedenceVariables, appContextProps);
  }
  //trackFieldTracking(event);
  fireEvent(
    {
      ...getUiInteraction(event, precedenceVariables, appContextProps),
      ...getFieldTracking(event)
    },
    [selfDescribingEvents]
  );
};

export const clickSnowplowImplementation = (event, appContextProps, customElementType = null) => {
  // custumElement Type provides all components that start with div tag such as drawers or modals, etc to
  // specify their types. The customElementType is defined at the parent div of the components' onClick events
  const {
    parentNode: { nodeName: elementType }
  } = event.target;

  const elementRole = event.target.hasAttribute("role")
    ? event.target.getAttribute("role")
    : event.target.tagName;
  const parentRole = event.target.parentNode.hasAttribute("role")
    ? event.target.parentNode.getAttribute("role")
    : event.target.parentNode.tagName;

  if (customElementType) {
    if (FEATURE_FLAGS.ONETAG_ENABLED) {
      trackUserInteraction(event, elementTypes(customElementType), appContextProps);
    }
    fireEvent(
      {
        ...getUiInteraction(
          event,
          { elementType: elementTypes(customElementType) },
          appContextProps
        )
      },
      [selfDescribingEvents]
    );
  } else if (
    elementType.toLowerCase() === "button" ||
    (elementRole && elementRole.toLowerCase() === "button") ||
    (parentRole && parentRole.toLowerCase() === "button")
  ) {
    if (FEATURE_FLAGS.ONETAG_ENABLED) {
      trackUserInteraction(event, elementTypes("button"), appContextProps);
    }
    fireEvent(
      {
        ...getUiInteraction(event, { elementType: elementTypes("button") }, appContextProps),
        ...getLinkClick(event)
      },
      [selfDescribingEvents]
    );
  } else if (elementType.toLowerCase() === "drawer") {
    if (FEATURE_FLAGS.ONETAG_ENABLED) {
      trackUserInteraction(event, elementTypes("drawer"), appContextProps);
    }
    fireEvent(
      { ...getUiInteraction(event, { elementType: elementTypes("drawer") }, appContextProps) },
      [selfDescribingEvents]
    );
  } else {
    if (FEATURE_FLAGS.ONETAG_ENABLEDD) {
      trackUserInteraction(event, elementTypes("link"), appContextProps);
    }
    fireEvent(
      {
        ...getUiInteraction(event, { elementType: elementTypes("link") }, appContextProps),
        ...getLinkClick(event)
      },
      [selfDescribingEvents]
    );
  }
};

export const mouseoverSnowplowImplementation = (event, appContextProps) => {
  const { parentElement, id, className, textContent } = event.target.parentElement;

  const elementName = parentElement.attributes["aria-describedby"]?.value
    ? `aria-describedby: ${parentElement.attributes["aria-describedby"].value}`
    : id
    ? `id: ${id}`
    : className
    ? `className: ${className}`
    : `textContent: ${textContent}`;
  if (FEATURE_FLAGS.ONETAG_ENABLED) {
    trackmouseoverUserInteraction(event, elementName, appContextProps);
  }
  fireEvent(
    {
      ...getUiInteraction(
        event,
        { elementType: elementTypes("tooltiplink"), elementName },
        appContextProps
      )
    },
    [selfDescribingEvents]
  );
};

// The implementation of the Application schema should be rethought?? (Application Timeout)
// It should have additional information and might not make sense as an extension
//  of trackPageView
export default function snowPlow(pageType, url, product = {}, info = {}) {
  // Track a Pageview with the Application context
  const eventType = pageType;
  const eventName = getEventName(pageType, url, info);
  const logger = getWebLogger("snow-plow.js");

  if (eventName && eventType) {
    if (FEATURE_FLAGS.ONETAG_ENABLED) {
      trackUserPageView(product, eventName, eventType, url, info);
    }
    fireEvent({ ...getApplication(url, product, info) }, [
      trackingEvents("TRACK_PAGE_VIEW"),
      eventType + ":" + eventName
    ]);
  } else {
    //eslint-disable-next-line no-console
    logger.info({
      actionType: SNOWPLOW_FAILURE,
      message: "not sending logs as snowplow may be disabled or event data may be missing"
    });
  }
}
