import PropTypes from "prop-types";
import React, { useContext } from "react";
import { intlShape, FormattedMessage } from "react-intl";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import { ApplicationTerms, PrivacyTerms } from "../alerts";
import EntrySectionHeader from "../entry-form/entry-section-header";
import { LANGUAGES } from "../../state/constants/product";
import { getFrenchApplication } from "../../lib/product-utils";
import FrenchConsent from "./french-consent";

const ApplicationTermsConditions = ({
  title,
  intl,
  productType,
  productId,
  showCta,
  productDisplayContent
}) => {
  const { toggleModal } = useContext(SprayCanContext);
  const displayFrenchConsent = intl.locale === LANGUAGES.ENGLISH;
  const rewardCard = productType === "upmarket" || productType === "midmarket";
  const frenchApplication = displayFrenchConsent
    ? getFrenchApplication(productId, productDisplayContent)
    : "";

  const openModal = (type, e, convertFrench) => {
    e.preventDefault();

    switch (type) {
      case "app-terms": {
        const convertedTitle = convertFrench
          ? "application-terms.alert.heading.french"
          : "application-terms.alert.heading";
        toggleModal(null, ApplicationTerms, { title: convertedTitle, intl, convertFrench });
        return;
      }
      case "privacy": {
        toggleModal(null, PrivacyTerms, {
          title: "privacy-terms.alert.heading",
          intl,
          convertFrench
        });
        return;
      }
    }
  };

  const applicationTermsAlert = displayFrenchConsent ? (
    <div className="billc96">
      <FormattedMessage id="application-terms-conditions.app.terms.content" />
      &nbsp;
      <a
        data-cy="application-terms-link-fr"
        aria-label={intl.formatMessage({
          id: "application-terms-conditions.app.terms.content.text.french"
        })}
        href={intl.formatMessage({
          id: "application-terms.alert.paragraph.linkUrl1.french"
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        (French
      </a>
      &nbsp;<p style={{ color: "#0074D9" }}>|</p>&nbsp;
      <a
        data-cy="application-terms-link-en"
        aria-label={intl.formatMessage({
          id: "application-terms-conditions.app.terms.content.text.english"
        })}
        href={intl.formatMessage({
          id: "application-terms.alert.paragraph.linkUrl1"
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        English)
      </a>
    </div>
  ) : (
    <a
      data-cy="application-terms-alert-modal-text-fr"
      onClick={(e) => openModal("app-terms", e, false)}
    >
      <FormattedMessage id="application-terms-conditions.app.terms.content" />
    </a>
  );

  const privacyTermsAlert = displayFrenchConsent ? (
    <div className="billc96">
      <FormattedMessage id="application-terms-conditions.privacy.terms.content" />
      &nbsp;
      <a
        data-cy="privacy-terms-link-fr"
        aria-label={intl.formatMessage({
          id: "application-terms-conditions.privacy.terms.content.text.french"
        })}
        href={intl.formatMessage({
          id: "privacy-terms.alert.paragraph.linkUrl1.french"
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>(French</span>
      </a>
      &nbsp;<p style={{ color: "#0074D9" }}>|</p>&nbsp;
      <a
        data-cy="privacy-terms-link-en"
        aria-label={intl.formatMessage({
          id: "application-terms-conditions.privacy.terms.content.text.english"
        })}
        href={intl.formatMessage({
          id: "privacy-terms.alert.paragraph.linkUrl1"
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>English)</span>
      </a>
    </div>
  ) : (
    <a data-cy="privacy-terms-alert-modal-text" onClick={(e) => openModal("privacy", e, false)}>
      <FormattedMessage id="application-terms-conditions.privacy.terms.content" />
    </a>
  );

  const appTermsAndConditions = (
    <div className="app-tncs-content">
      <FormattedMessage id="application-terms-conditions.intro" tagName="p" />
      <h3>
        <FormattedMessage id="application-terms-conditions.app.terms.heading" tagName="strong" />
      </h3>
      <ul>
        <li>{applicationTermsAlert}</li>
      </ul>
      <h3>
        <FormattedMessage
          id="application-terms-conditions.privacy.terms.heading"
          tagName="strong"
        />
      </h3>
      <ul>
        <li>{privacyTermsAlert}</li>
      </ul>
      <h3>
        <FormattedMessage id="application-terms-conditions.benefits.heading" tagName="strong" />
      </h3>
      <ul>
        <li>
          <FormattedMessage id="application-terms-conditions.benefits.list1.content" />
        </li>
        <li>
          <strong>
            <FormattedMessage id="application-terms-conditions.benefits.list2.content.bold" />
          </strong>
          <FormattedMessage id="application-terms-conditions.benefits.list2.content" />{" "}
          <a
            data-cy="application-terms-conditions.benefits.linkUrl"
            href={intl.formatMessage({
              id: "application-terms-conditions.benefits.linkUrl"
            })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="application-terms-conditions.benefits.linkText" />
          </a>
        </li>
      </ul>

      {displayFrenchConsent && (
        <FrenchConsent intl={intl} rewardCard={rewardCard} frenchApplication={frenchApplication} />
      )}

      <h3>
        <FormattedMessage
          id="application-terms-conditions.bureau.consent.heading"
          tagName="strong"
        />
      </h3>
      <p className="bureau-consent-content">
        <strong>
          <FormattedMessage id="application-terms-conditions.bureau.consent.content.bold" />
        </strong>
        <FormattedMessage
          id="application-terms-conditions.bureau.consent.content.regular"
          values={{
            linkText: (
              <a
                data-cy="application-terms-conditions.bureau.consent.content.regular.linkUrl"
                href={intl.formatMessage({
                  id: "application-terms-conditions.bureau.consent.content.regular.linkUrl"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="common.privacy-policy" />
              </a>
            )
          }}
        />
      </p>
    </div>
  );

  const phoneTermsAndConditions = (
    <div className="app-tncs-content">
      <p className="bureau-consent-content">
        <FormattedMessage id="application-terms-cta-paragraph2.content" tagName="strong" />
      </p>
    </div>
  );
  return (
    <div id="app-terms-and-conditions" className="row column">
      <EntrySectionHeader title={title} intl={intl} />
      {showCta ? phoneTermsAndConditions : appTermsAndConditions}
    </div>
  );
};

ApplicationTermsConditions.propTypes = {
  intl: intlShape.isRequired,
  productType: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showCta: PropTypes.bool.isRequired,
  productDisplayContent: PropTypes.array.isRequired
};

export default ApplicationTermsConditions;
