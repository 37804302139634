import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const PrivacyTerms = (props) => {
  return (
    <div id="privacy-terms-alert" className="callout">
      <section className="content">
        <FormattedMessage
          id="privacy-terms.alert.paragraph.content"
          values={{
            linkText1: (
              <a
                href={props.intl.formatMessage({ id: "privacy-terms.alert.paragraph.linkUrl1" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-terms.alert.paragraph.linkText1" />
              </a>
            ),
            linkText2: (
              <a
                href={props.intl.formatMessage({ id: "privacy-terms.alert.paragraph.linkUrl1" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="privacy-terms.alert.paragraph.linkText2" />
              </a>
            )
          }}
        />
        <ul data-testid="privacyList">
          <li>
            <FormattedMessage id="privacy-terms.alert.paragraph.list1.content" />
          </li>
          <li>
            <FormattedMessage
              id="privacy-terms.alert.paragraph.list2.content"
              values={{
                linkText1: (
                  <a
                    href={props.intl.formatMessage({
                      id: "privacy-terms.alert.paragraph.list2.linkUrl1"
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="privacy-terms.alert.paragraph.list2.linkText1" />
                  </a>
                ),
                linkText2: (
                  <a
                    href={props.intl.formatMessage({
                      id: "privacy-terms.alert.paragraph.list2.linkUrl2"
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="privacy-terms.alert.paragraph.list2.linkText2" />
                  </a>
                ),
                linkText3: (
                  <a
                    href={props.intl.formatMessage({
                      id: "privacy-terms.alert.paragraph.list2.linkUrl3"
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="privacy-terms.alert.paragraph.list2.linkText3" />
                  </a>
                )
              }}
            />
          </li>
          <li>
            <FormattedMessage
              id="privacy-terms.alert.paragraph.list3.content"
              values={{
                linkText1: (
                  <a
                    href={props.intl.formatMessage({
                      id: "privacy-terms.alert.paragraph.list3.linkUrl1"
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="privacy-terms.alert.paragraph.list3.linkText1" />
                  </a>
                ),
                linkText2: (
                  <a
                    href={props.intl.formatMessage({
                      id: "privacy-terms.alert.paragraph.list3.linkUrl2"
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="privacy-terms.alert.paragraph.list3.linkText2" />
                  </a>
                )
              }}
            />
          </li>
        </ul>
      </section>
    </div>
  );
};

PrivacyTerms.propTypes = {
  intl: intlShape
};

export default PrivacyTerms;
