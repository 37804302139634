import PropTypes from "prop-types";
import React from "react";
import { intlShape } from "react-intl";
import { LANGUAGES } from "../../state/constants/product";
import { ENGLISH_SECURED_VIDEO, FRENCH_SECURED_VIDEO } from "../../state/constants/decision";
import { CHANNEL_PHONE } from "../../state/constants/channel";
import SecuredApprovedHeader from "./secured-approved-video-header";
import SecuredApprovedSendFunds from "./secured-approved-video-send-funds";
import SecuredApprovedVideoSection from "./secured-approved-video-section";
import SecuredApprovedNextSteps from "./secured-approved-video-next-steps";

const SecuredApprovedVideoF2F = ({ intl, decision, productInfo, firstName, lastName }) => {
  const videoSource =
    productInfo.locale === LANGUAGES.ENGLISH_CANADA ? ENGLISH_SECURED_VIDEO : FRENCH_SECURED_VIDEO;
  let minimumSecurityDepositAmount = decision.minimumSecurityDepositAmount;
  if (Intl) {
    minimumSecurityDepositAmount = new Intl.NumberFormat(productInfo.locale).format(
      minimumSecurityDepositAmount
    );
  }

  return (
    <div>
      <SecuredApprovedHeader
        firstName={firstName}
        lastName={lastName}
        intl={intl}
        productInfo={productInfo}
        decision={decision}
        minimumSecurityDepositAmount={minimumSecurityDepositAmount}
        solicitationReferenceNumber={decision.solicitationReferenceNumber}
        faceToFaceRequired
      />
      <SecuredApprovedSendFunds
        productInfo={productInfo}
        firstName={firstName}
        lastName={lastName}
        minimumSecurityDepositAmount={minimumSecurityDepositAmount}
        solicitationReferenceNumber={decision.solicitationReferenceNumber}
        intl={intl}
        isCTA={productInfo.channel === CHANNEL_PHONE}
      />
      <SecuredApprovedVideoSection videoSource={videoSource} />
      <SecuredApprovedNextSteps
        minimumSecurityDepositAmount={minimumSecurityDepositAmount}
        solicitationReferenceNumber={decision.solicitationReferenceNumber}
        faceToFaceRequired
        intl={intl}
      />
    </div>
  );
};

SecuredApprovedVideoF2F.propTypes = {
  intl: intlShape.isRequired,
  decision: PropTypes.object,
  productInfo: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

export default SecuredApprovedVideoF2F;
