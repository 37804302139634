import PropTypes from "prop-types";
import React, { useState, createRef, useContext } from "react";
import { FormattedMessage, intlShape } from "react-intl";
import SelectField from "@cof/graffiti-alley-spray-cans/atoms/SelectField";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import * as validation from "../../lib/validation";
import { BANKING_LIST } from "../../state/constants/list-options";
import { ThirdPartyTerms } from "../alerts";
import { cardImageBrands } from "../../lib/product-utils";
import CardArt from "@cof/graffiti-alley-spray-cans/molecules/CardArt";

const QuickFund = ({ intl, solicitationReferenceNumber, productInfo, firstName, lastName }) => {
  const { toggleModal } = useContext(SprayCanContext);
  const [bankSelected, updateSelectedBankInfo] = useState({ selection: "", url: "" });
  const bankSelectionList = createRef();

  const bankingList = Object.keys(BANKING_LIST).map((id) => ({
    id,
    content: BANKING_LIST[id]
  }));

  const thirdPartyTermsAlert = (
    <a
      id="third-party-terms-alert-link"
      onClick={(e) => {
        toggleModal(e, ThirdPartyTerms, { intl });
      }}
    >
      <FormattedMessage id="quick-fund.navigate.info.linkText" />
      <span className="icon-resize icon-uniELaunch" aria-hidden="true" />
    </a>
  );

  const submitEntry = () => {
    if (bankSelected.selection) {
      window.open(bankSelected.url, "_blank");
    } else {
      bankSelectionList.current.focus();
      bankSelectionList.current.blur();
    }
  };

  const handleChange = (event) => {
    updateSelectedBankInfo({
      selection: event.target.value,
      url: intl.formatMessage({ id: `${BANKING_LIST[event.target.value]}.url` })
    });
  };

  return (
    <div data-cy="quick-fund" className="row align-center" id="quick-fund">
      <div className="column small-12 medium-8 quick-fund-wrapper">
        <div className="row quick-fund-spacing">
          <div className="column small-12 medium-5">
            <CardArt
              brand={cardImageBrands(productInfo.brand)}
              firstName={firstName}
              lastName={lastName}
              classImageStyle=""
              showLegalDisclaimer={true}
            />
          </div>
          <div className="column small-12 medium-7">
            <h2>
              <FormattedMessage id="quick-fund.header.title" />
            </h2>
            <FormattedMessage id="quick-fund.header.content" tagName="p" />
          </div>
        </div>
        <div className="row quick-fund-border">
          <div className="column align-center">
            <FormattedMessage id="quick-fund-reference-number" />
            <br />
            <p id="solicitation-ref" className="currency-value securing-content-emphasis">
              {solicitationReferenceNumber}
            </p>
          </div>
          <div className="row column small-12 align-center">
            <form name="quick-fund-form">
              <div className="row align-center quick-fund-spacing">
                <SelectField
                  flagAsRequired={true}
                  id="quick-fund-bank-selection"
                  value={bankSelected.selection}
                  validation={validation.financialBanking}
                  intl={intl}
                  placeholder="placeholder.quick-fund"
                  options={bankingList}
                  onChange={(event) => handleChange(event)}
                  columns="small-12 columns"
                  reference={bankSelectionList}
                />
                <button
                  id="quick-fund-go-to-bank"
                  type="button"
                  className="button column small-10 medium-5"
                  onClick={submitEntry}
                >
                  <FormattedMessage id="quick-fund.navigate.button" />
                </button>
              </div>
            </form>
            <div className="column small-12">
              <FormattedMessage id="quick-fund.navigate.info" />
              {thirdPartyTermsAlert}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuickFund.propTypes = {
  intl: intlShape.isRequired,
  solicitationReferenceNumber: PropTypes.string,
  productInfo: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

export default QuickFund;
