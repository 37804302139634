import PropTypes from "prop-types";
import React, { Component } from "react";
import { intlShape, FormattedMessage } from "react-intl";

import EntrySectionHeader from "./entry-section-header";
import { pageTracker, getProductDetails } from "../../lib/logger";
import { isMobile } from "../../lib/dom-utils";
import getWebLogger from "../../lib/logger/webLogger";

class EntryDisclosure extends Component {
  static propTypes = {
    data: PropTypes.object,
    product: PropTypes.object,
    intl: intlShape.isRequired,
    disclosuresClass: PropTypes.string
  };

  componentDidMount() {
    const destDocument = this.iFrame.contentDocument;
    const node = document.createElement("div");
    node.innerText = this.props.data.disclosures;
    if (destDocument) {
      destDocument.write(node.innerText);
      destDocument.close();
    }
  }

  printDisclosures = (event) => {
    const logger = getWebLogger("entry-disclosure.js");
    event.preventDefault();
    let ua = window.navigator.userAgent;
    let isOlderIE = ua.indexOf("MSIE ") !== -1;
    let isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);
    let isChromeMobile =
      !isMobile() &&
      typeof window.safari === "undefined" &&
      ua.indexOf("Macintosh") !== -1 &&
      ua.indexOf("Chrome") === -1 &&
      ua.indexOf("Firefox") === -1;
    let isFirefoxMobile = isMobile() && ua.indexOf("FxiOS") !== -1;

    if (this.iFrame) {
      pageTracker("PrintDisclosures", getProductDetails(this.props.product), "popup");
      logger.info({ url: "PrintDisclosures", product: this.props.product, pageType: "popup" });

      if (isChromeMobile) {
        let content = this.iFrame.contentWindow.document.body; // getting content from iframe
        let winRef = window.open(null, "disclosure"); // opening a new window to print
        let newWinDoc = winRef.document; // get the new window document object
        //getting style from iframe
        let styleText =
          this.iFrame.contentWindow.document.head.getElementsByTagName("style")[0].innerHTML;
        // a[plying style to new window body
        newWinDoc.body.innerHTML = "<style>" + styleText + "</style>" + content.innerHTML;
        winRef.windowToPrint = winRef; // sending indow reference to the new window
        winRef.onfocus = function () {
          // overriding onfocus to print the document
          this.windowToPrint.print();
          this.windowToPrint.close();
        };
      } else if (isIE11 || isOlderIE) {
        this.iFrame.contentWindow.document.execCommand("print", false, null);
      } else if (isFirefoxMobile) {
        this.iFrame.contentWindow.print();
      } else {
        this.iFrame.contentWindow.print();
      }
    }
  };

  render() {
    const { intl } = this.props;

    return (
      <section className="disable-border row column" id="entry-disclosures">
        <EntrySectionHeader
          intl={intl}
          id="entry-disclosures-header"
          title="entry-section.title.disclosures"
        />

        <p>
          <FormattedMessage id="entry-section.sub-title.disclosures-before-submit" />
        </p>
        <p className="text-center">
          <a href="" onClick={this.printDisclosures}>
            <i className="oi-print" />
            <FormattedMessage id="print-disclosures.button" />
          </a>
        </p>

        <div id="disclosures-wrapper" className={this.props.disclosuresClass}>
          <iframe
            id="product-disclosures"
            title={intl.formatMessage({ id: "common.important-disclosures" })}
            ref={(ref) => (this.iFrame = ref)}
          />
        </div>
      </section>
    );
  }
}

export default EntryDisclosure;
