import "fast-text-encoding";
import { createId } from "@paralleldrive/cuid2";
import Cookies from "js-cookie";
import config from "../../config";
import { TITLE_TOGGLE_EN, TITLE_TOGGLE_FR, LANGUAGES } from "../state/constants/product";

// INFO: unique applicant identifier to track their actions
//       whenever the user restarts the application (entry form or gmc pages)
//       this guid will be regenerated
let guid;

// INFO: like the guid this is a unique identifier to track actions, but unlike the guid
//       this value will never reset for the duration of the application, only modifying
//       when the application reloads
// INFO: this would be better implemented as a constant, but since the guid cannot
//       be implemented as constant and follows the same pattern and almost the same
//       use cases it was decided that they would be implemented on the same file
let sessionID;
let sessionConfig;

export function getSessionConfig() {
  if (!sessionConfig) {
    sessionConfig = config();
  }
  return sessionConfig;
}

// retrive the sessionID, generating one if it doens't exist yet, after that
// always use the cached value for the duration of the SPA lifecycle.
export function getSessionId() {
  if (!sessionID) {
    sessionID = createId();
  }

  return sessionID;
}

// If there is no GUID passed in, retrive the guid, generating one if it doesn't exist yet
export function getGUID(reuseGUID) {
  if (!reuseGUID || "null" === reuseGUID || "undefined" === reuseGUID) {
    guid = guid ?? createId();
  } else {
    guid = reuseGUID;
  }

  return guid;
}

// update the guid with a new value, updating the cached one
export function refreshGUID() {
  guid = createId();
  return guid;
}

const sanitizeLang = (lang) => {
  if (lang.includes("fr")) return "fr";
  else if (lang.includes("en")) return "en";
};

// attempt to infer the prefered language to render the application
export const getLocale = (global, productInfo, defaultLocale) => {
  const qsLangMatch = global.location.search ? global.location.search.match(/lang=([^&]*)/) : null;
  const qsLang = qsLangMatch && qsLangMatch.length >= 2 ? qsLangMatch[1] : null;
  let locale;
  // check the card-info first
  if (productInfo && productInfo.locale) {
    locale = sanitizeLang(productInfo.locale);
  }

  // search on the global object
  else if (global.locale) {
    locale = sanitizeLang(global.locale);
  } else if (global.lang) {
    locale = sanitizeLang(global.lang);
  }

  // search on the querystring
  else if (qsLang) {
    locale = sanitizeLang(qsLang);
  }

  // read from cookies
  else if (Cookies.get("lang")) {
    locale = sanitizeLang(Cookies.get("lang"));
  } else if (Cookies.get("locale")) {
    locale = sanitizeLang(Cookies.get("locale"));
  }

  // use the browser knowledge of the user language
  //   this method returns the browser language not the user prefered language,
  //   but in most cases is still a good guess
  else if (global.navigator.userLanguage) {
    locale = sanitizeLang(global.navigator.userLanguage);
  } else if (global.navigator.language) {
    locale = sanitizeLang(global.navigator.language);
  }

  if (locale) {
    return locale;
  } else {
    return defaultLocale;
  }
};

export const UpdateTabTitle = (product) => {
  const locale = getLocale(window, product, "en-CA");
  const titleText = String(LANGUAGES.ENGLISH).includes(locale) ? TITLE_TOGGLE_EN : TITLE_TOGGLE_FR;
  document.title = titleText;
};
