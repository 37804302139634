import compose from "@cof/graffiti-alley-spray-cans/utils/validation/compose";

// used to cache validation composition per module
let bankingInfoValidation;

export default function financialBanking(value) {
  // cache validation composition
  if (!bankingInfoValidation) {
    bankingInfoValidation = compose().rule(
      "required",
      "quick-fund-bank-account.validation.required"
    );
  }

  return bankingInfoValidation.validate(value);
}
