import React, { useContext, useEffect, useCallback } from "react";
import { intlShape, injectIntl } from "react-intl";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import WalkAwayModalTimer from "@cof/graffiti-alley-spray-cans/modal-bodies/WalkAwayModalTimer";
import EntryHeader from "../components/entry-form/entry-header";
import { APP_RESET, CONSTANT } from "../state/constants/action-types";
import { redirectTo, addTaggingInfo, setDomLocale, removeParamsFromQs } from "../lib/dom-utils";
import { CHANNEL_PHONE } from "../state/constants/channel";
import { LANGUAGES } from "../state/constants/product";
import { pageLoadLogger } from "../lib/logger";
import { refreshGUID } from "../lib/session";
import { getMessages } from "../lib/api/locale";
import { AppContext } from "../state/app-context";
import EntryForm from "../components/entry-pages/entry-form";
import { changeCalypsoContext } from "../lib/CalypsoUtil";
import useLogger from "../hooks/useLogger";

const EntryPage = ({ intl }) => {
  const { appDispatch, app } = useContext(AppContext);
  const logger = useLogger("entry-page.js");
  const { data, validateAndSubmit, dispatch } = useContext(SprayCanContext);

  const { product } = app;

  const { productDisplayContent, channel, snippets } = app.product;

  const switchLanguage = useCallback(
    async (locale) => {
      let res;
      res = await getMessages(locale);

      if (!res.error) {
        const previousLocale = locale === "en" ? "fr" : "en";

        setDomLocale(previousLocale, locale);
        dispatch({
          type: CONSTANT.dispatchTypeFieldUpdate,
          fieldName: "languageSelected",
          fieldValue: locale
        });
      }
    },
    [dispatch]
  );

  const validatePrefillData = useCallback(() => {
    const findPrefillFieldsToSkipValidation = () => {
      return Object.keys(data)
        .filter(
          (key) =>
            (key.includes("financial") && !key.includes("financialMortgageRent")) ||
            key.includes("personal") ||
            key.includes("employment") ||
            key.includes("contact")
        )
        .filter((key) => data[key] === null || data[key] === undefined || data[key] === "");
    };
    validateAndSubmit(null, () => {}, null, null, null, findPrefillFieldsToSkipValidation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeCalypsoContext("CA_ApplicationForm");
    logger.info({ message: "In the Entry page" });
    if (app.stage && app.stage >= 2) {
      logger.info({
        logFilter: "CANCA-20923",
        message: "Refreshing correlationId in entry page",
        appStage: app.stage
      });
      refreshGUID();
      logger.info({
        logFilter: "CANCA-20923",
        message: "Refreshed correlationId in entry page",
        appStage: app.stage
      });
      appDispatch({
        type: APP_RESET
      });
      dispatch({ type: "resetToInitialState" });
    }

    if (data.prefill && app.stage < 2) {
      validatePrefillData();
    }
    const productLocale =
      product.locale === LANGUAGES.FRENCH_CANADA ? LANGUAGES.FRENCH : LANGUAGES.ENGLISH;
    //If the locale is different from the product locale, we need to switch language
    if (intl.locale !== productLocale) {
      switchLanguage(productLocale);
    }
    pageLoadLogger(product, null, app.prefillKey);
    addTaggingInfo(product);
  }, [
    dispatch,
    intl.locale,
    switchLanguage,
    product,
    appDispatch,
    app.stage,
    app.prefillKey,
    data.prefill,
    validatePrefillData
  ]);

  removeParamsFromQs(["corid"]);

  const isPrefill = data.prefill && app.stage < 2;
  const isExternalAffiliate = data.prefill && data.isExternalAffiliate;

  const showCta = channel === CHANNEL_PHONE;

  /**
   * @todo remove conditional rendering of snippets once all snippets come back from icatalyst
   */
  const productSnippets =
    productDisplayContent.cardSummary.length && productDisplayContent.ui.length
      ? productDisplayContent
      : snippets;
  const onTimeout = () => {
    redirectTo("/session-timeout", { message: "session timeout" });
  };

  return (
    <div id="product-form">
      <WalkAwayModalTimer
        appTimeout={10 * 60 * 1000}
        onModalTimeout={onTimeout}
        onAppExit={onTimeout}
      />
      <EntryHeader intl={intl} snippets={productSnippets} />
      <EntryForm
        intl={intl}
        showCta={showCta}
        isPrefill={isPrefill}
        isExternalAffiliate={isExternalAffiliate}
      />
    </div>
  );
};

// component properties
EntryPage.propTypes = {
  intl: intlShape.isRequired
};

export { EntryPage as EntryPageRaw };

const EntryPageHOC = injectIntl(EntryPage);
EntryPageHOC.displayName = "EntryPage";
export default EntryPageHOC;
