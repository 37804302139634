import requestGet from "./base/request-get";
import { BASE_URL } from "./base/settings";
import { addressValidation, nameValidation } from "./../string-utils";
import { VALID_BORROWELL_PARTNER_CODE } from "../affiliate-constants";
import getWebLogger from "../logger/webLogger";
import config from "../../../config";

const convertApplicantInfo = (creditOffersResponse, prefillOrch = false) => {
  if (typeof creditOffersResponse !== "object" || Object.keys(creditOffersResponse).length === 0)
    throw new Error("Empty applicant");
  const convertedResponse = {};
  if (creditOffersResponse.address) {
    if (!prefillOrch && creditOffersResponse.address.countryCode !== "CAN")
      throw new Error("Non-Canadian address");
    convertedResponse.contactHomeAddress = addressValidation(
      creditOffersResponse.address.addressLine1
    );
    convertedResponse.contactStreet = addressValidation(creditOffersResponse.address.addressLine1);
    convertedResponse.contactApartment = creditOffersResponse.address.addressLine2;
    convertedResponse.contactCity = addressValidation(creditOffersResponse.address.city);
    convertedResponse.contactProvince = creditOffersResponse.address.stateCode;
    convertedResponse.contactPostalCode = creditOffersResponse.address.postalCode;
  }
  convertedResponse.personalFirstName = nameValidation(creditOffersResponse.firstName);
  convertedResponse.personalLastName = nameValidation(creditOffersResponse.lastName);
  convertedResponse.personalDob = creditOffersResponse.dateOfBirth;
  if (prefillOrch && creditOffersResponse.phoneNumber) {
    convertedResponse.contactPhoneNumber = creditOffersResponse.phoneNumber.telephoneNumber;
  } else {
    convertedResponse.contactPhoneNumber = creditOffersResponse.telephoneNumber;
  }
  convertedResponse.contactEmail = creditOffersResponse.emailAddress;
  convertedResponse.prefill = true;
  convertedResponse.isExternalAffiliate = true;
  return convertedResponse;
};

export const getApplicantInfo = async (prefillKey) => {
  const logger = getWebLogger("credit-offers.js");
  try {
    logger.info({
      actionType: "GET_APPLICANT",
      prefillKey: prefillKey,
      message: "Calling Credit Offers Get Applicant endpoint"
    });
    const creditOffersResponse = await requestGet(
      `${BASE_URL}/prefill?applicantId=${prefillKey}`,
      {},
      "Credit Offers Get Applicant"
    );

    return convertApplicantInfo(creditOffersResponse);
  } catch (e) {
    logger.info({
      actionType: "UNSUCCESSFUL_GET_APPLICANT",
      prefillKey: prefillKey,
      message: "Unsuccessfully called the Credit Offers Get Applicant endpoint."
    });
    return {};
  }
};

export const getApplicantInfoFromOrch = async (partnerCode, applicantReferenceId) => {
  const logger = getWebLogger("credit-offers.js");
  try {
    logger.info({
      actionType: "GET_APPLICANT_ORCH",
      message: "Calling Prefill Orchestrator Service endpoint",
      partnerCode: partnerCode,
      applicantReferenceId: applicantReferenceId
    });
    const options = {
      isCustomHeaders: true,
      fetchOptions: {
        customHeaders: { "Applicant-Referrer-Id": partnerCode }
      }
    };
    const { prefillOrchestratorEndpoint } = config();

    const prefillData = await requestGet(
      `${prefillOrchestratorEndpoint}${applicantReferenceId}`,
      {},
      "Prefill Orchestrator Get Applicant",
      options
    );

    if (partnerCode === VALID_BORROWELL_PARTNER_CODE) {
      return convertApplicantInfo(prefillData, true);
    }
    return prefillData;
  } catch (e) {
    logger.info({
      actionType: "UNSUCCESSFUL_GET_APPLICANT_ORCH",
      message: "Unsuccessfully called the prefill orchestrator Get Applicant endpoint.",
      applicantReferenceId: applicantReferenceId,
      errorDescription: e
    });
    return {};
  }
};
