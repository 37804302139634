import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const CENTISECOND = 100;
const MILLISECOND = 1000;

/*
 * This component counts down from a initial time in seconds, on ticks of equal interval
 *      any animations are performed by css and should be synchronized with this compoennt
 *      see the _timer.scss for more details
 */
class Timer extends Component {
  static propTypes = {
    time: PropTypes.number, // initial time to be used by the countdown
    interval: PropTypes.number // tick interval (see below for more info)
  }

  /*
   * time - is always given in milliseconds (no fractions)
   * interval properties should be given on a fraction of one second.
   *     millisecond 1000 - timer counts down seconds with no decimal
   *     centisecond 100 - timer counts down seconds with one decimal
   *     decisecond 10 - timer counts down seconds with two decimals
   *
   */
  static defaultProps = {
    time: 30000, // default to 30 seconds
    interval: 100 // defaults to one centisecond (see above)
  }

  constructor(props) {
    super(props);

    const time = (props.time / MILLISECOND) * (MILLISECOND / props.interval);
    this.state = {
      time,
      timeLeftInt: this.formatTimeInt(time),
      timeLeftDec: this.formatTimeDec(time),
      timerId: null
    };
  }

  componentDidMount() {
    // start timer
    this.setState(Object.assign({}, this.state, {
      timerId: window.setInterval(this.tick, this.props.interval)
    }));
  }

  componentWillUnmount() {
    // clean after
    window.clearInterval(this.state.timerId);
  }

  formatTimeInt = (time) => {
    const interval = this.props.interval;
    const floatTime = time / (MILLISECOND / interval);
    const int = Math.floor(floatTime);

    return (int < 10 ? '0' + int : '' + int);
  }

  /*formatTimeDec(2999, 100, 1000, 10);*/
  formatTimeDec = (time) => {
    const interval = this.props.interval;
    const floatTime = time / (MILLISECOND / interval); // 29.99
    const dec = (floatTime % 1);
    const fixed = ('' + (CENTISECOND / interval)).length;

    return (interval > CENTISECOND ? '' : '.' + (dec.toFixed(fixed).slice(2)));
  }

  tick = () => {
    const left = this.state.time - 1;
    let timerId = this.state.timerId;

    if (left <= 0) {
      window.clearInterval(this.state.timerId);
      timerId = null;
    }

    this.setState(Object.assign({}, this.state, {
      time: left,
      timeLeftInt: this.formatTimeInt(left),
      timeLeftDec: this.formatTimeDec(left),
      timerId: timerId
    }));
  }

  render() {
    return (
      <div className="countdown">
        <div className="time-left">
          <div>
            <span className="time-left-int">{this.state.timeLeftInt}</span>
            <span className="time-left-dec">{this.state.timeLeftDec}</span>
          </div>
          <span className="time-left-sign"><FormattedMessage id="common.seconds" /></span>
        </div>
        <div className="l-half" />
        <div className="r-half" />
      </div>
    );
  }
}

export default Timer;
