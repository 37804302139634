import PropTypes from "prop-types";
import React from "react";

const Button = (props) => {
  let url = props.url;

  const handleSubmit = () => {
    window.open(url, "_blank");
  };
  return (
    <div className="row">
      <div className="small-12 columns">
        <button type="button" className="button" onClick={handleSubmit} id={props.id}>
          {props.buttonTitle}
        </button>
      </div>
    </div>
  );
};

Button.propTypes = {
  url: PropTypes.string,
  id: PropTypes.string,
  buttonTitle: PropTypes.string
};

export default Button;
