export const BANKING_LIST = {
  "bank-of-montreal": "quick-fund-bank-of-montreal",
  "royal-bank": "quick-fund-royal-bank",
  "td-bank": "quick-fund-td-bank",
  "scotia-bank": "quick-fund-scotia-bank",
  cibc: "quick-fund-cibc",
  desjardins: "quick-fund-desjardins",
  laurentian: "quick-fund-laurentian",
  "national-bank-canada": "quick-fund-national-bank-canada",
  tangerine: "quick-fund-tangerine"
};

export const CURRENCY_SIGN = {
  en: "$",
  fr: "$"
};
