import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";
import { getSnippetTextHTML, sanitizeFirstName } from "../../lib/dom-utils";
import ApprovedUserEnrolment from "./approved-enrolment-section";
import ApprovedTapToVerify from "./approved-tap-to-verify-section";
import CardArt from "@cof/graffiti-alley-spray-cans/molecules/CardArt";
import HeroBanner from "@cof/graffiti-alley-spray-cans/molecules/HeroBanner";

import { CARD_NAME_SNIPPET } from "../../state/constants/product";
import { cardImageBrands } from "../../lib/product-utils";

const Approved = ({ intl, decision, productInfo, firstName, lastName }) => {
  const credentialEnrolmentLocation = decision.credentialEnrolmentLocation;
  const tapToVerifyEnrolmentLocation = decision.tapToVerifyEnrolmentLocation;

  const DecisionFaq = (
    <p data-cy="decision-faq" className="faq-link">
      <FormattedMessage
        id="decision.questions"
        values={{
          linkFaq: (
            <a
              href={intl.formatMessage({ id: "faq.external.url" })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="decision.link.faq" />
            </a>
          )
        }}
      />
    </p>
  );

  return (
    <div className="approved-redesign">
      <HeroBanner backgroundContentClass="confetti-banner">
        <div className="confetti-banner-clip" data-testid="cardart-container">
          <CardArt
            data-testid="approved-page-card-art"
            brand={cardImageBrands(productInfo.brand)}
            firstName={firstName}
            lastName={lastName}
            classImageStyle=""
            showLegalDisclaimer={false}
          />
        </div>
      </HeroBanner>
      <div data-cy="decision-page" className="column small-8 text-center decision-page">
        <h1 className="redesign">
          <FormattedMessage
            id="decision.approve.title"
            values={{
              firstName: <span dangerouslySetInnerHTML={sanitizeFirstName(firstName)} />
            }}
          />
        </h1>
        <p className="medium-bold">
          <FormattedMessage
            id="decision.approve.message"
            values={{
              productName: (
                <span
                  dangerouslySetInnerHTML={getSnippetTextHTML(
                    productInfo.snippets,
                    CARD_NAME_SNIPPET
                  )}
                />
              )
            }}
          />
        </p>
        <p className="medium-bold">
          <FormattedMessage id="decision.approve.legal-disclaimer" />
        </p>
        {FEATURE_FLAGS.ENABLE_TAP_TO_VERIFY && tapToVerifyEnrolmentLocation ? (
          <div>
            <ApprovedTapToVerify location={tapToVerifyEnrolmentLocation} />
            <div className="t2v-faq">{DecisionFaq}</div>
          </div>
        ) : (
          credentialEnrolmentLocation && (
            <div>
              <ApprovedUserEnrolment decision={decision} />
              {DecisionFaq}
            </div>
          )
        )}
      </div>
    </div>
  );
};

Approved.propTypes = {
  intl: intlShape.isRequired,
  decision: PropTypes.object,
  productInfo: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

export default Approved;
