import React, { useEffect, useState, useContext } from "react";
import { intlShape, injectIntl, FormattedMessage } from "react-intl";
import { pageLoadLogger } from "../lib/logger";
import { APP_SET_STAGE, RESET_QUICK_CHECK_FLAG } from "../state/constants/action-types";
import { AppContext } from "../state/app-context";
import { changeCalypsoContext } from "../lib/CalypsoUtil";
import useLogger from "../hooks/useLogger";

const SessionTimeout = ({ intl }) => {
  const setQuickCheck = useState(false)[1];
  const { app, appDispatch } = useContext(AppContext);
  const logger = useLogger("session-timeout.js");

  useEffect(() => {
    changeCalypsoContext("CA_SessionTimeout");
    logger.info({ message: "In Session Timeout page" });
    pageLoadLogger(app.product, null, app.prefillKey);
    appDispatch({
      type: APP_SET_STAGE,
      stage: 10
    });
    setQuickCheck(app.brands.quickCheck);
    appDispatch({
      type: RESET_QUICK_CHECK_FLAG
    });
  }, [app.brands.quickCheck, app.prefillKey, app.product, appDispatch]);

  return (
    <div className="row column text-center error-page">
      <i className="oi-clock outline header-icon" />
      <h2>
        <FormattedMessage id="session-timeout.title" />
      </h2>
      <p>
        <FormattedMessage id="session-timeout.text" />
        <br />
        <a href={intl.formatMessage({ id: "quick-check.url" })}>
          <FormattedMessage id="session-timeout.next-step" />
        </a>
      </p>
    </div>
  );
};

SessionTimeout.propTypes = {
  intl: intlShape.isRequired
};

export { SessionTimeout as SessionTimeoutRaw };
const SessionTimeoutHOC = injectIntl(SessionTimeout);
SessionTimeoutHOC.displayName = "SessionTimeout";
export default SessionTimeoutHOC;
