import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const SoftDeclined = ({ intl }) => {
  return (
    <div data-cy="decision-page" className="column small-10 text-center decision-page">
      <i className="oi-success header-icon" />
      <h1>
        <FormattedMessage id="decision.soft-decline.title" />
      </h1>
      <p>
        <FormattedMessage id="decision.soft-decline.message" />
      </p>
      <p data-cy="decision-faq">
        <FormattedMessage
          id="decision.questions"
          values={{
            linkFaq: (
              <a
                href={intl.formatMessage({ id: "faq.external.url" })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="decision.link.faq" />
              </a>
            )
          }}
        />
        <br />
        <FormattedMessage
          id="decision.text.thank-you"
          values={{
            registered: (
              <sup>
                <FormattedMessage id="common.registered" />
              </sup>
            )
          }}
        />
      </p>
    </div>
  );
};

SoftDeclined.propTypes = {
  intl: intlShape.isRequired
};

export default SoftDeclined;
