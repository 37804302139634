import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { intlShape, FormattedMessage, injectIntl } from "react-intl";
import { pageLoadLogger } from "../../lib/logger";
import { AppContext } from "../../state/app-context";
import { changeCalypsoContext } from "../../lib/CalypsoUtil";
import useLogger from "../../hooks/useLogger";

const UnAuthPageAccess = ({ intl, search }) => {
  const { product } = useContext(AppContext).app;
  const logger = useLogger("unauth-page-access.js");
  const isUpgradeYourCard = search?.includes("uyc=t");

  useEffect(() => {
    changeCalypsoContext("CA_UnauthPage");
    pageLoadLogger(product);
    logger.info({ message: "In Unauthorized Access page" });
  }, [product]);

  return (
    <div data-testid="UnauthAccess" className="row column text-center error-page">
      <i className="oi-caution outline header-icon" />
      <h1 className="h1-as-h2">
        <FormattedMessage id="unauth-access.title" />
      </h1>
      <p data-testid="UnauthAccessStep">
        <FormattedMessage
          id="unauth-access.step"
          values={{
            linkGYCPage: (
              <a
                href={intl.formatMessage({
                  id: `${isUpgradeYourCard ? "validation-error.link.url" : "get-your-card.url"}`
                })}
              >
                <FormattedMessage
                  id={
                    isUpgradeYourCard
                      ? "unauth-access.link.uyc-redirect"
                      : "unauth-access.link.gyc-redirect"
                  }
                />
              </a>
            )
          }}
        />
        <br />
        <FormattedMessage
          id="unauth-access.next-step"
          values={{
            linkProductPage: (
              <a href={intl.formatMessage({ id: "compare-credit-cards.external.url" })}>
                <FormattedMessage id="unauth-access.link.product-redirect" />
              </a>
            )
          }}
        />
      </p>
    </div>
  );
};

UnAuthPageAccess.propTypes = {
  intl: intlShape,
  search: PropTypes.string
};

export { UnAuthPageAccess as UnAuthPageAccessRaw };
const UnAuthPageAccessHOC = injectIntl(UnAuthPageAccess);

UnAuthPageAccessHOC.displayName = "UnAuthPageAccess";
export default UnAuthPageAccessHOC;
