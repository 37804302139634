// Application Intial State
export default {
  // application information
  app: {
    // app global status (controls the app overlay)
    // this should be an object as follow:
    // {
    //   value: [string]      - [required] 'free' or 'busy'
    //   component: [string]  - [required when state is 'busy'] component name imported from 'components/status'
    //   customkey1: [mix]    - [optional] custom property proxied to component
    //   customkey2: [mix]    - [optional] custom property proxied to component
    //   custom....           - [optional] any custom property will always be proxied
    // }
    started: false,
    // stage of the application process:
    //     0 - application entry stage
    //     1 - consent given when the user finishes the filling up the application
    //     2 - entry editing completed (no more edits allowed)
    //     3 - verification check is done (oow)
    //     4 - identity verification confirmed (questions sent)
    //         stage 4 is currently not used since we have a strange implementation
    //         of identity verification also submitting the form, thus jumping from 3 to 5
    //         keeping it here in case we decide to refactore this behaviour in the future
    //     5 - decision was made for this application
    stage: null,

    // Informs whether an application submission API call has been performed
    hasAppBeenSubmitted: false,

    // payload encrypted and salted on the first api cal (identity check) to make
    //     sure the user hasn't tempered with the data in between calls
    checksum: null,

    // id received by identity check to match the verification id
    //     and used on possible identity verification call and application submition call
    verificationId: null,

    // session received by identity check to match the same user
    //     and used on possible identity verification call
    verificationSession: null,

    // verification decision based on identity check request, might be: questions, pass, fail, ...
    verificationDecision: null,

    // questions returned by identity check, when necessary to verify the user
    verificationQuestions: [],

    // application decision
    decision: null,

    // GMC
    ctaAgentId: "",

    // Where it comes from (application origin : get your card or enter form page)
    origin: null,
    clientID: "DynamicAppCA",
    // the key that represents applicant data from cache
    prefillKey: null,
    // The Key is received from quick check redirect and used to exclude users from Skinny DAPP test
    qkRef: null,
    isUpgradeCardFlow: false,
    brands: {
      quickCheck: false,
      quickCheckView: "secured",
      upmarketBrands: [],
      securedBrands: [],
      brandByHash: {},
      customerName: "",
      brandList: []
    },
    product: {
      solId: null,
      testCellId: null,
      id: null,
      brand: null,
      type: null,
      solicitedIndicator: null,
      locale: null,
      active: null,
      channel: null,
      campaign: null,
      snippets: {
        cardSummary: [],
        ui: []
      },
      productDisplayContent: {
        cardSummary: [],
        ui: []
      },
      disclosures: null
    }
  }
};
