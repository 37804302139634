import React from "react";
import { intlShape, FormattedMessage } from "react-intl";
import WebFooter from "@cof/graffiti-alley-spray-cans/organisms/WebFooter";
import LogoLink from "@cof/graffiti-alley-spray-cans/molecules/LogoLink/LogoLink";
import { MenuItem } from "@cof/graffiti-alley-spray-cans/molecules/Menu";
import nortonLogo from "../assets/images/norton-logo.png";

const NortonLink = ({ intl }) => (
  <MenuItem className="text-center certificate">
    <div data-testid="NortonLink" className="norton-logo-wrapper">
      <a
        href={intl.formatMessage({ id: "norton.external.url" })}
        id="norton-certificate"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          id="norton-logo"
          src={nortonLogo}
          alt={intl.formatMessage({ id: "common.norton-ssl" })}
        />
      </a>
      <a
        href="https://www.verisign.com/en_US/website-presence/online/ssl-certificates/index.xhtml?loc=en_US"
        id="norton-ssl"
        target="_blank"
        rel="noopener noreferrer"
        className="about-ssl"
      >
        <FormattedMessage id="footer.link.about-ssl" />
      </a>
    </div>
  </MenuItem>
);

NortonLink.propTypes = {
  intl: intlShape.isRequired
};

const AppFooter = ({ intl }) => {
  return (
    <div id="app-footer">
      <WebFooter.Wrapper showNavBar={true}>
        <>
          <LogoLink className={"homepage-link"} />
          <WebFooter.Navbar>
            <NortonLink intl={intl} />
          </WebFooter.Navbar>
          <WebFooter.DefaultText showDefaultText={false} />
        </>
      </WebFooter.Wrapper>
    </div>
  );
};

AppFooter.propTypes = {
  intl: intlShape.isRequired
};

export default AppFooter;
