import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/** This is a component that forces the scroll to the top for every route change.
 * Taken from the documentation:
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
const HashLinkScroll = ({ children, location: { pathname } }) => {
    const { hash } = window.location;
    useEffect(() => {
        if (hash !== "") {
            // Push onto callback queue so it runs after the DOM is updated,
            // this is required when navigating from a different page so that
            // the element is rendered on the page before trying to getElementById.
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return children || null;
};

export default withRouter(HashLinkScroll);
