import {
  ENTER_FORM_URL,
  APPLICATION_REVIEW_URL,
  IDENTITY_VERIFICATION_URL,
  APPLICATION_DECISION_URL,
  INVALID_OFFER_URL,
  UNAUTH_PAGE_ACCESS_URL,
  TECHNICAL_DIFFICULTY_URL,
  SESSION_TIMEOUT_URL,
  GET_YOUR_CARD_URL,
  ENTRY_CTA_URL,
  LOCKOUT_ERROR_URL,
  QUICK_CHECK_HOME_URL,
  UPGRADE_YOUR_CARD_URL,
  VALIDATION_ERROR_URL
} from "./routes-constants";

// INFO: used for page tracking logs
//       when including a new route, also include the taxonomy here
//
//       empty string => no page tracking or development error will be logged
//       null => taxonomy will be informed somewhere else
//       undefined => no page tracking, but a development error will show on console
export const routesTaxonomyMap = (url) => {
  const map = {};
  map[ENTER_FORM_URL] = "enter application information";
  map[APPLICATION_REVIEW_URL] = "review & verify app info";
  map[IDENTITY_VERIFICATION_URL] = "identity security";
  map[APPLICATION_DECISION_URL] = null;
  map[INVALID_OFFER_URL] = "invalid offer";
  map[TECHNICAL_DIFFICULTY_URL] = "technical difficulty";
  map[VALIDATION_ERROR_URL] = "UYC validation error page";
  map[UNAUTH_PAGE_ACCESS_URL] = "unauthorized page access";
  map[SESSION_TIMEOUT_URL] = "session timeout";
  map[GET_YOUR_CARD_URL] = "GMC Landing Page";
  map[LOCKOUT_ERROR_URL] = "lockout error";
  map[UPGRADE_YOUR_CARD_URL] = "UYC Landing Page";
  map[ENTRY_CTA_URL] = "Agents Landing Page";
  map[QUICK_CHECK_HOME_URL] = "Quick Check form page";
  return map[url];
};
