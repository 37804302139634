export const ENTER_FORM_URL = "/";
export const APPLICATION_REVIEW_URL = "/application-review";
export const IDENTITY_VERIFICATION_URL = "/identity-verification";
export const APPLICATION_DECISION_URL = [
  "/application-decision",
  "/secured-card-approval",
  "/capitalone-card-approval"
];
export const INVALID_OFFER_URL = "/invalid-offer";
export const UNAUTH_PAGE_ACCESS_URL = "/unauth-page-access";
export const UNAUTH_PAGE_ACCESS_WITHOUT_QK_URL = "/unauth-page-access-without-qk";
export const TECHNICAL_DIFFICULTY_URL = "/technical-difficulty";
export const VALIDATION_ERROR_URL = "/validation-error";
export const SESSION_TIMEOUT_URL = "/session-timeout";
export const GET_YOUR_CARD_URL = "/get-your-card";
export const UPGRADE_YOUR_CARD_URL = "/upgrade-your-card";
export const FIND_YOUR_CARD_URL = "/find-your-card";
export const LOCKOUT_ERROR_URL = "/lockout-error";
export const ENTRY_CTA_URL = "/agents";
export const QUICK_CHECK_HOME_URL = "https://findyourcard.capitalone.ca/";

export const QS_APP_ORIGIN = "ao";
export const APP_ORIGIN_GYC = "1";
export const APP_ORIGIN_UYC = "2";
export const QS_CHANNEL = "channel";
export const QS_APP_EXPERIMENT_NO_DO = "nodo";

/*
In src/state/initial-state.js, there is documentation showing that stages 0-5
are already defined. If more stages are added, this constant should be adjusted.
*/
export const HIGHEST_DEFINED_STAGE_NUM = 5;

export const PATHS = {
  pathsWithSnackBar: [GET_YOUR_CARD_URL, ENTER_FORM_URL, APPLICATION_REVIEW_URL]
};
