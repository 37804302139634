import React from "react";
import { intlShape, FormattedMessage } from "react-intl";
import Accordion from "@cof/graffiti-alley-spray-cans/molecules/Accordion";
import AccordionSection from "@cof/graffiti-alley-spray-cans/molecules/Accordion/AccordionSection";
import PrivacyContent from "../privacy/privacy-content";
import OtherInformation from "../privacy/other-information";

const EntryPrivacy = ({ intl }) => {
  return (
    <Accordion id="entry-privacy">
      <AccordionSection
        title={
          <div data-cy="privacy-accordion">
            <span aria-hidden="true" className="oi-secure-check" />{" "}
            <FormattedMessage id="entry-section.title.privacy.statement" />
          </div>
        }
      >
        <PrivacyContent id="privacy-content" intl={intl} />
      </AccordionSection>

      <AccordionSection
        title={
          <div data-cy="other-info-accordion">
            <span aria-hidden="true" className="oi-info" />{" "}
            <FormattedMessage id="entry-section.title.other.important.information" />
          </div>
        }
      >
        <OtherInformation id="other-information" intl={intl} />
      </AccordionSection>
    </Accordion>
  );
};

EntryPrivacy.propTypes = {
  intl: intlShape.isRequired
};

export default EntryPrivacy;
