import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, IntlProvider, intlShape } from "react-intl";
import { LANGUAGES } from "../../state/constants/product";
const messages = require("../../../locale/fr.json");

const ApplicationTerms = (props) => {
  if (props.convertFrench) {
    props.intl.locale = LANGUAGES.FRENCH;
    props.intl.messages = messages;
  }
  return (
    <div id="app-terms-alert" className="callout">
      <IntlProvider locale={props.intl.locale} messages={props.intl.messages}>
        <section className="content">
          <FormattedMessage id="application-terms.alert.paragraph.content" />
          <ul>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list1.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list2.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list3.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list4.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list5.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list6.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list7.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list8.content" />
            </li>
            <li>
              <FormattedMessage id="application-terms.alert.paragraph.list9.content" />
            </li>
          </ul>
        </section>
      </IntlProvider>
    </div>
  );
};

ApplicationTerms.propTypes = {
  intl: intlShape,
  convertFrench: PropTypes.bool
};

export default ApplicationTerms;
