import Cookies from "js-cookie";
import * as DOMPurify from "dompurify";
import history from "../routes/history";
import { getGUID } from "./session";

import queryString from "query-string";
import {
  GET_YOUR_CARD_URL,
  APP_ORIGIN_GYC,
  QS_CHANNEL,
  ENTER_FORM_URL,
  TECHNICAL_DIFFICULTY_URL,
  APPLICATION_DECISION_URL,
  UNAUTH_PAGE_ACCESS_WITHOUT_QK_URL,
  UPGRADE_YOUR_CARD_URL,
  FIND_YOUR_CARD_URL,
  ENTRY_CTA_URL,
  APP_ORIGIN_UYC
} from "../routes/routes-constants";
import { CHANNEL_PHONE } from "../state/constants/channel";
import { LANGUAGES } from "../state/constants/product";
import { getProductInfo, isCTA } from "../lib/server-info";
import { isMobile as isMobileOrTablet } from "react-device-detect";
import {
  MANDATORY_AFFILIATE_PARAMS,
  VALID_AFFILIATE_CHANNEL,
  VALID_BORROWELL_PARTNER_CODE,
  QUICK_CHECK_QUERY_PARAM_KEY
} from "./affiliate-constants";
import getWebLogger from "./logger/webLogger";

export const addQS = (key, value) => {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.pushState(null, "", url.toString());
};

export const getQS = (queryParam) => {
  const qs = queryString.parse(window.location.search);
  return qs ? qs[queryParam] : null;
};

export const getChannelFromQS = () => {
  const qs = queryString.parse(window.location.search);
  if (qs) {
    const channel = qs.channel;
    if (channel) {
      return `${channel.substr(0, 1).toUpperCase()}${channel.substr(1)}`;
    }
  }
  return null;
};

export const getReservationNumberAndAccessCodeFromQS = () => {
  const qs = queryString.parse(window.location.search);
  let reservationNumber = "";
  let accessCode = "";
  if (qs) {
    reservationNumber = qs.pc1 || "";
    accessCode = qs.pc2 || "";
  }
  return { reservationNumber, accessCode };
};
/**
 *
 * Utility method to sanitize the url to be redirected by removing unneeded query paramters
 * based on currentUrl
 * @param {String} redirectToUrl
 * @param {String} qs
 */
export const sanitizeRedirectUrlQs = (redirectToUrl, qs) => {
  const urlsToSanitize = [ENTER_FORM_URL, TECHNICAL_DIFFICULTY_URL, ...APPLICATION_DECISION_URL];
  // WHATWG URL API format
  if (urlsToSanitize.includes(redirectToUrl)) {
    const searchParams = new URLSearchParams(qs);
    searchParams.delete("appDigest");
    searchParams.delete("govtIdcorid");
    return `?${searchParams.toString()}`;
  }
  return qs;
};

export const isOriginGYC = () => {
  const qs = queryString.parse(window.location.search);
  const urlpath = window.location.pathname;
  return urlpath === GET_YOUR_CARD_URL || qs.ao === APP_ORIGIN_GYC;
};

export const isOriginUYC = () => {
  const qs = queryString.parse(window.location.search);
  const urlpath = window.location.pathname;
  return urlpath === UPGRADE_YOUR_CARD_URL || qs.ao === APP_ORIGIN_UYC;
};

export const isMobile = () => {
  return isMobileOrTablet;
};

export const isOriginQKErrorPage = () => {
  const urlPath = window.location.pathname;
  return urlPath === UNAUTH_PAGE_ACCESS_WITHOUT_QK_URL;
};

export const isOriginCTA = () => {
  return window.location.pathname.includes(ENTRY_CTA_URL);
};

export const getOrigin = () => {
  const currentPath = window.location.pathname;

  if (isOriginUYC()) {
    return UPGRADE_YOUR_CARD_URL;
  }

  if (isOriginGYC()) {
    return GET_YOUR_CARD_URL;
  }

  if (isOriginQKErrorPage()) {
    return UNAUTH_PAGE_ACCESS_WITHOUT_QK_URL;
  }

  if (isOriginCTA()) {
    return ENTRY_CTA_URL;
  }

  if (currentPath === FIND_YOUR_CARD_URL) return FIND_YOUR_CARD_URL;
};

// helper method to replace the current url, logging information if informed
export const replaceWith = (url, err, appendQS = true) => {
  const logger = getWebLogger("dom-utils.js");
  if (err) {
    logger.info(
      Object.assign({}, err, {
        url,
        actionType: "HISTORY_REPLACE"
      })
    );
  }

  const qs = appendQS ? window.location.search || "" : "";
  const redirectUrl = `${url}${sanitizeRedirectUrlQs(url, qs)}`;
  history.replace(redirectUrl);
};

// helper method to redirect to a new url, logging information if informed
export const redirectTo = (url, err, appendQS = true) => {
  const logger = getWebLogger("dom-utils.js");
  if (err) {
    logger.info(
      Object.assign({}, err, {
        url,
        actionType: "HISTORY_PUSH"
      })
    );
  }
  if (window.location) {
    const urlPath = window.location.pathname;
    const qs = appendQS ? window.location.search || "" : "";
    const redirectUrl = `${url}${sanitizeRedirectUrlQs(url, qs)}`;

    if (getOrigin() === urlPath) {
      history.push(redirectUrl);
    } else {
      history.replace(redirectUrl);
    }
  }
};

export const getSnippetTextHTML = (snippets, type) => {
  const found = snippets.ui.find((uiSnippet) => uiSnippet.type === type);
  if (!found) {
    return { __html: "" };
  }
  return { __html: DOMPurify.sanitize(found.text) };
};

export const sanitizeFirstName = (firstName) => {
  if (!firstName) {
    return { __html: "" };
  }
  return { __html: DOMPurify.sanitize(firstName) };
};

export const setDomLocale = (previousLocale, currentLocale) => {
  document.body.classList.remove(`lang-${previousLocale}`);
  document.body.classList.add(`lang-${currentLocale}`);
  document.documentElement.setAttribute("lang", currentLocale);
};

export const isCTAorCTAQs = () => {
  return isCTA(getProductInfo()) || getChannelFromQS() === CHANNEL_PHONE;
};

export const getCTAQs = () => {
  const channel = getChannelFromQS();
  return channel ? `${QS_CHANNEL}=${channel}` : "";
};

// Function to verify CTA GYC flow
export const isPathGYC = (path) => {
  return path === GET_YOUR_CARD_URL;
};

/** Use the env variable sent from apptier to determine with environment the app is running in.
 *  We rely on this because once the application is built through the pipeline, it is packaged as production.
 **/
export const getAppEnvirnoment = () => {
  return FEATURE_FLAGS.ENV;
};

// Function to return path with channel if it is CTA
export const buildOriginPath = (path) => {
  const channelQs = getCTAQs();
  if ((isPathGYC(path) || isOriginGYC()) && channelQs) {
    return `${path}?${channelQs.toLowerCase()}`;
  }
  return path;
};

export const getCookies = (cookie) => {
  return cookie ? Cookies.getJSON(cookie) : Cookies.getJSON();
};

/**
 * Utility method to retrieve path name in url.format compliant format
 */
export const getCurrentUrlPathName = () => {
  const pathParts = window.location.pathname.split("/");
  const path = `/${pathParts[pathParts.length - 1]}`;
  return path;
};

/**
 * Utility method to retrieve query params in url.format compliant format
 */
export const getAllQs = () => {
  const params = {};
  const url = new URL(window.location.href);
  const keys = url.searchParams.keys();

  for (const key of keys) {
    params[key] = url.searchParams.get(key);
  }

  return params;
};

/**
 * Utility method to retrieve query params, clean up the correlation ID for the FYC flow where we want
 * to generate a new correlation ID when users refresh and add product Id
 */
export const buildQsAfterQuickCheckRedirect = (product) => {
  let queryString = new URLSearchParams(getAllQs());
  queryString.delete("corid");
  queryString.append("productId", product);

  return queryString.toString();
};

/**
 * Utility method to redirect to an external url outside the app.
 * This method is required because window.location methods cannot be stubbed
 * @param {String} redirectUrl
 */
const assignExternalUrl = (redirectUrl) => {
  window.location.assign(redirectUrl);
};

/**
 * Redirects to external domain url
 * @param {Object} urlObject url.format() compliant Object
 * @param {Boolean} appendQS To append existing Qs from browser or not
 */
export const redirectToExternalUrl = (urlObject, appendQS = true) => {
  const { protocol, hostname, pathname, query } = urlObject;
  const logger = getWebLogger("dom-utils.js");
  const redirectUrl = new URL(`${protocol}://${hostname}${pathname}`);
  let allQueryParams = {};
  allQueryParams = appendQS ? Object.assign(query, getAllQs()) : query;
  for (const [name, value] of Object.entries(allQueryParams)) {
    redirectUrl.searchParams.append(name, value);
  }
  logger.info(
    Object.assign(
      {},
      {
        redirectUrl: redirectUrl.href,
        actionType: "EXTERNAL_REDIRECT"
      }
    )
  );
  assignExternalUrl(redirectUrl.href);
};

/**
 * Returns a new Object with all non-empty properties from one given Object merged
 * in to the other, overwriting those with the same key
 * @param {Object} target properties to be merged
 * @param {Object} source non-empty properties to be merged to target
 */
export const mergeObjects = (target, source) => {
  const filteredSource = {};
  const stateProperties = Object.getOwnPropertyNames(source);
  stateProperties.forEach((property) => {
    if (source[property] !== "") {
      filteredSource[property] = source[property];
    }
  });
  return { ...target, ...filteredSource };
};

/**
 * Returns Boolean based on whether the application is invoked using webdriver.
 */

export const isWebDriver = () => {
  return navigator.webdriver ? true : false;
};

export const isOriginQuickCheck = () => {
  return window.location.pathname.includes(FIND_YOUR_CARD_URL);
};

export const isOriginEntryForm = () => {
  return window.location.pathname === ENTER_FORM_URL;
};

export const getProductId = () => {
  const productIdFormat = /^\d{4,10}$/; // Minimum 4 digits maximum 10
  const productId = getQS("productId") || window.PRODUCT;
  if (productId && productId.match(productIdFormat)) {
    return productId;
  }
};

export const hasTitle = () => !!document.title;
export const setTitle = (title) => {
  document.title = title;
};

export const addTaggingInfo = (product, decision = null) => {
  //Changes to GTM_TAGGING_INFO is driven by business and marketing teams.
  window.GTM_TAGGING_INFO = {
    brand: product.brand,
    channel: product.channel,
    productId: product.id,
    productType: product.type,
    languageCode: product.locale,
    solicitationId: product.solId,
    guid: getGUID(),
    appnId: decision && decision.applicationReferenceId ? decision.applicationReferenceId : null,
    appStatus: decision && decision.applicationStatus ? decision.applicationStatus : null,
    testCellId: product.testCellId
  };
};

export const isAffiliateParamsPresent = () => {
  const logger = getWebLogger("dom-utils.js");
  if (!FEATURE_FLAGS.ENABLE_AFFILIATE_ENTRYPOINT) return false;
  const queryParams = queryString.parse(window.location.search);
  if (!queryParams.partnerCode) return false; //affiliate flow is started when partnerCode is present

  if (queryParams.partnerCode !== VALID_BORROWELL_PARTNER_CODE) {
    logger.info({
      actionType: "INVALID_PARTNER_CODE",
      message: "Invalid partner code supplied."
    });
    return false;
  }

  // checking for missing params
  let isParamValid = true;
  let containsPrefillKey = !!queryParams["prefillKey"];
  MANDATORY_AFFILIATE_PARAMS.forEach((mandatoryParam) => {
    isParamValid = isParamValid && !!queryParams[mandatoryParam];
  });

  logger.info({
    actionType: `${isParamValid ? "CORRECT" : "INSUFFICIENT"}_AFFILIATE_PARAMS`,
    message: `${
      isParamValid ? "Correct" : "Insufficient"
    } parameters supplied from affiliate. Prefill key ${containsPrefillKey ? "" : "not "}supplied`
  });

  return isParamValid;
};

export const validatePrefillQS = (mandatoryParamList) => {
  const logger = getWebLogger("dom-utils.js");
  const queryParams = queryString.parse(window.location.search);
  let isParamValid = true;
  let containsPrefillKey = !!queryParams["prefillKey"];
  mandatoryParamList.forEach((mandatoryParam) => {
    isParamValid = isParamValid && !!queryParams[mandatoryParam];
  });

  logger.info({
    actionType: `${isParamValid ? "CORRECT" : "INSUFFICIENT"}_PREFILL_PARAMS`,
    message: `${isParamValid ? "Correct" : "Insufficient"} parameters supplied. Prefill key ${
      containsPrefillKey ? "" : "not "
    }supplied`
  });

  return isParamValid;
};

export const isValidPrefillQS = () => {
  const logger = getWebLogger("dom-utils.js");
  const partnerCode = getQS("partnerCode");
  if (!partnerCode) return false;

  if (partnerCode === VALID_BORROWELL_PARTNER_CODE) {
    return (
      FEATURE_FLAGS.ENABLE_AFFILIATE_ENTRYPOINT && validatePrefillQS(MANDATORY_AFFILIATE_PARAMS)
    );
  } else {
    logger.info({
      actionType: "INVALID_PARTNER_CODE",
      message: "Invalid partner code supplied."
    });
    return false;
  }
};

export const getAffiliateProductParams = () => {
  const locale = getQS("locale");
  return {
    brandCode: getQS("brandCode"),
    channelCode: `${VALID_AFFILIATE_CHANNEL}${locale == LANGUAGES.FRENCH_CANADA ? "_FR" : ""}`,
    locale: locale
  };
};

export const removeAffiliateParamsFromQs = () => {
  return removeParamsFromQs(["prefillKey", "partnerCode"]);
};

export const removeParamsFromQs = (parameterNames) => {
  let queryString = new URLSearchParams(getAllQs());

  parameterNames.forEach((parameterName) => {
    if (queryString.has(parameterName)) {
      queryString.delete(parameterName);
    }
  });
  let newQs = queryString.toString();
  window.history.replaceState({}, document.title, `/?${newQs}`);
  return newQs;
};

// get Correlation Id if passed in

export function getCorrelationIdFromRedirect() {
  const logger = getWebLogger("dom-utils.js");
  const rtpmCorrelationIdQS = getQS("corid");
  const rtpmCorrelationId = getCookies("rtpm-info") || {};
  logger.info({
    logFilter: "CANCA-20923",
    rtpmCorrelationIdQS: rtpmCorrelationIdQS,
    rtpmCorrelationId: rtpmCorrelationId
  });
  return getGUID(rtpmCorrelationIdQS || rtpmCorrelationId.correlationId);
}

// get Quick Check Reference Parameter from Query String
export function getQuickCheckReferenceParameterFromRedirect() {
  return getQS(QUICK_CHECK_QUERY_PARAM_KEY);
}
