import PropTypes from "prop-types";
import React, { Fragment, useCallback, useContext, useEffect } from "react";
import { intlShape, FormattedMessage } from "react-intl";

import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";

import EmploymentSection from "@cof/graffiti-alley-spray-cans/organisms/EmploymentSection";
import FinancialSection from "@cof/graffiti-alley-spray-cans/organisms/FinancialSection";

import EntryDisclosure from "../entry-form/entry-disclosure";
import EntryPrivacy from "../entry-form/entry-privacy";
import PersonalSection from "@cof/graffiti-alley-spray-cans/organisms/PersonalSection";
import ContactSection from "@cof/graffiti-alley-spray-cans/organisms/ContactSection";
import AuthorizedUserSection from "@cof/graffiti-alley-spray-cans/organisms/AuthorizedUserSection";
import EntryConsentToReview from "../entry-form/entry-consent-to-review";
import PrefillWarningEntry from "../alerts/prefill-warning-entry";
import { AppContext } from "../../state/app-context";
import { BRAND_MAP, LANGUAGES } from "../../state/constants/product";
import { APP_SET_STAGE, CONSTANT } from "../../state/constants/action-types";
import { addQS, redirectTo } from "../../lib/dom-utils";
import { QUICK_CHECK_QUERY_PARAM_KEY, NS_TEST_SIN_PREFILL } from "../../lib/affiliate-constants";
import { getFrenchApplication } from "../../lib/product-utils";
import EntrySectionHeader from "../entry-form/entry-section-header";
import CtaAgentId from "@cof/graffiti-alley-spray-cans/molecules/CtaAgentId";
import { clickSnowplowImplementation } from "../../lib/logger/snow-plow";
import { emailVerification } from "../../lib/api/email-verification";
import { getGUID } from "../../lib/session";
import NSTracker from "../../lib/ns-tracker";
import config from "../../../config";
import { NaturalSelectionSoloMode } from "@cof/natural-selection";
const { NS, Control, Variation } = NaturalSelectionSoloMode;

const EntryForm = ({ intl, showCta, isPrefill, isExternalAffiliate }) => {
  const { app, appDispatch } = useContext(AppContext);
  const {
    validateAndSubmit,
    dispatch,
    data: { qkPrefilledEmail: prefilledEmail, prefillToken: prefillToken }
  } = useContext(SprayCanContext);
  const { product, qkRef } = app;
  const { disclosures, brand, productDisplayContent } = product;
  const { ui } = productDisplayContent;

  const productTypeInfo = product.type && product.type === "upmarket" ? "standard" : "secured";
  const isQkPrefill = isPrefill && !isExternalAffiliate;

  useEffect(() => {
    if (qkRef) {
      addQS(QUICK_CHECK_QUERY_PARAM_KEY, qkRef);
    }
  }, []);

  const {
    sinPrefillNsConfig: { control: controlWeight, sinPrefill: sinPrefillWeight }
  } = config();

  const {
    paperlessNsConfig: { control, optIn }
  } = config();

  const submitEntry = (e) => {
    const event = e;
    if (event) {
      clickSnowplowImplementation(event, app);
    }
    validateAndSubmit(
      e,
      () => {
        appDispatch({
          type: APP_SET_STAGE,
          stage: 1
        });
        redirectTo("/application-review");
      },
      []
    );
  };

  const cardImageBrands = () => {
    for (let key of Object.keys(BRAND_MAP)) {
      if (key === brand) {
        return BRAND_MAP[key];
      }
    }
  };

  const isEnglish = product.locale === LANGUAGES.ENGLISH_CANADA;

  const frechApplicationLink = isEnglish && (
    <div className="billC96FrenchApplication">
      <a
        data-cy="french-application-link"
        href={getFrenchApplication(product.id, ui, getGUID())}
        target="_blank"
        rel="noopener"
      >
        <FormattedMessage id="fr-application.text" />
      </a>
    </div>
  );

  const onRendered = useCallback((selectedTest = "") => {
    dispatch({
      type: CONSTANT.dispatchTypeFieldUpdate,
      fieldName: "nsTestSinPrefill",
      fieldValue: selectedTest
    });
  }, []);

  return (
    <div id="product-entry-content">
      <form name="Canada-DAPP-entry-page" className="row column">
        {!showCta ? (
          <Fragment>
            {frechApplicationLink}
            <EntryDisclosure intl={intl} data={product} product={product} />
            <EntryPrivacy intl={intl} />
          </Fragment>
        ) : (
          <section className="row column" id="entry-agent-id">
            <EntrySectionHeader
              intl={intl}
              id="agent-information-header"
              title="entry-section.title.agent"
            />
            <CtaAgentId intl={intl} />
          </section>
        )}
        <section className="row column">
          {isQkPrefill && <PrefillWarningEntry />}
          {FEATURE_FLAGS.ENABLE_SINPREFILL && prefillToken ? (
            <NS experimentName="dapp-sinPrefill" correlationId={getGUID()}>
              <Control weight={controlWeight}>
                <NSTracker testName={"control"} />
                <PersonalSection
                  cardBrand={cardImageBrands()}
                  showCard
                  showLearnAboutField={false}
                  showMembershipNumber={false}
                  showMothersMaidenName={false}
                  showNameOnCard
                  showSINField
                  showLegalDisclaimer={true}
                />
              </Control>
              <Variation name="sinPrefill" weight={sinPrefillWeight}>
                <NSTracker testName={NS_TEST_SIN_PREFILL} onRender={onRendered} />
                <PersonalSection
                  cardBrand={cardImageBrands()}
                  showCard
                  showLearnAboutField={false}
                  showMembershipNumber={false}
                  showMothersMaidenName={false}
                  showNameOnCard
                  showSINField
                  disableIdentityFields
                  maskSINvalue
                  showLegalDisclaimer={true}
                />
              </Variation>
            </NS>
          ) : (
            <PersonalSection
              cardBrand={cardImageBrands()}
              showCard
              showLearnAboutField={false}
              showMembershipNumber={false}
              showMothersMaidenName={false}
              showNameOnCard
              showSINField
              showLegalDisclaimer={true}
            />
          )}
        </section>

        <section className="row column">
          <ContactSection
            extraLabel={undefined}
            isEmailRequired={true}
            isEmailTooltipRequired={false}
            showPhoneNumberType
            useAutoComplete={false}
            emailInputExtraHelp="_sc.tooltip-text.email"
            emailCallback={(e) =>
              FEATURE_FLAGS.ENABLE_EAV ? emailVerification(e, prefilledEmail) : undefined
            }
          />
        </section>

        <section className="row column">
          <AuthorizedUserSection
            showAdditionalInformation
            showConsentCheckbox
            showHeader
            showMembershipNumber={false}
            showPhoneNumberType
            useAutoComplete={false}
          />
        </section>

        <section className="row column">
          <EmploymentSection hideLength />
        </section>

        <section className="row column">
          <FinancialSection mode="standard" productType={productTypeInfo} />
        </section>

        {!showCta ? (
          <EntryConsentToReview intl={intl} />
        ) : (
          <Fragment>
            {frechApplicationLink}
            <EntryDisclosure
              intl={intl}
              data={{ disclosures }}
              product={product}
              disclosuresClass="cta-disclosures"
            />
          </Fragment>
        )}

        <div className="row align-center review-button">
          <div className="entry-review-button small-10 medium-8 columns">
            <button
              id="entry-review-button"
              type="button"
              className="entry-review-button button"
              onClick={(e) => submitEntry(e)}
            >
              <FormattedMessage id="entry-review.button" />
            </button>
            {FEATURE_FLAGS.ENABLE_PAPERLESS && (
              <NS experimentName="dapp-paperless">
                <Control weight={control}>
                  <span className="no-paperless-consent"></span>
                </Control>
                <Variation name="opt-in" weight={optIn}>
                  <span className="opt-in"></span>
                </Variation>
              </NS>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
// component properties
EntryForm.propTypes = {
  intl: intlShape.isRequired,
  showCta: PropTypes.bool,
  isPrefill: PropTypes.bool,
  isExternalAffiliate: PropTypes.bool
};
export default EntryForm;
