import PropTypes from "prop-types";
import React, { Component } from "react";
import { intlShape, FormattedMessage } from "react-intl";

class EntryConsentToReview extends Component {
  static propTypes = {
    locale: PropTypes.string,
    intl: intlShape.isRequired,
    isDisclosures: PropTypes.bool
  };

  render() {
    const consentContent = this.props.isDisclosures
      ? "accept-disclosures-consent.content"
      : "entry-review-consent.content";
    const { intl } = this.props;

    return (
      <section id="entry-privacy" className="row column privacy-drawers-wrapper">
        <div className="entry-review-consent">
          <span data-testid="entry-review-consent-links-container">
            <FormattedMessage
              id={consentContent}
              values={{
                linkText1: (
                  <a href={intl.formatMessage({ id: "entry-review-consent.linkUrl1" })}>
                    <FormattedMessage id="entry-review-consent.linkText1" />
                  </a>
                ),
                linkText2: (
                  <a href={intl.formatMessage({ id: "entry-review-consent.linkUrl2" })}>
                    <FormattedMessage id="entry-review-consent.linkText2" />
                  </a>
                ),
                linkText3: (
                  <a href={intl.formatMessage({ id: "entry-review-consent.linkUrl3" })}>
                    <FormattedMessage id="entry-review-consent.linkText3" />
                  </a>
                )
              }}
            />
          </span>
        </div>
      </section>
    );
  }
}

export default EntryConsentToReview;
