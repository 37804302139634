import { FormattedMessage } from "react-intl";
import React from "react";
import { LANGUAGES, BRAND_MAP } from "../state/constants/product";
import config from "../../config";
import { CHANNEL_PHONE } from "../state/constants/channel";
import { getLocale } from "./session";
import { getCTAQs, getChannelFromQS, isOriginGYC, isOriginUYC, redirectTo } from "./dom-utils";
import { GET_YOUR_CARD_URL, UPGRADE_YOUR_CARD_URL } from "../routes/routes-constants";
import getWebLogger from "./logger/webLogger";

/**
 * Enum for product display content (snippet) values.
 * Card Summary sniipets require a titile property
 * UI snippets are in [99]
 * @readonly
 * @enum {string}
 */
const productDisplayContentEnum = {
  1: "MEMBERSHIP_FEE_SNIPPET",
  2: "REWARDS_REDEMPTION_SNIPPET",
  3: "REWARDS_BONUS_SNIPPET",
  4: "REWARDS_RATE_SNIPPET",
  5: "PURCHASE_AIR_SNIPPET",
  7: "REWARDS_SNIPPET",
  99: [
    "PRIMARY_BENEFIT_SNIPPET",
    "CARD_ART_IMAGE_URL",
    "BANNER_IMAGE_URL",
    "BRANDED_PRODUCT_NAME_SNIPPET",
    "CARD_NAME_SNIPPET",
    "CREDIT_LINE_INCREASE_SNIPPET",
    "CARD_ART_APPROVED_IMAGE_URL",
    "CARD_ART_ALT",
    "FOREIGN_TRANSACTIONN_FEE",
    "DOWNSELL_CARD_NAME_SNIPPET",
    "FRENCH_MAPPING"
  ],
  properties: {
    1: { title: <FormattedMessage id="snippets.title.annual-fee" /> },
    2: { title: <FormattedMessage id="snippets.title.rewards-redemption" /> },
    3: { title: <FormattedMessage id="snippets.title.rewards-bonus" /> },
    4: { title: <FormattedMessage id="snippets.title.reward-rate" /> },
    5: { title: <FormattedMessage id="snippets.title.purchase-air" /> },
    7: { title: <FormattedMessage id="snippets.title.rewards" /> }
  }
};

/**
 * Definition of Snippet order by brand
 * @readonly
 * @const {Object.<string, number[]}
 */
const productDisplayContentOrder = {
  ASPIRECASH: [7, 5, 1],
  ASPIRETRAVEL: [7, 3, 2, 5, 1],
  ASPIRETRAVELWORLDELITE: [7, 3, 2, 5, 1],
  GUARANTEEDLOWRATEMASTERCARD: [5, 1],
  GUARANTEEDMASTERCARD: [5, 1],
  GUARANTEEDSECUREDMASTERCARD: [5, 1],
  NOFEESMARTREWARDSMASTERCARD: [5, 1],
  AMFSMARTREWARDSMASTERCARD: [5, 1],
  GUARANTEEDMASTERCARD22PCT: [5, 1],
  GUARANTEEDMASTERCARD26PCT: [5, 1],
  GUARANTEEDMASTERCARD30PCT: [5, 1],
  GUARANTEEDMASTERCARDDEPOSIT: [5, 1]
};

/**
 * For a given product brand, returns image brand for card art.
 * @param {String} brand
 */
export const cardImageBrands = (brand) => {
  for (let key of Object.keys(BRAND_MAP)) {
    if (key === brand) {
      return BRAND_MAP[key];
    }
  }
};

/**
 * For a given brand, extract card summary and ui snippets and return them in predefined order.
 * @param {Object} productInfo
 * @param {Object} filteredProductDisplayContent
 */
const orderProductDisplayContent = (productInfo, filteredProductDisplayContent) => {
  const cardSummary = [];
  const ui = [];

  if (filteredProductDisplayContent.length) {
    productDisplayContentOrder[productInfo.brand].forEach((productDisplayContentOrderElement) => {
      let result = filteredProductDisplayContent.find(
        (element) => element.type === productDisplayContentEnum[productDisplayContentOrderElement]
      );
      if (result !== undefined) {
        let productDisplayContentEnumProperty =
          productDisplayContentEnum.properties[productDisplayContentOrderElement];
        if (
          productDisplayContentEnumProperty !== undefined &&
          productDisplayContentEnumProperty.title !== undefined
        ) {
          result.title = productDisplayContentEnumProperty.title;
        }
        cardSummary.push(result);
      }
    });

    productDisplayContentEnum[99].forEach((uiSnippet) => {
      let result = filteredProductDisplayContent.find((element) => element.type === uiSnippet);
      result !== undefined && ui.push(result);
    });
  }

  return {
    cardSummary,
    ui
  };
};

/**
 * Filter and extract a language from language array.
 * NOTE: July 2018 - For the foreseeable future there are going to be no changes to iCatalyst. The new system
 * that is being planned as a replacment for iCatalyst is SPICE and until there is more clarity on the direction
 * of SPICE, we will continue to consume snippet content from iCatalyst as en-US for English as es-US for French.
 * To prevent spreading of the es-US and en-US hardcoding in the code, the LANGUAGES constants will be used to
 * lookup various English and French Canada flags. This method below is a helper function to extract the values we
 * require from the enumerated object.
 * @param {Array} languageArray
 * @param {String} language
 */
export const extractSnippetLanguage = (languageArray, language) => {
  return languageArray.find((e) => e === language);
};

/**
 * Filter snippets based on language code and pass the sublist for ordering.
 * @param {Object} productInfo
 */
export const buildProductDisplayContent = (productInfo) => {
  const { brand, productDisplayContent, languageCode } = productInfo;
  let filteredProductDisplayContent = [];

  //remove when icatalyst returns language code and have the ability to add en-CA and fr-CA langugage for snippets
  if (
    brand &&
    Object.keys(productInfo).length !== 0 &&
    Array.isArray(productDisplayContent) &&
    productDisplayContent.length
  ) {
    filteredProductDisplayContent = productDisplayContent.filter(
      ({ language: snippetLanguage }) =>
        snippetLanguage ===
        (languageCode === LANGUAGES.FRENCH_CANADA
          ? extractSnippetLanguage(LANGUAGES.FRENCH_ALL, snippetLanguage)
          : extractSnippetLanguage(LANGUAGES.ENGLISH_ALL, snippetLanguage))
    );
  }
  return orderProductDisplayContent(productInfo, filteredProductDisplayContent);
};

export const buildProductDisplayContentFromCanadaProductsAPI = (
  productInfo,
  unmappedDisplayContents
) => {
  let productDisplayContents = unmappedDisplayContents.map((displayContent) => {
    return {
      language: displayContent.languageCode || undefined,
      type: displayContent.snippetType || undefined,
      text: displayContent.snippetText || undefined
    };
  });
  return buildProductDisplayContent({
    ...productInfo,
    productDisplayContent: productDisplayContents
  });
};

/**
 * Take user entered Reservation Code and access Code and determine
 * if user should be redirected to Quick check page
 * @param {Object} userEnteredCode
 */
export const verifyCodesForQuickCheckUA = (reservationNumber, accessCodeNumber) => {
  let quickCheckRedirect = false;
  const {
    quickCheckUA: { redirectUrl, codes }
  } = config();
  let quickCheckRedirectUrl = {};
  const userLocale = getLocale(window);
  //when expected codes for a locale are defined and contain atleast 1 reservation and access code combination
  if (codes && codes.length > 0) {
    for (const { reservationCode, accessCode, locale, query } of codes) {
      if (
        reservationNumber.substr(0, 7) === reservationCode.substr(0, 7) &&
        accessCodeNumber.substr(0, 2) === accessCode.substr(0, 2) &&
        locale.includes(userLocale)
      ) {
        quickCheckRedirect = true;
        quickCheckRedirectUrl = Object.assign(quickCheckRedirectUrl, redirectUrl);
        // append query params specific to code
        quickCheckRedirectUrl.query = Object.assign(quickCheckRedirectUrl.query, query);
        break;
      }
    }
  }
  return { quickCheckRedirect, quickCheckRedirectUrl };
};

/**
 * Takes English productId and determine the French productId
 * @param {String} productId
 * @param {List} productDisplayContent
 */

export const fetchFrenchProductForBillC96 = (productId, productDisplayContent) => {
  const logger = getWebLogger("product-utils.js");
  let frenchProductID;

  frenchProductID = getFrenchMappingSnippet(productId, productDisplayContent);
  if (frenchProductID) {
    return frenchProductID;
  }

  const { billC96Mapping } = config();
  frenchProductID = billC96Mapping[productId];
  if (frenchProductID && frenchProductID.length > 0) {
    return frenchProductID;
  }

  // Log Error and show technical difficulty
  logger.info({
    actionType: "FRENCH_PRODUCT_ERROR",
    message: `No equivalent french product found for product id: ${productId}`
  });
  redirectTo("/technical-difficulty", {
    message: `French Product Unavailable = ${productId}`
  });
};

/**
 * Returns French productID if exists in English Snippet Text Mapping
 * @param {List} productDisplayContent
 */

export const getFrenchMappingSnippet = (productId, productDisplayContent) => {
  let frenchProductID;
  if (productDisplayContent && productDisplayContent.length > 0) {
    productDisplayContent.forEach((snippet) => {
      if (snippet.type === "FRENCH_MAPPING") {
        frenchProductID = snippet.text;
      }
    });
    return frenchProductID;
  }
  const logger = getWebLogger("product-utils.js");
  logger.info({
    actionType: "FRENCH_MAPPING_SNIPPET_NOT_FOUND_RETRIEVING_FROM_CONFIG",
    message: `French mapping snippet not found for the product: ${productId}`
  });
  return null;
};

const setCTAQS = (url) => {
  if (getChannelFromQS() == CHANNEL_PHONE) {
    return url + "&" + getCTAQs();
  } else {
    return url;
  }
};

export const getFrenchApplication = (productId, productDisplayContent, correlationId) => {
  if (isOriginUYC()) {
    const UYCFrenchURL = `${UPGRADE_YOUR_CARD_URL}?lang=${LANGUAGES.FRENCH_CANADA}`;
    return setCTAQS(UYCFrenchURL);
  }
  if (isOriginGYC()) {
    const GYCFRENCHURL = `${GET_YOUR_CARD_URL}?lang=${LANGUAGES.FRENCH_CANADA}`;
    return setCTAQS(GYCFRENCHURL);
  }

  const frenchProduct = fetchFrenchProductForBillC96(productId, productDisplayContent);
  if (frenchProduct) {
    let frenchUrl =
      window.location.protocol + "//" + window.location.host + "/?productId=" + frenchProduct;
    if (correlationId) {
      frenchUrl += "&corid=" + correlationId;
    }
    return setCTAQS(frenchUrl);
  }
  return null;
};
