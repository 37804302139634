import React, { Component } from "react";
import PropTypes from "prop-types";
import { intlShape, FormattedMessage } from "react-intl";

class TechnicalDifficultyQuickCheck extends Component {
  static propTypes = {
    intl: intlShape,
    customerName: PropTypes.string
  };

  render() {
    const { intl, customerName } = this.props;
    const title = customerName
      ? "technical-difficulty-quick-check.name-title"
      : "technical-difficulty-quick-check.title";

    return (
      <div className="row column text-center error-page">
        <i className="oi-caution outline header-icon" />
        <h2>
          <FormattedMessage id={ title } values={ { firstName: customerName } } />
        </h2>
        <p>
          <FormattedMessage
            id="technical-difficulty-quick-check.text"
            values={ {
              linkCTA: (
                <a href={ intl.formatMessage({ id: "compare-credit-cards.external.url" }) }>
                  <FormattedMessage id="technical-difficulty-quick-check.link" />
                </a>
              )
            } }
          />
          <br />
          <FormattedMessage id="technical-difficulty-quick-check.next-step" />
        </p>
      </div>
    );
  }
}

export default TechnicalDifficultyQuickCheck;
