import {
  APPLICATION_APPROVED,
  APPLICATION_DECLINED,
  APPLICATION_SOFT_DECLINED
} from "../../state/constants/decision";

// INFO: used for page tracking logs
//       when including a new decision, also include the taxonomy here
//
//       empty string => no page tracking or development error will be logged
//       null => taxonomy will be informed somewhere else
//       undefined => no page tracking, but a development error will show on console
export const decisionTaxonomyMap = (decision, f2fRequired) => {
  switch (decision.toLowerCase()) {
    case APPLICATION_APPROVED:
      return f2fRequired ? "approved but identity validation required" : "approved";
    case APPLICATION_DECLINED:
      return "declined";
    case APPLICATION_SOFT_DECLINED:
      return "soft decline";
    default:
      // TODO Ask Harsh or Aaron what is pending approval
      return "pendingApproval";
  }
};

// INFO: used for page tracking logs
//       unless a new type of decision gets created you won't need to add other
//       entries here. (ex. upmarket and secure share the same decisions)
export const decisionApplicationMap = (decision) => {
  switch (decision.toLowerCase()) {
    case APPLICATION_APPROVED:
      return "approved";
    case APPLICATION_DECLINED:
      return "decline";
    case APPLICATION_SOFT_DECLINED:
      return "softDecline";
    default:
      // TODO Ask Harsh or Aaron what is pending approval
      return "pendingApproval";
  }
};

// using stage-0 would result: export ApprovedF2F from './approved-f2f';
import _ApprovedF2F from "./approved-f2f";
export { _ApprovedF2F as ApprovedF2F };

// using stage-0 would result: export Approved from './approved';
import _Approved from "./approved";
export { _Approved as Approved };

// using stage-0 would result: export SecuredApprovedF2F from './secured-approved-f2f';
import _SecuredApprovedVideoF2F from "./secured-approved-video-f2f";
export { _SecuredApprovedVideoF2F as SecuredApprovedF2F };

// using stage-0 would result: export SecuredApproved from './secured-approved';
import _SecuredApprovedVideo from "./secured-approved-video";
export { _SecuredApprovedVideo as SecuredApproved };

// using stage-0 would result: export Declined from './declined';
import _Declined from "./declined";
export { _Declined as Declined };

// using stage-0 would result: export SoftDeclined from './soft-declined';
import _SoftDeclined from "./soft-declined";
export { _SoftDeclined as SoftDeclined };

// using stage-0 would result: export SoftDeclined from './soft-declined';
import _KYCConcerned from "./kyc-concerned";
export { _KYCConcerned as KYCConcerned };
