import requestHeaders from "./request-headers";
import { MIME_TYPE } from "./settings";
import { BadRequest, ServerError, NotAuthorized, ConnectionError } from "../../errors";
import { getQS } from "../../dom-utils";
import getWebLogger from "../../logger/webLogger";

export default (url, params, callDescription, options = {}) => {
  const logger = getWebLogger("request-get.js");
  const qs = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");

  const getUrl = qs ? `${url}?${qs}` : url;

  const { responseType, fetchOptions, isCustomHeaders, logIfResponseFromCache } = options;

  let extraHeaders = {};

  if (fetchOptions && fetchOptions.customHeaders) {
    const { customHeaders } = fetchOptions;
    Object.assign(extraHeaders, customHeaders);
  }

  // If the sccwo
  if (getQS("sccwo")) extraHeaders["Cache-Control"] = "no-store";

  /**
   * provides the capabilty to pass in custom options to fecth()
   * one use case would be when we want to strip the existing headers, when making the disclosure call.
   */
  const fetchAPIOptions = {
    method: "GET",
    headers: isCustomHeaders
      ? requestHeaders(url, extraHeaders)
      : requestHeaders(null, extraHeaders),
    ...fetchOptions
  };

  // The fetch() API only rejects a promise when a network error is encountered.
  // The catch will handle any such failure cases. For all other HTTP status code,
  // the conditions below will do the appropriate error handlng.
  return fetch(getUrl, fetchAPIOptions)
    .catch(() => {
      throw new ConnectionError(`Connection Error - ${callDescription}`);
    })
    .then((res) => {
      if (logIfResponseFromCache) {
        if (res.headers["X-Cache"] === "Hit from cloudfront") {
          logger.info({
            actionType: "PRODUCT_RETRIEVED_FROM_CACHE",
            message: "Product successfully retrieved from cloudfront cache."
          });
        }
      }
      if (res.status >= 500) {
        throw new ServerError(null, res.status);
      } else if (res.status === 401) {
        throw new NotAuthorized(null, res.status);
      } else if (res.status >= 400) {
        throw new BadRequest(null, res.status);
      } else if (responseType === MIME_TYPE.TEXT_HTML) {
        return res.text();
      } else {
        return res.json();
      }
    });
};
