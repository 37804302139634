import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

import DeclinedCreditKeeper from "../declined-credit-keeper";

const Declined = ({ intl, creditKeeperBlock, creditKeeperUrl }) => {
  return (
    <div>
      <div data-cy="decision-page" className="column small-8 text-center decision-page">
        <h1>
          <FormattedMessage id="decision.decline.title" />
        </h1>
        <p>
          <FormattedMessage id="decision.decline.message" />
        </p>
        <p data-cy="decision-faq">
          <FormattedMessage
            id="decision.questions"
            values={{
              linkFaq: (
                <a
                  href={intl.formatMessage({ id: "faq.external.url" })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="decision.link.faq" />
                </a>
              )
            }}
          />
          <br />
          <FormattedMessage
            id="decision.text.thank-you"
            values={{
              registered: (
                <sup>
                  <FormattedMessage id="common.registered" />
                </sup>
              )
            }}
          />
        </p>
      </div>

      {creditKeeperBlock && (
        <DeclinedCreditKeeper
          id="declined-credit-keeper"
          intl={intl}
          creditKeeperUrl={creditKeeperUrl}
        />
      )}
    </div>
  );
};

Declined.propTypes = {
  intl: intlShape.isRequired,
  creditKeeperBlock: PropTypes.bool,
  creditKeeperUrl: PropTypes.string
};

export default Declined;
