// Create a new object, that prototypically inherits from the Error constructor
function ServerError(message, code) {
  this.name = "DynamicAppCanada - InternalServerError";
  this.code = code;
  this.message = message || "Internal Server Error";
  this.stack = new Error().stack;
}
ServerError.prototype = Object.create(Error.prototype);
ServerError.prototype.constructor = ServerError;

function BadRequest(message, code) {
  this.name = "DynamicAppCanada - BadRequest";
  this.code = code;
  this.message = message || "Bad Request";
  this.stack = new Error().stack;
}
BadRequest.prototype = Object.create(Error.prototype);
BadRequest.prototype.constructor = BadRequest;

function NotAuthorized(message, code) {
  this.name = "DynamicAppCanada - NotAuthorized";
  this.code = code;
  this.message = message || "Not Authorized";
  this.stack = new Error().stack;
}
NotAuthorized.prototype = Object.create(Error.prototype);
NotAuthorized.prototype.constructor = NotAuthorized;

function ConnectionError(message) {
  this.name = "DynamicAppCanada - ConnectionError";
  this.message = message || "Connection Error";
  this.code = 503;
  this.stack = new Error().stack;
}
ConnectionError.prototype = Object.create(Error.prototype);
ConnectionError.prototype.constructor = ConnectionError;

export { ServerError };
export { BadRequest };
export { NotAuthorized };
export { ConnectionError };
