import React, { useContext, useEffect, useState } from "react";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import { intlShape, injectIntl, FormattedMessage, FormattedDate } from "react-intl";
import WalkAwayModalTimer from "@cof/graffiti-alley-spray-cans/modal-bodies/WalkAwayModalTimer";
import EntryHeader from "../components/entry-form/entry-header";
import OptionsField from "@cof/graffiti-alley-spray-cans/atoms/OptionsField";
import ApplicationTermsConditions from "../components/privacy/application-terms-conditions";
import { getSnippetTextHTML, redirectTo } from "../lib/dom-utils";
import ReviewSection from "@cof/graffiti-alley-spray-cans/molecules/ReviewSection";
import PaperlessConsent from "@cof/graffiti-alley-spray-cans/molecules/PaperlessConsent";
import { cleanseSpecialCharacters } from "../lib/string-utils";
import PrefillWarningReview from "../components/alerts/prefill-warning-review";
import { pageLoadLogger } from "../lib/logger";

import { LANGUAGES, CARD_NAME_SNIPPET } from "../state/constants/product";
import { CURRENCY_SIGN } from "../state/constants/list-options";

export const SIN_OBFUSCATE_FORMAT = [6, /\d/];

import { CHANNEL_PHONE } from "../state/constants/channel";
import { applyObfuscate } from "../components/form/hoc/obfuscate-input";
import { check } from "../lib/api/identity";
import { APPLICATION_APPROVED, APPLICATION_SOFT_DECLINED } from "../state/constants/decision";
import { AppContext } from "../state/app-context";
import {
  APP_DECISION,
  APP_HAS_BEEN_SUBMITTED,
  APP_IDENTITY_DECISION,
  APP_SET_STAGE
} from "../state/constants/action-types";
import { submit } from "../lib/api/application";
import { AppSubmit, Processing } from "../components/status";
import { changeCalypsoContext } from "../lib/CalypsoUtil";
import useLogger from "../hooks/useLogger";
import { NaturalSelectionSoloMode } from "@cof/natural-selection";
import config from "../../config";
import { NS_TEST_SIN_PREFILL } from "../lib/affiliate-constants";
const { NS, Control, Variation } = NaturalSelectionSoloMode;

const EntryReview = ({ intl }) => {
  const { data, toggleModal, dispatch } = useContext(SprayCanContext);
  const logger = useLogger("entry-review.js");

  const { prefill, isExternalAffiliate, reservationNumber, accessCode, nsTestSinPrefill } = data;
  const { app, appDispatch } = useContext(AppContext);

  const { product } = app;
  const { productDisplayContent, channel, snippets } = product;
  const [, setAppSubmitted] = useState(false);
  useEffect(() => {
    pageLoadLogger(product, null, app.prefillKey);
    logger.info({ message: "In Application review page" });
    changeCalypsoContext("CA_ApplicationReview");
    dispatch({
      type: "fieldUpdate",
      fieldName: "reviewPageConsentDisclosure",
      fieldValue: false
    });
  }, [app.prefillKey, dispatch, product]);

  const {
    paperlessNsConfig: { control, optIn }
  } = config();

  const getDecisionRedirectUrl = (decision) => {
    const { concerns, applicationStatus } = decision;
    const status = applicationStatus;
    const { hasAlertConcern, hasKYCConcern, hasFundingConcern } = concerns;

    let decisionRedirectUrl = "/application-decision";

    if (!hasAlertConcern && hasFundingConcern) {
      decisionRedirectUrl = "/secured-card-approval";
    } else if (
      (APPLICATION_SOFT_DECLINED.localeCompare(status?.toLowerCase()) === 0 &&
        !hasAlertConcern &&
        !hasFundingConcern &&
        hasKYCConcern) ||
      (APPLICATION_APPROVED.localeCompare(status?.toLowerCase()) === 0 &&
        !hasAlertConcern &&
        !hasFundingConcern &&
        !hasKYCConcern)
    ) {
      decisionRedirectUrl = "/capitalone-card-approval";
    }

    return decisionRedirectUrl;
  };

  const submitApplication = async (session) => {
    logger.info({
      logFilter: "CANCA-20923",
      message: "Before SubmitApp",
      verificationId: session.verificationId
    });
    let res;
    try {
      toggleModal(null, AppSubmit, {
        isCloseable: false,
        message: "app.status.text.processing-application"
      });
      res = await submit(data, product, session);

      logger.info({
        logFilter: "CANCA-20923",
        message: "After SubmitApp"
      });

      appDispatch({
        type: APP_HAS_BEEN_SUBMITTED,
        hasAppBeenSubmitted: true
      });
      appDispatch({
        type: APP_SET_STAGE,
        stage: 5
      });

      appDispatch({
        type: APP_DECISION,
        decision: res
      });
      const redirectUrl = getDecisionRedirectUrl(res);
      changeCalypsoContext("CA_ApplicationDecision");
      redirectTo(redirectUrl);
      toggleModal(null, AppSubmit);
    } catch (e) {
      toggleModal(null, AppSubmit);
      redirectTo("/technical-difficulty", { ...e, ...product });
    }
  };

  const checkIdentity = async () => {
    let decision;

    try {
      toggleModal(null, Processing, {
        isCloseable: false,
        message: "app-status.text.verifying",
        icon: "large oi-person"
      });

      await new Promise((resolve) => setTimeout(resolve, 100));

      const session = {
        reservationNumber: reservationNumber === "" ? null : reservationNumber,
        accessCode: accessCode,
        isUpgradeCardFlow: app.isUpgradeCardFlow
      };

      logger.info({
        logFilter: "CANCA-20923",
        message: "Before IDV"
      });

      const res = await check(data, product, session);

      appDispatch({
        type: APP_SET_STAGE,
        stage: 3
      });

      const submitSession = {
        checksum: res.applicationChecksum,
        verificationId: res.identityDecisionResult.verificationId,
        verificationSession: res.identityDecisionResult.sessionReferenceId,
        reservationNumber: reservationNumber === "" ? null : reservationNumber,
        accessCode: accessCode,
        clientID: app.clientID
      };

      logger.info({
        logFilter: "CANCA-20923",
        message: "After IDV",
        verificationId: submitSession.verificationId
      });

      decision = res.identityDecisionResult.identityVerificationDecision
        ? res.identityDecisionResult.identityVerificationDecision.toLowerCase()
        : null;

      appDispatch({
        type: APP_IDENTITY_DECISION,
        verificationDecision: decision
      });
      toggleModal(null, Processing);

      switch (decision) {
        case "pass":
          await submitApplication(submitSession);
          break;

        case "fail":
          await submitApplication(submitSession);
          break;

        case "error":
          redirectTo("/technical-difficulty", {
            message: `identity check returned unexpected decision = ${decision}`,
            ...product
          });
          break;

        default:
          redirectTo("/technical-difficulty", {
            message: `identity check returned unexpected decision = ${decision}`,
            ...product
          });
      }
    } catch (e) {
      const qs = new URLSearchParams(window.location.search);
      // Only redirect to validation error page from UYC flow (uyc query string)
      if (e.code === 400 && qs.has("uyc") === true) {
        toggleModal(null, Processing);
        redirectTo(
          "/validation-error/?" + qs,
          {
            message: "Applicant information may have been inputted incorrectly."
          },
          false
        );
      } else {
        toggleModal(null, Processing);
        redirectTo("/technical-difficulty", { ...e, ...product });
      }
    }
  };

  const completeApplication = async (event) => {
    event.preventDefault();
    setAppSubmitted(true);
    appDispatch({
      type: APP_SET_STAGE,
      stage: 2
    });
    await checkIdentity();
  };

  const handleEditLink = (id) => {
    appDispatch({
      type: APP_SET_STAGE,
      stage: 0
    });
    changeCalypsoContext("CA_ApplicationForm");
    redirectTo("/" + (window.location.search || "") + "#" + id, null, false);
  };

  const showCta = channel === CHANNEL_PHONE;
  const { locale } = intl;

  const SPACE = " ";

  const formatCurrency = (value, locale) => {
    if (value && Intl) {
      const formatted = Intl.NumberFormat(locale).format(value.trim());
      if (locale.includes(LANGUAGES.FRENCH)) {
        return formatted + SPACE + CURRENCY_SIGN[locale];
      }
      return CURRENCY_SIGN[locale] + formatted;
    }
    return value;
  };

  const isPrefill = prefill && app.stage < 2;
  const isPrefillExternalAffiliate = prefill && isExternalAffiliate;

  /**
   * @todo remove conditional rendering of snippets once all snippets come back from icatalyst
   */

  const productSnippets =
    productDisplayContent.cardSummary.length && productDisplayContent.ui.length
      ? productDisplayContent
      : snippets;

  /**
   * Formats a numeric value for the application information summary.
   *
   * @param {*} dateString is a string value in the form of YYYY-MM-DD
   * to show for one of the application information summary elements.
   */
  const formatDate = (dateString) => (
    <FormattedDate value={dateString} timeZone="UTC" day="numeric" month="numeric" year="numeric" />
  );

  const getSINContent = (personalSin, prefillToken) => {
    if (nsTestSinPrefill === NS_TEST_SIN_PREFILL && prefillToken) {
      return "***-***-***";
    }
    if (personalSin) {
      return applyObfuscate(personalSin, SIN_OBFUSCATE_FORMAT[0], SIN_OBFUSCATE_FORMAT[1]);
    }
    return "";
  };

  const disclosureOnChange = (event) => {
    let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    value = value == null ? "" : value;
    return dispatch({
      type: "fieldUpdate",
      fieldName: "reviewPageConsentDisclosure",
      fieldValue: value
    });
  };

  const onTimeout = () => {
    redirectTo("/session-timeout", { message: "session timeout" });
  };

  // App submit button must always be enabled for a phone application
  let disableSubmitButton =
    !showCta &&
    data &&
    (!data.reviewPageConsentDisclosure ||
      (data.isPaperlessConsentMandatory && !data.paperlessConsent));

  return (
    <div id="product-entry-review">
      <WalkAwayModalTimer
        appTimeout={10 * 60 * 1000}
        onModalTimeout={onTimeout}
        onAppExit={onTimeout}
      />
      <EntryHeader
        locale={locale}
        intl={intl}
        onHeaderAlert={toggleModal}
        snippets={productSnippets}
      />

      <div className="entry-wrapper row column">
        {isPrefill && !isPrefillExternalAffiliate ? (
          <PrefillWarningReview intl={intl} />
        ) : (
          <div className="review-alert-box">
            <h2 className="callout warning">
              <FormattedMessage id="review-page.pre-completion-text" />
            </h2>
          </div>
        )}

        <ReviewSection
          editAction={
            nsTestSinPrefill === NS_TEST_SIN_PREFILL
              ? undefined
              : () => handleEditLink("personal-info")
          }
          id="personal-info"
          itemList={[
            {
              content: [data.personalFirstName, data.personalLastName],
              labels: ["_sc.first-name.label", "_sc.last-name.label"]
            },
            {
              content: [data.personalDob ? formatDate(data.personalDob) : null],
              labels: ["_sc.dob.label"]
            },
            {
              content: [getSINContent(data.personalSin, data.prefillToken)],
              labels: ["_sc.sin.label"]
            }
          ]}
          title="_sc.section.title.personal"
        />

        <ReviewSection
          editAction={() => handleEditLink("contact-section")}
          id="contact-section"
          itemList={[
            { content: [data.contactStreet], labels: ["_sc.street-address.label"] },
            { content: [data.contactApartment], labels: ["_sc.apartment.label"] },
            { content: [data.contactCity], labels: ["_sc.city.label"] },
            { content: [data.contactProvince], labels: ["_sc.province.label"] },
            { content: [data.contactPostalCode], labels: ["_sc.postal-code.label"] },
            { content: [data.contactPhoneNumber], labels: ["_sc.primary-phone-number.label"] },
            {
              content: [data.selectionText.contactPhoneNumberType],
              labels: ["_sc.phone-number-type.label"]
            },
            { content: [data.contactEmail], labels: ["_sc.email.label"] }
          ]}
          title="_sc.section.title.contact"
        />

        <ReviewSection
          editAction={() => handleEditLink("authorized-user-section")}
          id="authorized-user-section"
          shouldShowNoneWhenMissingContent={true}
          itemList={[
            {
              content: [data.authorizedFirstName, data.authorizedLastName],
              labels: ["_sc.first-name.label", "_sc.last-name.label"]
            },
            {
              content: [data.authorizedDob ? formatDate(data.authorizedDob) : null],
              labels: ["_sc.dob.label"]
            },
            {
              content: [cleanseSpecialCharacters(data.authorizedStreet)],
              labels: ["_sc.street-address.label"]
            },
            { content: [data.authorizedApartment], labels: ["_sc.apartment.label"] },
            {
              content: [cleanseSpecialCharacters(data.authorizedCity)],
              labels: ["_sc.city.label"]
            },
            { content: [data.authorizedProvince], labels: ["_sc.province.label"] },
            { content: [data.authorizedPostalCode], labels: ["_sc.postal-code.label"] },
            {
              content: [data.authorizedPhoneNumber],
              labels: ["_sc.primary-phone-number.label"]
            },
            {
              content: [data.selectionText.authorizedPhoneNumberType],
              labels: ["_sc.phone-number-type.label"]
            }
          ]}
          title="_sc.section.title.authorized"
        />

        <ReviewSection
          editAction={() => handleEditLink("employment-info")}
          id="employment-info"
          itemList={[
            {
              content: [data.selectionText.employmentStatus],
              labels: ["_sc.employment-status.label"]
            },
            {
              content: [data.selectionText.employmentIndustry],
              labels: ["_sc.employment-industry.label"]
            },
            {
              content: [data.selectionText.employmentJobDescription],
              labels: ["_sc.employment-job-description.label"]
            },
            {
              content: [data.employmentCurrentEmployer],
              labels: ["_sc.employment-current-employer.label"]
            },
            {
              content: [data.employmentPhoneNumber],
              labels: ["_sc.employment-phone-number.label"]
            },
            {
              content: [data.selectionText.employmentLengthYears],
              labels: ["_sc.employment-length-years.label"]
            },
            {
              content: [data.employmentLengthMonths],
              labels: ["_sc.employment-length-months.label"]
            }
          ]}
          title="_sc.section.title.employment"
        />

        <ReviewSection
          editAction={() => handleEditLink("financial-info")}
          id="financial-info"
          itemList={[
            {
              content: [data.selectionText.financialHousing],
              labels: ["_sc.financial-housing.label"]
            },
            {
              content: [data.selectionText.financialHasMortgage],
              labels: ["_sc.financial-has-mortgage.label"]
            },
            {
              content: [formatCurrency(data.financialMonthlyPayment, locale)],
              labels: ["_sc.financial-monthly-rent.label"]
            },
            {
              content: [formatCurrency(data.financialAnnualIncome, locale)],
              labels: ["_sc.financial-annual-income.label"]
            },
            {
              content: [formatCurrency(data.financialOtherIncome, locale)],
              labels: ["_sc.financial-other-income.label"]
            },
            {
              content: [data.selectionText.financialBankingInfo],
              labels: ["_sc.financial-banking-info.label"]
            },
            {
              content: [data.selectionText.financialCashAdvance],
              labels: ["_sc.financial-cash-advance.label"]
            }
          ]}
          title="_sc.section.title.financial"
        />

        <ApplicationTermsConditions
          title="entry-review.section-title.application-terms-conditions"
          intl={intl}
          locale={locale}
          productType={product.type}
          productId={product.id}
          showCta={showCta}
          productDisplayContent={product.productDisplayContent.ui}
        />
        {
          // Do not show agreement checkbox for a phone application
          !showCta && (
            <form className="row column entry-review-checkbox">
              <div className="row align-center entry-review-consent-wrapper">
                <div className="small-12 medium-12 columns required">
                  <OptionsField
                    id="entry-disclosure-consent"
                    name="reviewPageConsentDisclosure"
                    flagAsRequired={false}
                    type="checkbox"
                    intl={intl}
                    options={[
                      {
                        id: "disclosure-consent",
                        content: "entry-disclosure-consent.content",
                        contentValues: {
                          productName: (
                            <span
                              dangerouslySetInnerHTML={getSnippetTextHTML(
                                productSnippets,
                                CARD_NAME_SNIPPET
                              )}
                            />
                          )
                        }
                      }
                    ]}
                    onChange={disclosureOnChange}
                  />
                </div>

                {FEATURE_FLAGS.ENABLE_PAPERLESS && (
                  <div className="small-12 medium-12 columns required">
                    <NS experimentName="dapp-paperless">
                      <Control weight={control}>
                        <span className="no-paperless-consent"></span>
                      </Control>
                      <Variation name="opt-in" weight={optIn}>
                        <PaperlessConsent intl={intl} mandatory={true} defaultValue={false} />
                      </Variation>
                    </NS>
                  </div>
                )}
              </div>
            </form>
          )
        }

        <div className="row align-center">
          <div className="submit-button small-10 medium-8 columns">
            <button
              type="button"
              id="submit-button"
              className="submit-button button"
              disabled={disableSubmitButton}
              onClick={completeApplication}
            >
              <FormattedMessage id="entry-submit.button" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

EntryReview.propTypes = {
  intl: intlShape
};

export { EntryReview as EntryReviewRaw };

const EntryReviewHOC = injectIntl(EntryReview);
EntryReviewHOC.displayName = "EntryPage";
export default EntryReviewHOC;
