import React, { Component } from "react";
import { intlShape, FormattedMessage } from "react-intl";

class OtherInformation extends Component {
  static propTypes = {
    intl: intlShape.isRequired
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="drawer-content" id="other-information-link">
        <strong>
          <FormattedMessage id="other-information.heading1.content" tagName="p" />
        </strong>
        <FormattedMessage
          id="other-information.heading1.paragraph1.content"
          tagName="p"
          values={{
            concernsLink: (
              <a
                href={intl.formatMessage({
                  id: "other-information.heading1.paragraph1.linkUrl"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="other-information.heading1.paragraph1.linkText" />
              </a>
            )
          }}
        />

        <FormattedMessage
          id="other-information.heading1.paragraph2.content"
          tagName="p"
          values={{
            linkText: (
              <a
                href={intl.formatMessage({
                  id: "other-information.heading1.paragraph2.linkUrl"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="other-information.heading1.paragraph2.linkText" />
              </a>
            ),
            superscriptOrdinal: (
              <sup>
                <FormattedMessage id="common.superscript.th" />
              </sup>
            )
          }}
        />
        <strong>
          <div style={{ marginBottom: "1rem" }}>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "other-information.heading2.content" })
              }}
            />
          </div>
        </strong>
        <p>
          <FormattedMessage id="other-information.heading2.paragraph1.en.content" />
          <i>
            <FormattedMessage id="other-information.heading2.paragraph1.fr.content" />
          </i>
        </p>
      </div>
    );
  }
}

export default OtherInformation;
