import getWebLogger from "./logger/webLogger";

const setupCalypso = (guid) => {
  if (
    window.cdApi &&
    window.cdApi.setCustomerSessionId &&
    window.cdApi.changeContext &&
    window.cdApi.setCustomerBrand
  ) {
    // in case cdAPI is available but it errors out?
    window.cdApi.setCustomerSessionId(guid);
    window.cdApi.changeContext("CA_ApplicationForm");
    window.cdApi.setCustomerBrand("Capital One Canada");
  } else {
    throw new Error("cdApi not available");
  }
};

const setupCalypsoWithRetry = (guid, duration = 1000, maxRetries = 3) => {
  const logger = getWebLogger("CalypsoUtil.js");
  try {
    setupCalypso(guid);
  } catch (error) {
    if (maxRetries > 0) {
      setTimeout(() => setupCalypsoWithRetry(guid, duration, maxRetries - 1), duration);
    } else {
      // log only the last retry
      logger.info({ actionType: "CALYPSO_ERROR", message: "Error: Failed to setup Calypso." });
    }
  }
};

const changeCalypsoContext = (context) => {
  if (window.cdApi && window.cdApi.setCustomerSessionId && window.cdApi.changeContext) {
    window.cdApi.changeContext(context);
  }
};

//For get-your-card page refresh
const changeCalypsoCustomerSessionId = (context) => {
  if (window.cdApi && window.cdApi.setCustomerSessionId && window.cdApi.changeContext) {
    window.cdApi.setCustomerSessionId(context);
  }
};

export default setupCalypsoWithRetry;

export { changeCalypsoContext, changeCalypsoCustomerSessionId };
