import { getGUID } from "../session";
import { elementTypes } from "./snow-plow-constants";
import getWebLogger from "./webLogger";
import { APPLICATION_DECISION_URL } from "../../routes/routes-constants";
import { getCurrentUrlPathName } from "../../lib/dom-utils";

export const trackUserInteraction = (eventData, elementType, appContextProps) => {
  const logger = getWebLogger("oneTag.js");
  try {
    const decisionInfo = appContextProps.decision ? appContextProps.decision : {};
    const currentParsedUrl = getCurrentUrlPathName();
    let offerType;
    let solicitationReferenceNumber;
    let applicationReferenceId;
    let applicationStatus;
    if (decisionInfo?.uycres && decisionInfo?.uycacc) {
      offerType = btoa(decisionInfo.uycres);
      solicitationReferenceNumber = decisionInfo.uycacc;
    }

    if (APPLICATION_DECISION_URL.includes(currentParsedUrl)) {
      applicationReferenceId = decisionInfo.applicationReferenceId
        ? parseInt(decisionInfo.applicationReferenceId, 10)
        : null;
      applicationStatus = decisionInfo.applicationStatus ? decisionInfo.applicationStatus : null;
    }
    if (elementType != "button" && elementType != "link") {
      let { id, className, textContent, innerText, type } = eventData.target;
      if (eventData.target.parentNode.type == "button") {
        id = eventData.target.parentNode.id;
        className = eventData.target.parentNode.className;
        textContent = eventData.target.parentNode.textContent;
        innerText = eventData.target.parentNode.innerText;
        type = eventData.target.parentNode.type;
      }
      window.oneTag.track("link", {
        event_name: "ui_interaction",
        ui_interaction_action: eventData.type,
        ui_interaction_element_name: eventData.target.elementType
          ? eventData.target.elementType
          : id
          ? `id: ${id}`
          : className
          ? `className: ${className}`
          : `textContent: ${textContent}`,
        ui_interaction_element_type: elementType ? elementType : elementTypes(type),
        ui_interaction_value: innerText.trim(),
        ui_interaction_element_description: eventData.target.textContent,
        application_id: getGUID(),
        application_product_id: appContextProps.product.id ? appContextProps.product.id : {},
        application_product_name: appContextProps.product.brand
          ? appContextProps.product.brand
          : {},
        application_decision: applicationStatus ? applicationStatus : null,
        offer_type: offerType,
        reservation_number: solicitationReferenceNumber,
        application_reference_id: applicationReferenceId
      });
    } else {
      let { id, className, target, href, innerText, textContent } = eventData.target.parentNode;

      if (id === "t2v-button" || id === "tapToVerify") {
        href = new URL(window.location).origin + "/t2v-clicks";
        id = "t2v-button";
      }

      if (eventData.target.parentNode.type == "button" || eventData.target.type == "button") {
        id = "button";
        if (
          eventData.target.parentNode.id == "entry-review-button" ||
          eventData.target.id == "entry-review-button"
        ) {
          href = new URL(window.location).origin + "/application-review";
        }
        if (href == undefined) {
          href = new URL(window.location).origin;
        }
        target = "undefined";
      }
      window.oneTag.track("link", {
        event_name: "ui_interaction",
        link_click_element_id: id,
        link_click_element_classes: className.split(" "),
        link_click_element_target: target,
        link_click_target_url: href,
        link_click_element_content: innerText.trim(),
        ui_interaction_action: eventData.type,
        ui_interaction_element_name: eventData.target.elementType
          ? eventData.target.elementType
          : id
          ? `id: ${id}`
          : className
          ? `className: ${className}`
          : `textContent: ${textContent}`,
        ui_interaction_element_type: elementType
          ? elementType
          : elementTypes(eventData.target.parentNode.type),
        ui_interaction_value: innerText.trim(),
        ui_interaction_element_description: eventData.target.textContent,
        application_id: getGUID(),
        application_product_id: appContextProps.product.id ? appContextProps.product.id : {},
        application_product_name: appContextProps.product.brand
          ? appContextProps.product.brand
          : {},
        application_decision: applicationStatus ? applicationStatus : null,
        offer_type: offerType,
        reservation_number: solicitationReferenceNumber,
        application_reference_id: applicationReferenceId
      });
    }
  } catch (e) {
    logger.info({
      actionType: "FAIL_UI_TRACKER",
      message: "not sending logs as onetag may be disabled or UI tracking event data may be missing"
    });
    console.warn(e);
  }
};
//combining field tracking schema with ui interaction else it will be sent as different event.
export const trackInputUserInteraction = (eventData, precedenceVariables, appContextProps) => {
  const logger = getWebLogger("oneTag.js");
  try {
    let { id, className, textContent, innerText, type, nextElementSibling, form } =
      eventData.target;
    const { elementType, elementName, value } = precedenceVariables;
    if (eventData.target.parentNode.type == "button") {
      id = eventData.target.parentNode.id;
      className = eventData.target.parentNode.className;
      textContent = eventData.target.parentNode.textContent;
      innerText = eventData.target.parentNode.innerText;
      type = eventData.target.parentNode.type;
    }
    window.oneTag.track("link", {
      event_name: "ui_interaction",
      ui_interaction_action: eventData.type,
      ui_interaction_element_name: elementName
        ? elementName
        : id
        ? `id: ${id}`
        : className
        ? `className: ${className}`
        : `textContent: ${textContent}`,
      ui_interaction_element_type: elementType ? elementType : elementTypes(type),
      ui_interaction_value: value ? value : innerText.trim(),
      ui_interaction_element_description: eventData.target.textContent,
      field_tracking_name: id
        ? `id: ${id}`
        : className
        ? `className: ${className}`
        : `textContent: ${textContent}`,
      field_tracking_input_character_length: eventData.target.value.length
        ? eventData.target.value.length
        : null,
      field_tracking_validation_message:
        nextElementSibling && nextElementSibling.className.includes("error")
          ? nextElementSibling.innerText
          : null,
      field_tracking_form_name: form.name,
      field_tracking_validation_status:
        nextElementSibling && nextElementSibling.className.includes("error") ? false : true,
      application_id: getGUID(),
      application_product_id: appContextProps.product.id ? appContextProps.product.id : {},
      application_product_name: appContextProps.product.brand ? appContextProps.product.brand : {}
    });
  } catch (e) {
    logger.info({
      actionType: "FAIL_UI_INPUT_TRACKER",
      message: "not sending logs as onetag may be disabled or UI Input event data may be missing"
    });
    console.warn(e);
  }
};

export const trackmouseoverUserInteraction = (eventData, elementName, appContextProps) => {
  const logger = getWebLogger("oneTag.js");
  try {
    let { id, className, textContent, innerText } = eventData.target;
    if (eventData.target.parentNode.type == "button") {
      id = eventData.target.parentNode.id;
      className = eventData.target.parentNode.className;
      textContent = eventData.target.parentNode.textContent;
      innerText = eventData.target.parentNode.innerText;
    }
    window.oneTag.track("link", {
      event_name: "ui_interaction",
      ui_interaction_action: eventData.type,
      ui_interaction_element_name: elementName
        ? elementName
        : id
        ? `id: ${id}`
        : className
        ? `className: ${className}`
        : `textContent: ${textContent}`,
      ui_interaction_element_type: elementTypes("tooltiplink"),
      ui_interaction_value: innerText.trim(),
      ui_interaction_element_description: eventData.target.textContent,
      application_id: getGUID(),
      application_product_id: appContextProps.product.id ? appContextProps.product.id : {},
      application_product_name: appContextProps.product.brand ? appContextProps.product.brand : {}
    });
  } catch (e) {
    logger.info({
      actionType: "FAIL_MOUSEOVER_TRACKER",
      message: "not sending logs as onetag may be disabled or mouseover event data may be missing"
    });
    console.warn(e);
  }
};

export const trackUserPageView = (product, eventName, eventType, url, info) => {
  const logger = getWebLogger("oneTag.js");
  let offerType;
  let solicitationReferenceNumber;
  let applicationReferenceId;
  let applicationStatus;
  if (info?.uycres && info?.uycacc) {
    offerType = btoa(info.uycres);
    solicitationReferenceNumber = info.uycacc;
  }

  if (APPLICATION_DECISION_URL.includes(url)) {
    applicationReferenceId = info.applicationReferenceId
      ? parseInt(info.applicationReferenceId, 10)
      : null;
    applicationStatus = info.applicationStatus ? info.applicationStatus : null;
  }
  try {
    window.oneTag.track("view", {
      event_name: "page_view",
      application_id: getGUID(),
      application_product_id: product.id,
      application_product_name: product.brand,
      page_title: eventType + ":" + eventName,
      application_decision: applicationStatus ? applicationStatus : null,
      offer_type: offerType,
      reservation_number: solicitationReferenceNumber,
      application_reference_id: applicationReferenceId
    });
  } catch (e) {
    logger.info({
      actionType: "FAIL_PAGE_VIEW_TRACKER",
      message: "not sending logs as onetag may be disabled or pageview event data may be missing"
    });
    console.warn(e);
  }
};
