import React, { useCallback, useContext, useEffect, useState } from "react";
import { intlShape, injectIntl } from "react-intl";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  getOrigin,
  buildQsAfterQuickCheckRedirect,
  isOriginCTA,
  isOriginGYC,
  isOriginQuickCheck,
  getQS,
  isOriginUYC,
  isValidPrefillQS,
  isOriginQKErrorPage
} from "../lib/dom-utils";

import AppStatus from "../components/app-status";
import AppHeader from "../components/app-header";
import AppFooter from "../components/app-footer";

import { APP_SET_PRODUCT } from "../state/constants/action-types";
import { UpdateTabTitle } from "../lib/session";

import {
  EntryPage,
  EntryReview,
  ApplicationDecision,
  TechnicalDifficulty,
  SessionTimeout,
  GetYourCard,
  UpgradeYourCard
} from "../containers";
import {
  InvalidOffer,
  PageNotFound,
  LockoutError,
  UnAuthPageAccess,
  UnauthPageAccessWithoutQk,
  ValidationError
} from "../components/error-pages";
import EntryCTA from "../components/entry-form/entry-cta";

import ProductRoute from "../routes/product-route";

import {
  ENTER_FORM_URL,
  APPLICATION_REVIEW_URL,
  APPLICATION_DECISION_URL,
  INVALID_OFFER_URL,
  UNAUTH_PAGE_ACCESS_URL,
  UNAUTH_PAGE_ACCESS_WITHOUT_QK_URL,
  TECHNICAL_DIFFICULTY_URL,
  SESSION_TIMEOUT_URL,
  GET_YOUR_CARD_URL,
  FIND_YOUR_CARD_URL,
  ENTRY_CTA_URL,
  LOCKOUT_ERROR_URL,
  UPGRADE_YOUR_CARD_URL,
  VALIDATION_ERROR_URL
} from "../routes/routes-constants";
import { SprayCanContext } from "@cof/graffiti-alley-spray-cans/context/app-context";
import {
  getApplicantInfo,
  getProductFromOrchestrator,
  getApplicantInfoFromOrch
} from "../lib/server-info";
import { AppContext } from "../state/app-context";
import useLogger from "../hooks/useLogger";

/*
 * App Component - Root component of our application
 *                 include here the shared functionality of the app
 */
const App = ({ intl }) => {
  const logger = useLogger("app.js");
  const { dispatch } = useContext(SprayCanContext);
  const {
    appDispatch,
    app: { product }
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState();
  const [isAppStarted, setIsAppStarted] = useState(false);

  async function fetchProductInformation(appDispatch, prefill = false) {
    const product = await getProductFromOrchestrator(prefill);
    appDispatch({
      type: APP_SET_PRODUCT,
      product: product
    });

    UpdateTabTitle(product);
  }

  const fetchProductAndPrefill = useCallback(async () => {
    if (isOriginGYC() || isOriginCTA() || isOriginUYC() || isOriginQKErrorPage()) return;

    setIsLoading(true);
    if (isValidPrefillQS()) {
      const partnerCode = getQS("partnerCode");
      const prefillKey = getQS("prefillKey");

      await fetchProductInformation(appDispatch, true);

      let prefillData = {};
      // Following condition to determine whether fetch prefill data from lambda or credit offer

      prefillData = await getApplicantInfoFromOrch(partnerCode, prefillKey);

      dispatch({
        type: "prefillFromPayload",
        prefillPayload: prefillData
      });

      setIsLoading(false);
    } else {
      await fetchProductInformation(appDispatch);
      if (isOriginQuickCheck()) {
        const prefillData = getApplicantInfo();
        dispatch({
          type: "prefillFromPayload",
          prefillPayload: prefillData
        });
      }
      setIsLoading(false);
    }
  }, [appDispatch, dispatch]);

  // start app and load app with initial state
  useEffect(() => {
    if (isOriginQuickCheck()) {
      logger.info({ message: "Landing from POST /find-your-card page" });
    }

    if (!isAppStarted) {
      fetchProductAndPrefill();
      setIsAppStarted(true);
    }
  }, [dispatch, isAppStarted, fetchProductAndPrefill]);

  const originPath = getOrigin();
  const qs = window.location.search || "";

  return (
    <div className="viewport-control">
      <AppHeader intl={intl} />

      <div className="main-content">
        {isLoading ? (
          <AppStatus
            component="Processing"
            message="app-status.text.loading-app"
            intl={intl}
            productDetails={product}
          />
        ) : !isAppStarted ? (
          <Redirect to={{ pathname: originPath, search: qs }} />
        ) : (
          <Switch>
            <ProductRoute exact path={ENTER_FORM_URL} component={EntryPage} />
            <ProductRoute path={APPLICATION_REVIEW_URL} component={EntryReview} />
            {APPLICATION_DECISION_URL.map((path, index) => (
              <ProductRoute path={path} component={ApplicationDecision} key={index} />
            ))}
            <Route path={UNAUTH_PAGE_ACCESS_WITHOUT_QK_URL} component={UnauthPageAccessWithoutQk} />
            <Route path={INVALID_OFFER_URL} component={InvalidOffer} />
            {FEATURE_FLAGS.ENABLE_UPGRADE_YOUR_CARD && (
              <Route path={UPGRADE_YOUR_CARD_URL} component={UpgradeYourCard} />
            )}
            {/* Technical difficulty is after review page - either OOW or Submit App */}
            <Route path={TECHNICAL_DIFFICULTY_URL} component={TechnicalDifficulty} />
            <Route path={VALIDATION_ERROR_URL} component={ValidationError} />
            <Route
              path={UNAUTH_PAGE_ACCESS_URL}
              render={() => <UnAuthPageAccess search={qs.includes("uyc=t") ? "/?uyc=t" : ""} />}
            />

            <Route path={ENTRY_CTA_URL} component={EntryCTA} />
            {/* Session timeout is at enter info page */}
            <Route path={SESSION_TIMEOUT_URL} component={SessionTimeout} />
            {/* Lockout error is after review page */}
            <Route path={LOCKOUT_ERROR_URL} component={LockoutError} />
            <Route path={GET_YOUR_CARD_URL} component={GetYourCard} />
            {product && (
              <Redirect
                from={FIND_YOUR_CARD_URL}
                to={{
                  pathname: ENTER_FORM_URL,
                  search: buildQsAfterQuickCheckRedirect(product.id)
                }}
              />
            )}
            <Route path="*" component={PageNotFound} />
          </Switch>
        )}
      </div>

      <AppFooter intl={intl} />
    </div>
  );
};

// component properties
App.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(App);
