import { useMemo, useContext } from "react";
import { AppContext } from "../state/app-context";
import getLogger from "@cof/client-side-logger";
import {
  DEFAULT_LOGGER_OPTIONS,
  DEFAULT_WRITE_STREAM,
  getMessageAndObject
} from "../lib/logger/webLogger";

const useLogger = (fileName) => {
  if (!fileName) {
    throw new Error("Please provide a file name for logging using useLogger Hook");
  }

  const LOGGER_OPTIONS = {
    ...DEFAULT_LOGGER_OPTIONS,
    // Note: Before adding a new Dynamic Field here, Please check if it can be added to
    // DEFAULT_LOGGER_OPTIONS.dynamicFields`
    // Also note that the Web logger API expects log object in specific schema, any new key added here
    // should also be present on the Backend schema
    dynamicFields: {
      ...DEFAULT_LOGGER_OPTIONS.dynamicFields,
      product: () => {
        const finalProductInfoToLog = { ...product };
        delete finalProductInfoToLog.disclosures;

        return finalProductInfoToLog;
      },
      prefillKey: () => {
        if (prefillKey !== null) {
          return prefillKey;
        }
      }
    }
  };

  const {
    app: { product, prefillKey }
  } = useContext(AppContext);

  const logInstance = useMemo(() => getLogger(LOGGER_OPTIONS), []);

  const log = useMemo(() => {
    logInstance.addStream({
      stream: {
        write: DEFAULT_WRITE_STREAM
      }
    });

    return logInstance.getChildLogger(fileName);
  }, [fileName]);

  return {
    info: (e) => {
      const [msg, obj] = getMessageAndObject(e);

      log.info(obj, msg);
    },
    error: (e) => {
      const [msg, obj] = getMessageAndObject(e);

      log.error(obj, msg);
    }
  };
};

export default useLogger;
