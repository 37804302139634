import PropTypes from "prop-types";
import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const GYCInfoSecurity = (props) => {
  return (
    <div id="info-security-alert">
      <section className="content">
        <p data-testid="informationSecurity">
          <FormattedMessage
            id="get-your-card.info-security-tooltip-content"
            values={{
              privacyPolicy: (
                <a
                  href={props.intl.formatMessage({ id: "gyc-privacy-policy.external.url" })}
                  id="gyc-privacy-policy"
                  target="new"
                >
                  <FormattedMessage id="get-your-card.info-security-tooltip-link" />
                </a>
              )
            }}
          />
        </p>
      </section>
    </div>
  );
};

GYCInfoSecurity.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func
};

export default GYCInfoSecurity;
