// app action types
export const APP_RESET = "@DAP/APP_RESET";
export const APP_SET_STAGE = "@DAP/APP_SET_STAGE";
export const APP_SET_SESSION = "@DAP/APP_SET_SESSION";
export const APP_SET_IS_UPGRADE_CARD_FLOW = "@DAP/APP_SET_IS_UPGRADE_CARD_FLOW";
export const APP_IDENTITY_CHECK = "@DAP/APP_IDENTITY_CHECK";
export const APP_IDENTITY_DECISION = "@DAP/APP_IDENTITY_DECISION";
export const APP_HAS_BEEN_SUBMITTED = "@DAP/APP_HAS_BEEN_SUBMITTED";
export const APP_STARTED = "@DAP/APP_STARTED";
export const APP_DECISION = "@DAP/APP_DECISION";
export const APP_UPDATE_GUID = "@DAP/APP_UPDATE_GUID";
export const APP_SET_CLIENT_ID = "@DAP/APP_SET_CLIENT_ID";
export const APP_SET_CTA_AGENT_ID = "@DAP/APP_SET_CTA_AGENT_ID";
export const APP_SET_PRODUCT = "@DAP/APP_SET_PRODUCT";
export const APP_SET_APIGW_TRAFFIC = "@DAP/APP_SET_APIGW_TRAFFIC";
// product action types
export const PRODUCT_SET_DETAILS = "@DAP/PRODUCT_SET_DETAILS";
export const PRODUCT_SET_SOLICITED_INDICATOR = "@DAP/PRODUCT_SET_SOLICITED_INDICATOR";
export const PRODUCT_DISCLOSURE_SET_DETAILS = "@DAP/PRODUCT_DISCLOSURE_SET_DETAILS";

// locale action types
export const LANGUAGE_SWITCH = "@DAP/LANGUAGE_SWITCH";
export const LANGUAGE_LOAD = "@DAP/LANGUAGE_SWITCH_LOAD";
export const LANGUAGE_LOAD_SUCCESS = "@DAP/LANGUAGE_LOAD_SUCCESS";
export const LANGUAGE_LOAD_ERROR = "@DAP/LANGUAGE_LOAD_ERROR";
export const LANGUAGE_LOAD_FAILURE = "@DAP/LANGUAGE_LOAD_FAILURE";

// entry action types
export const ENTRY_RESET = "@DAP/ENTRY_RESET";
export const ENTRY_UPDATE_FIELD = "@DAP/ENTRY_UPDATE_FIELD";
export const ENTRY_UPDATE_FIELDS_FROM_CONTEXT = "@DAP/ENTRY_UPDATE_FIELDS_FROM_CONTEXT";
export const ENTRY_UPDATE_ADDRESS = "@DAP/ENTRY_UPDATE_ADDRESS";
export const ENTRY_AUTHORIZED_USER_ADDRESS_SYNC = "@DAP/ENTRY_AUTHORIZED_USER_ADDRESS_SYNC";
export const ENTRY_AUTHORIZED_USER_CLEANUP = "@DAP/ENTRY_AUTHORIZED_USER_CLEANUP";
export const ENTRY_TIMEOUT = "@DAP/ENTRY_TIMEOUT";
export const ENTRY_RESET_EMP_STATUS_DEPENDENT_FIELDS =
  "@DAP/ENTRY_RESET_EMP_STATUS_DEPENDENT_FIELDS";
export const ENTRY_RESET_INDUSTRY_DEPENDENT_FIELDS = "@DAP/ENTRY_RESET_INDUSTRY_DEPENDENT_FIELDS";
export const ENTRY_RESET_JOB_DESC_DEPENDENT_FIELDS = "@DAP/ENTRY_RESET_JOB_DESC_DEPENDENT_FIELDS";

export const ENTRY_SUBMIT = "@DAP/ENTRY_SUBMIT";
export const ENTRY_SUBMIT_SUCCESS = "@DAP/ENTRY_SUBMIT_SUCCESS";
export const ENTRY_SUBMIT_ERROR = "@DAP/ENTRY_SUBMIT_ERROR";
export const ENTRY_SUBMIT_FAILURE = "@DAP/ENTRY_SUBMIT_FAILURE";

// brand action types for quick check
export const SET_QUICKCHECK_VIEW = "@DAP/SET_QUICKCHECK_VIEW";
export const RESET_QUICK_CHECK_FLAG = "@DAP/RESET_QUICK_CHECK_FLAG";
export const SET_QUICK_CHECK_FLAG = "@DAP/SET_QUICK_CHECK_FLAG";
export const RESET_QUICK_CHECK_INFO = "@DAP/RESET_QUICK_CHECK_INFO";

export const CONSTANT = {
  dispatchTypeFieldUpdate: "fieldUpdate"
};
