import requestGet from "./base/request-get";
import config from "../../../config";
import { FormattedMessage } from "react-intl";
import getWebLogger from "../logger/webLogger";
import { getChannelFromQS, redirectTo } from "../dom-utils";
import { CHANNEL_INTERNET, CHANNEL_PHONE } from "../../state/constants/channel";
import { TECHNICAL_DIFFICULTY_URL } from "../../routes/routes-constants";

const isProductAPIResponseValid = (productInfo) => {
  const {
    productId,
    productLanguageCode,
    productType,
    brandCode,
    channelCode,
    isSolicitedProduct,
    productDisplayContents,
    productDisclosure
  } = productInfo;
  return (
    productId &&
    productLanguageCode &&
    productType &&
    brandCode &&
    channelCode &&
    typeof isSolicitedProduct === "boolean" &&
    Array.isArray(productDisplayContents) &&
    productDisplayContents.length > 0 &&
    productDisclosure
  );
};

const convertProductDisplayContents = (displayContent) => {
  const cardSummary = [];
  const ui = [];
  const cardSummarySnippetsMapping = {
    MEMBERSHIP_FEE_SNIPPET: { title: <FormattedMessage id="snippets.title.annual-fee" /> },
    REWARDS_REDEMPTION_SNIPPET: {
      title: <FormattedMessage id="snippets.title.rewards-redemption" />
    },
    REWARDS_BONUS_SNIPPET: { title: <FormattedMessage id="snippets.title.rewards-bonus" /> },
    REWARDS_RATE_SNIPPET: { title: <FormattedMessage id="snippets.title.reward-rate" /> },
    PURCHASE_AIR_SNIPPET: { title: <FormattedMessage id="snippets.title.purchase-air" /> },
    REWARDS_SNIPPET: { title: <FormattedMessage id="snippets.title.rewards" /> }
  };

  displayContent.forEach((content) => {
    const { type, text } = content;
    Object.keys(cardSummarySnippetsMapping).includes(type)
      ? cardSummary.push({ type, text, title: cardSummarySnippetsMapping[type].title })
      : ui.push({ type, text });
  });

  return {
    cardSummary,
    ui
  };
};

function translateChannelFromProductOrchestrator(channel) {
  if (channel === "Internet_Phone") {
    return getChannelFromQS() === CHANNEL_PHONE ? CHANNEL_PHONE : CHANNEL_INTERNET;
  } else {
    return channel;
  }
}
const convertProductInfo = (productInfo) => {
  const {
    productId: id,
    productLanguageCode: locale,
    productType: type,
    brandCode: brand,
    channelCode: channel,
    isSolicitedProduct: solicitedIndicator,
    productDisplayContents,
    productDisclosure
  } = productInfo;
  return {
    id,
    locale,
    type,
    brand,
    solicitedIndicator,
    channel: translateChannelFromProductOrchestrator(channel),
    active: true,
    productDisplayContent: convertProductDisplayContents(productDisplayContents),
    disclosures: atob(productDisclosure)
  };
};

export const getProduct = async (productId) => {
  const logger = getWebLogger("product.js");
  logger.info({
    actionType: "RETRIEVING_PRODUCT_USING_ORCHESTRATOR",
    message: "Retrieving Product using Orchestrator"
  });
  const { webOrchestratorEndpoint } = config();
  let productInfo;
  try {
    const params = {};
    params.channel = getChannelFromQS() === CHANNEL_PHONE ? CHANNEL_PHONE : CHANNEL_INTERNET;
    productInfo = await requestGet(
      `${webOrchestratorEndpoint}/${productId}`,
      params,
      "Get product information using orchestrator",
      {
        isCustomHeaders: true,
        logIfResponseFromCache: true
      }
    );
  } catch (e) {
    if (e.code !== 404) {
      redirectTo(TECHNICAL_DIFFICULTY_URL, { ...e }, false);
    } else {
      throw e;
    }
  }

  if (!isProductAPIResponseValid(productInfo))
    throw new Error("Invalid product information fetched from orchestrator");
  return convertProductInfo(productInfo);
};

export const getProducts = async (productInputs) => {
  const logger = getWebLogger("product.js");
  logger.info({
    actionType: "RETRIEVING_PRODUCTS_USING_ORCHESTRATOR",
    message: "Retrieving Products using Orchestrator"
  });
  const { webOrchestratorEndpoint } = config();
  const { reservationNumber, accessCode, brandCode, locale } = productInputs;
  const params = {};
  if (reservationNumber && accessCode) {
    params.solicitationOffer = `${reservationNumber}|${accessCode}`;
  } else {
    params.productReference = `${brandCode}|${locale}`;
  }
  params.channel = getChannelFromQS() === CHANNEL_PHONE ? CHANNEL_PHONE : CHANNEL_INTERNET;
  let productInfo;
  try {
    productInfo = await requestGet(
      webOrchestratorEndpoint,
      params,
      "Get product information using orchestrator",
      {
        // unsure if this is required. Will be determined during implementation & testing
        isCustomHeaders: true,
        logIfResponseFromCache: true
      }
    );
  } catch (e) {
    if (e.code !== 404) {
      redirectTo(TECHNICAL_DIFFICULTY_URL, { ...e }, false);
    } else {
      throw e;
    }
  }

  const { products } = productInfo;
  if (!products || !Array.isArray(products) || products.length !== 1) {
    logger.info({
      actionType: "INVALID_PRODUCT_INFO_RESPONSE_STRUCTURE",
      message: `Product info orchestrator response structure is invalid. Reservation Number: ${reservationNumber}, Access Code: ${accessCode}`
    });
    throw new Error("INVALID_PRODUCT_INFO_RESPONSE_STRUCTURE");
  }
  if (!isProductAPIResponseValid(products[0])) {
    logger.info({
      actionType: "INVALID_PRODUCT_INFO_INFORMATION",
      message: `Invalid product information fetched from orchestrator. Reservation Number: ${reservationNumber}, Access Code: ${accessCode}`
    });
    throw new Error("INVALID_PRODUCT_INFO_INFORMATION");
  }
  return convertProductInfo(products[0]);
};
