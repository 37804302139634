import PropTypes from "prop-types";
import React, { Component } from "react";

import * as statusList from "./status";
import * as Logger from "../lib/logger";
import getWebLogger from "../lib/logger/webLogger";

class AppStatus extends Component {
  static propTypes = {
    component: PropTypes.string,
    productDetails: PropTypes.object
  };

  componentDidMount() {
    const logger = getWebLogger("app-status.js");
    if (statusList[this.props.component] && this.props.productDetails) {
      const {
        // eslint-disable-next-line no-unused-vars
        snippets,
        ...productInfo
      } = this.props.productDetails;
      Logger.pageTracker(this.props.component, productInfo, "status");
      logger.info({
        message: "In App Status",
        url: this.props.component,
        product: productInfo,
        pageType: "status"
      });
    }
  }

  render() {
    const { component, ...statusProps } = this.props;
    const Status = statusList[component];
    if (!Status) {
      console.error(
        `Error: attempt to render application in busy state, but missing status component: '${component}'`
      ); // eslint-disable-line
      return null;
    } else {
      return (
        <div id="app-status" className="expanded row align-center align-middle">
          <Status {...statusProps} />
        </div>
      );
    }
  }
}

export default AppStatus;
