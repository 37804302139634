import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class TechnicalDifficultyApp extends Component {
  static propTypes = {
    decision: PropTypes.object
  };

  render() {
    const checkNoAuto =
      this.props.decision &&
      this.props.decision.applicationStatus.toLowerCase() === "nodecision" &&
      this.props.decision.isNonAutoPostalCode;

    return (
      <div data-cy="technical-difficulty-app">
        {checkNoAuto ? (
          <div className="row column text-center error-page">
            <i className="oi-caution outline header-icon" />
            <h1 className="h1-as-h2">
              <FormattedMessage id="technical-difficulty.title" />
            </h1>
            <p>
              <FormattedMessage id="decision.no-decision-non-auto-postal-code.message.line1" />
              <br />
              <FormattedMessage id="decision.no-decision-non-auto-postal-code.message.line2" />
            </p>
          </div>
        ) : (
          <div className="row column text-center error-page">
            <i className="oi-caution outline header-icon" />
            <h1 className="h1-as-h2">
              <FormattedMessage id="technical-difficulty.title" />
            </h1>
            <p>
              <FormattedMessage id="technical-difficulty.text" />
              <br />
              <FormattedMessage id="technical-difficulty.next-step" />
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default TechnicalDifficultyApp;
