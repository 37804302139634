import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';

const PrefillWarningEntry = () => {
    return (
        <div id="prefill-warning-entry" className="callout warning prefill-warning-header">
            <p><FormattedMessage id="prefill-warning.entry.page.content" /></p>
        </div>
    );
};

PrefillWarningEntry.propTypes = {
    intl: intlShape,
    onClose: PropTypes.func
};

export default PrefillWarningEntry;


