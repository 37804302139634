import React from "react";
import { intlShape, FormattedMessage } from "react-intl";

const ThirdPartyTerms = () => {
  return (
    <section
      data-testid="quick-fund.navigate.info.alert.header"
      data-cy="third-party-terms"
      className="content"
    >
      <FormattedMessage id="quick-fund.navigate.info.alert.header" tagName="h2" />
      <FormattedMessage id="quick-fund.navigate.info.alert.content" />
    </section>
  );
};
ThirdPartyTerms.propTypes = {
  intl: intlShape
};

export default ThirdPartyTerms;
