import getWebLogger from "../logger/webLogger";
import config from "../../../config";
import requestPost from "./base/request-post";

let retry = 0;

export const emailVerification = async (event, prefillEmail = "") => {
  const logger = getWebLogger("email-verification.js");
  const validationResult = {};
  const defaultResult = {
    result: "VALID",
    message: ""
  };

  if (prefillEmail === event) {
    return defaultResult;
  }
  if (retry >= 2) {
    logger.info({
      actionType: "EAV_TWO_INVALID_ATTEMPTS",
      message: "Stop validation after two invalid attempts"
    });
    return defaultResult;
  }

  try {
    const { eavEndpoint } = config();
    const payload = {
      emailAddress: event,
      attemptNumber: 0,
      callWhitelistService: false
    };
    const eavResult = await requestPost(
      eavEndpoint,
      payload,
      "Calling for email address verification",
      { isCustomHeaders: true }
    );

    const { isValid } = eavResult;
    if (isValid) {
      validationResult.result = "VALID";
      validationResult.message = "";
    } else {
      retry++;
      validationResult.result = "INVALID";
      validationResult.message = "_sc.email.externalValidation";
    }
  } catch (e) {
    logger.info({
      actionType: "EAV_FAILURE",
      message: "Failure verifying email address.",
      errorDescription: e
    });
    validationResult.result = "VALID";
    validationResult.message = "";
  }

  return validationResult;
};
