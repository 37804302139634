import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { intlShape, FormattedMessage, injectIntl, IntlProvider } from "react-intl";
import { pageLoadLogger } from "../../lib/logger";
import { AppContext } from "../../state/app-context";
import { changeCalypsoContext } from "../../lib/CalypsoUtil";
import useLogger from "../../hooks/useLogger";
import { LANGUAGES } from "../../state/constants/product";
import messagesEN from "../../../locale/en.json";
import messagesFR from "../../../locale/fr.json";
import { getLocale } from "../../lib/session";
const UnauthPageAccessWithoutQk = (props) => {
  const { product } = useContext(AppContext).app;
  const logger = useLogger("unauth-page-access-without-qk.js");
  const locale = getLocale(window, product, "en-CA");
  const lang = String(LANGUAGES.FRENCH_ALL).includes(locale) ? "fr" : "en";
  //TODO: Day2 Lemonade fix bug where props.intl.formatMessage fn still refers to en.json
  // and not fr.json even after changing messages and local
  let quickCheckUrl = "quick-check.url";
  let capOneUrl = "capital-one.external.url";
  if (lang === "fr") {
    props.intl.messages = String(LANGUAGES.ENGLISH).includes(getLocale(window))
      ? messagesEN
      : messagesFR;
    props.intl.locale = LANGUAGES.FRENCH;
    props.intl.defaultLocale = LANGUAGES.FRENCH;
    quickCheckUrl = "quick-check.url.french";
    capOneUrl = "capital-one.external.url.french";
  }
  useEffect(() => {
    changeCalypsoContext("CA_UnauthPageWithoutQK");
    pageLoadLogger(product);
    logger.info({ message: "In Unauthorized Access to Product without QuickCheck page" });
  }, [product]);

  return (
    <div data-testid="UnauthAccessWithoutQk" className="row column text-center error-page">
      <IntlProvider locale={props.intl.locale} messages={props.intl.messages}>
        <section className="content">
          <i className="oi-caution outline header-icon" />
          <h1 className="h1-as-h2">
            <FormattedMessage id="unauth-page-access-without-qk.title" />
          </h1>
          <p data-testid="UnauthAccessWithoutQkStep">
            <FormattedMessage
              id="unauth-page-access-without-qk.step"
              values={{
                linkQuickCheck: (
                  <a href={props.intl.formatMessage({ id: quickCheckUrl })}>
                    <FormattedMessage id="unauth-page-access-without-qk.anchor-text.qk" />
                  </a>
                )
              }}
            />
            <br />
            <FormattedMessage
              id="unauth-page-access-without-qk.next-step"
              values={{
                linkHomePage: (
                  <a href={props.intl.formatMessage({ id: capOneUrl })}>
                    <FormattedMessage id="unauth-page-access-without-qk.anchor-text.homepage" />
                  </a>
                )
              }}
            />
          </p>
        </section>
      </IntlProvider>
    </div>
  );
};

UnauthPageAccessWithoutQk.propTypes = {
  intl: intlShape,
  search: PropTypes.string
};

export { UnauthPageAccessWithoutQk as UnauthPageAccessWithoutQkRaw };

const UnauthPageAccessWithoutQkHOC = injectIntl(UnauthPageAccessWithoutQk);

UnauthPageAccessWithoutQkHOC.displayName = "UnauthPageAccessWithoutQk";
export default UnauthPageAccessWithoutQkHOC;
