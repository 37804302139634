import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, intlShape } from "react-intl";
import QuickFund from "../../components/quick-fund/quick-fund";
import { isMobile } from "../../lib/dom-utils";

import SendFundsAdditionalInfo from "./send-funds-legal-accordian";

const SecuredApprovedSendFunds = ({
  minimumSecurityDepositAmount,
  solicitationReferenceNumber,
  productInfo,
  firstName,
  lastName,
  intl,
  isCTA
}) => {
  return (
    <div
      data-cy="secured-approved-send-funds"
      data-testid="secured-approved-send-funds"
      className="row columns text-center secured-decision-page-grey"
    >
      <h2>
        <FormattedMessage id="decision.text.securing.security-funds-subhead" />
      </h2>
      <div data-cy="secured-fund-video" className="row secured-fund-video large-collapse">
        <div className="column small-12 medium-2">
          <div className="secured-fund-icons-video">
            <span aria-hidden="true" className="icon-uniE202" />
          </div>
          <h4>
            <FormattedMessage id="decision.text.securing.security-funds-step1" />
          </h4>
        </div>

        <div className="column small-12 medium-1 secured-steps-arrow">
          <div className="line-container">
            <span className="line arrow-right" />
          </div>
        </div>

        <div className="column small-12 medium-2">
          <div className="secured-fund-icons-video">
            <span aria-hidden="true" className="icon-uniE101" />
          </div>
          <h4>
            <FormattedMessage
              id="decision.text.securing.security-funds-step2"
              values={{
                capitalOnePayee: (
                  <span className="securing-content-emphasis">
                    <FormattedMessage id="decision.text.securing.payee-list" />{" "}
                  </span>
                )
              }}
            />
          </h4>
        </div>

        <div className="column small-12 medium-1 secured-steps-arrow">
          <div className="line-container">
            <span className="line arrow-right" />
          </div>
        </div>

        <div className="column small-12 medium-2">
          <div className="secured-fund-icons-video">
            <span aria-hidden="true" className="icon-uniEdoclist">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
              <span className="path6" />
              <span className="path7" />
              <span className="path8" />
              <span className="path9" />
              <span className="path10" />
              <span className="path11" />
              <span className="path12" />
              <span className="path13" />
              <span className="path14" />
              <span className="path15" />
              <span className="path16" />
              <span className="path17" />
              <span className="path18" />
              <span className="path19" />
              <span className="path20" />
              <span className="path21" />
              <span className="path22" />
              <span className="path23" />
            </span>
          </div>
          <h4>
            <FormattedMessage id="decision.text.securing.security-funds-step3" />
            <span id="solicitation-ref" className="currency-value securing-content-emphasis">
              {solicitationReferenceNumber}.
            </span>
          </h4>
        </div>

        <div className="column small-12 medium-1 secured-steps-arrow">
          <div className="line-container">
            <span className="line arrow-right" />
          </div>
        </div>
        <div className="column small-12 medium-2">
          <div className="secured-fund-icons-video">
            <span aria-hidden="true" className="icon-uniE216">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
              <span className="path6" />
              <span className="path7" />
              <span className="path8" />
              <span className="path9" />
              <span className="path10" />
              <span className="path11" />
              <span className="path12" />
              <span className="path13" />
              <span className="path14" />
              <span className="path15" />
              <span className="path16" />
              <span className="path17" />
              <span className="path18" />
              <span className="path19" />
              <span className="path20" />
              <span className="path21" />
              <span className="path22" />
              <span className="path23" />
              <span className="path24" />
              <span className="path25" />
            </span>
          </div>
          <h4>
            <FormattedMessage
              id="decision.text.securing.security-funds-step4"
              values={{
                depositAmount: (
                  <span className="currency-value securing-content-emphasis">
                    <span className="currency-sign">$</span>
                    {minimumSecurityDepositAmount}
                  </span>
                ),

                emphasize: (
                  <span className="securing-content-emphasis">
                    <FormattedMessage id="decision.text.securing.security-funds-step4-emphasize" />
                  </span>
                )
              }}
            />
          </h4>
        </div>
      </div>

      {!isCTA && !isMobile() && (
        <QuickFund
          intl={intl}
          solicitationReferenceNumber={solicitationReferenceNumber}
          productInfo={productInfo}
          firstName={firstName}
          lastName={lastName}
        />
      )}

      <div
        data-cy="secured-limit-increase"
        className="row columns text-left limit-increase-spacing"
        id="secured-limit-increase"
      >
        <div className="column small-12 medium-8 limit-increase-wrapper">
          <h2>
            <span id="security-funds-increase-credit-limit-heading">
              <i className="oi-question" aria-hidden="true" />
              <FormattedMessage id="decision.text.securing.security-funds-increase-credit-limit-heading" />
            </span>
          </h2>
          <FormattedMessage id="decision.text.securing.security-funds-increase-credit-limit" />
          <SendFundsAdditionalInfo intl={intl} />
        </div>
      </div>
    </div>
  );
};

SecuredApprovedSendFunds.propTypes = {
  minimumSecurityDepositAmount: PropTypes.string,
  solicitationReferenceNumber: PropTypes.string,
  productInfo: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  intl: intlShape,
  isCTA: PropTypes.bool
};

export default SecuredApprovedSendFunds;
