import {
  inputSnowplowImplementation,
  clickSnowplowImplementation,
  mouseoverSnowplowImplementation
} from "../../lib/logger/snow-plow";

const fieldTrackingMapper = {
  "onBlur": { fieldType: ["text", "tel", "radio", "checkbox", "select-one"], snowplowEventHandler: inputSnowplowImplementation },
  "onFocus": { fieldType: ["text", "tel", "radio", "checkbox", "select-one"], snowplowEventHandler: inputSnowplowImplementation },
  "onChange": { fieldType: ["text", "tel", "radio", "checkbox", "select-one"], snowplowEventHandler: inputSnowplowImplementation },
  "onPaste": { fieldType: ["text", "tel"], snowplowEventHandler: inputSnowplowImplementation },
  "onClick": { fieldType: ["button", "a", "drawer"], snowplowEventHandler: clickSnowplowImplementation },
  "onMouseOver": { fieldType: ["tooltip"], snowplowEventHandler: mouseoverSnowplowImplementation }
};

export default fieldTrackingMapper;
