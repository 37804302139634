import { intlShape, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import React from "react";

const FrenchConsent = ({ rewardCard, frenchApplication, intl }) => {
  return (
    <div id="app-terms-conditions-french-consent">
      <h3>
        <FormattedMessage
          id="application-terms-conditions.french.consent.heading"
          tagName="strong"
        />
      </h3>

      <p data-cy="application-terms-french-consent" className="bureau-consent-content billc96">
        <strong>
          <FormattedMessage id="application-terms-conditions.benefits.list2.content.bold" />
        </strong>
        <FormattedMessage
          id="application-terms-conditions.french.consent.content"
          values={{
            frenchApplication: (
              <a id="french-application" href={frenchApplication} target="_blank" rel="noopener">
                <FormattedMessage id="application-terms-conditions.french.consent.application.text" />
              </a>
            ),
            agreement: (
              <a
                href={intl.formatMessage({
                  id: "application-terms-conditions.french.consent.card.agreement.url"
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="application-terms-conditions.french.consent.card.agreement.text" />
              </a>
            )
          }}
        />
        {rewardCard && (
          <a
            href={intl.formatMessage({
              id: "application-terms-conditions.french.consent.card.reward.url"
            })}
            data-testid="french.consent.card.reward.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="application-terms-conditions.french.consent.card.reward.text" />
          </a>
        )}
        <span>&nbsp;and&nbsp;</span>
        <a
          href={intl.formatMessage({
            id: "application-terms-conditions.french.consent.card.benefit.url"
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="application-terms-conditions.french.consent.card.benefit.text" />
        </a>
      </p>
    </div>
  );
};

FrenchConsent.propTypes = {
  intl: intlShape.isRequired,
  frenchApplication: PropTypes.string.isRequired,
  rewardCard: PropTypes.bool.isRequired
};

export default FrenchConsent;
