import PropTypes from "prop-types";
import React, { useContext } from "react";
import { intlShape } from "react-intl";
import { getSnippetTextHTML } from "../../lib/dom-utils";
import { CARD_NAME_SNIPPET, PRIMARY_BENEFIT_SNIPPET } from "../../state/constants/product";
import Faq from "../../components/alerts/faq";

import SubHeader from "@cof/graffiti-alley-spray-cans/organisms/SubHeader";
import { AppContext } from "../../state/app-context";

const EntryHeader = ({ intl, snippets }) => {
  const { app } = useContext(AppContext);
  const FaqModal = () => <Faq intl={intl} product={app.product} />;
  return (
    <SubHeader
      title={<span dangerouslySetInnerHTML={getSnippetTextHTML(snippets, CARD_NAME_SNIPPET)} />}
      description={
        <span dangerouslySetInnerHTML={getSnippetTextHTML(snippets, PRIMARY_BENEFIT_SNIPPET)} />
      }
      itemsList={[
        {
          id: intl.formatMessage({ id: "common.faq" }),
          modalComponent: FaqModal,
          icon: "oi-question show-for-medium"
        }
      ]}
    />
  );
};

EntryHeader.propTypes = {
  intl: intlShape.isRequired,
  snippets: PropTypes.object
};

export default EntryHeader;
